import React, { useState } from 'react';
import { 
  Typography, 
  Box,
  Button
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import ReactQuill from 'react-quill';
import axios from 'axios';
import 'react-quill/dist/quill.snow.css';

const EditableTextField = ({ 
  item, 
  fieldName, 
  label,
  playerId,
  onUpdate 
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(item[fieldName] || '');
  const [tempValue, setTempValue] = useState(item[fieldName] || '');
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;

  const handleDoubleClick = (e) => {
    e.stopPropagation();
    setIsEditing(true);
    setTempValue(value);
  };

  const handleSave = async () => {
    try {
      const transformedValue = tempValue
        .replace(/<p><br><\/p>/g, '<br>')
        .replace(/<p>/g, '')
        .replace(/<\/p>/g, '<br>')
        .replace(/<br><br>/g, '<br>');

      await axios.put(`${backendUrl}/api/technique/${item.id}`, {
        [fieldName]: transformedValue || '',
        playerId,
        section
      });
      
      setValue(transformedValue);
      if (onUpdate) {
        onUpdate();
      }
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating field:", error);
      alert("Error al actualizar el campo");
    }
  };

  const handleCancel = () => {
    setTempValue(value);
    setIsEditing(false);
  };

  // Ne rien rendre si pas de contenu
  if (!item[fieldName] && !isEditing) return null;

  return (
    <Box sx={{ width: '100%', mt: 1 }}>
      {isEditing ? (
        <Box>
          <Box sx={{ 
            '.ql-container': {
              minHeight: '150px'
            }
          }}>
            <ReactQuill
              theme="snow"
              value={tempValue}
              onChange={setTempValue}
              modules={{
                toolbar: [
                  [{ 'header': [1, 2, false] }],
                  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  [{'list': 'ordered'}, {'list': 'bullet'}],
                  ['link'],
                  ['clean']
                ],
              }}
            />
          </Box>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'flex-end', 
            gap: 1,
            mt: 2 
          }}>
            <Button 
              variant="outlined" 
              onClick={handleCancel}
            >
              Cancelar
            </Button>
            <Button 
              variant="contained" 
              startIcon={<SaveIcon />}
              onClick={handleSave}
            >
              Guardar
            </Button>
          </Box>
        </Box>
      ) : (
        <Box 
          onDoubleClick={handleDoubleClick}
          sx={{ 
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            },
            p: 2,
            borderRadius: 1
          }}
        >
          <Typography 
            component="div" 
            dangerouslySetInnerHTML={{ __html: value }} 
            sx={{ 
              '& *': { margin: 0 } // Pour éviter les marges indésirables dans le HTML rendu
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default EditableTextField;