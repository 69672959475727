import React, { useState, useEffect } from 'react';
import { 
  Autocomplete, 
  Chip,
  Avatar, 
  Box, 
  Typography, 
  TextField, 
  Button, 
  Select, 
  MenuItem, 
  IconButton, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  useMediaQuery, 
  useTheme,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Backdrop,
  CircularProgress,
  Switch
} from '@mui/material';
import { Edit, Delete, PhotoCamera, ExpandMore, ExpandLess } from '@mui/icons-material';
import axios from 'axios';
import { Fab } from '@mui/material';
import { Add } from '@mui/icons-material';

const MobileUserCard = ({ user, onEdit, onDelete, expanded, onToggleExpand }) => (
  <Paper elevation={2} sx={{ mb: 2, overflow: 'hidden' }}>
    <Box sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Avatar
            src={user.id ? `${process.env.REACT_APP_MEDIA_URL}/users/${user.id}.png?${new Date().getTime()}` : ''}
            alt={`${user.nom} ${user.prenom}`}
            sx={{ width: 50, height: 50 }}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Box sx={{ 
              width: 10, 
              height: 10, 
              borderRadius: '50%', 
              bgcolor: user.is_active ? '#4caf50' : '#f44336'
            }}/>
            <Box>
              <Typography variant="subtitle1">{`${user.prenom} ${user.nom}`}</Typography>
              <Typography variant="body2" color="textSecondary">{user.role}</Typography>
            </Box>
          </Box>
        </Box>
        <IconButton onClick={onToggleExpand}>
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>

      {expanded && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="body2" sx={{ mb: 1 }}>{`Email: ${user.email}`}</Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>{`Usuario: ${user.username}`}</Typography>
          
          {user.groupes && user.groupes.length > 0 && (
            <Box sx={{ mt: 1 }}>
              <Typography variant="body2" sx={{ mb: 1 }}>Grupos:</Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {user.groupes.map((group) => (
                  <Chip
                    key={group.id}
                    label={group.libelle}
                    size="small"
                    sx={{
                      backgroundColor: group.code_couleur,
                      color: '#fff',
                      mb: 0.5
                    }}
                  />
                ))}
              </Box>
            </Box>
          )}
          
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, gap: 1 }}>
            <IconButton onClick={() => onEdit(user)} color="primary" size="small">
              <Edit />
            </IconButton>
            <IconButton onClick={() => onDelete(user.id)} color="error" size="small">
              <Delete />
            </IconButton>
          </Box>
        </Box>
      )}
    </Box>
  </Paper>
);

const Usuarios = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [userRole, setUserRole] = useState(null);
  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);
  const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [userToEdit, setUserToEdit] = useState(null);
  const [groups, setGroups] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [expandedCards, setExpandedCards] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [newUser, setNewUser] = useState({
    nom: '',
    prenom: '',
    email: '',
    username: '',
    role: '',
    password: '',
    groupes: [],
    is_active: true
  });

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;

  const compressImage = async (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          const MAX_SIZE = 800;
          if (width > height && width > MAX_SIZE) {
            height *= MAX_SIZE / width;
            width = MAX_SIZE;
          } else if (height > MAX_SIZE) {
            width *= MAX_SIZE / height;
            height = MAX_SIZE;
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
          
          canvas.toBlob((blob) => {
            resolve(new File([blob], file.name, { type: 'image/png' }));
          }, 'image/png', 0.7);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const handleCapturePhoto = async (e, isEdit = false) => {
    const file = e.target.files?.[0];
    if (!file) return;

    try {
      const compressedFile = await compressImage(file);
      const reader = new FileReader();
      
      reader.onload = () => {
        if (isEdit) {
          setUserToEdit(prev => ({
            ...prev,
            avatar: reader.result,
            avatarFile: compressedFile
          }));
        } else {
          setNewUser(prev => ({
            ...prev,
            avatar: reader.result,
            avatarFile: compressedFile
          }));
        }
      };
      
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.error("Error processing image:", error);
      setErrorMessage("Error al procesar la imagen");
    }
  };

  const handleEditUser = (user) => {
    setUserToEdit({ 
      ...user,
      avatar: user.id ? `${process.env.REACT_APP_MEDIA_URL}/users/${user.id}.png?${new Date().getTime()}` : null,
      groupes: user.groupes ? user.groupes.map(g => g.id) : [],
      is_active: user.is_active 
    });
    setEditUserDialogOpen(true);
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('user'));
    if (userData) {
      setUserRole(userData.role);
      fetchUsers();
    }
  }, [page]);

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${backendUrl}/api/users`, {
        params: { section, page, limit: 20 }
      });
      const fetchedUsers = Array.isArray(response.data) ? response.data : response.data.users || [];
      setUsers(fetchedUsers);
      setTotalPages(response.data.totalPages || 1);
    } catch (error) {
      console.error("Error fetching users:", error);
      setUsers([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/groups`, { params: { section } });
        setGroups(response.data);
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    };
    fetchGroups();
  }, []);

  useEffect(() => {
    const applyRoleFilter = () => {
      const filtered = users.filter((user) => {
        if (userRole === 'super admin') return true;
        if (userRole === 'admin pro') return user.role === 'admin pro';
        if (userRole === 'admin cantera') {
          return user.role === 'admin cantera' || user.role === 'entrenador';
        }
        return false;
      });
      setFilteredUsers(filtered);
    };
    applyRoleFilter();
  }, [users, userRole]);

  const getAvailableRoles = () => {
    if (userRole === 'super admin') {
      return ['super admin', 'admin pro', 'admin cantera', 'entrenador'];
    }
    if (userRole === 'admin cantera') {
      return ['admin cantera', 'entrenador'];
    }
    if (userRole === 'admin pro') {
      return ['admin pro'];
    }
    return [];
  };

  const handleAddUser = async () => {
    if (!newUser.nom || !newUser.prenom || !newUser.email || !newUser.username || !newUser.role || !newUser.password) {
      setErrorMessage("Por favor, complete todos los campos obligatorios.");
      return;
    }

    if (newUser.role === 'entrenador' && newUser.groupes.length === 0) {
      setErrorMessage("Para el rol 'entrenador', seleccione al menos un grupo.");
      return;
    }

    const formData = new FormData();
    Object.keys(newUser).forEach(key => {
      if (key === 'groupes') {
        formData.append(key, JSON.stringify(newUser[key]));
      } else if (key === 'avatarFile' && newUser[key]) {
        formData.append('avatar', newUser[key]);
      } else if (key !== 'avatar') {
        formData.append(key, newUser[key]);
      }
    });
    formData.append('section', section);

    setIsLoading(true);
    try {
      await axios.post(`${backendUrl}/api/users`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setAddUserDialogOpen(false);
      setNewUser({ 
        nom: '', 
        prenom: '', 
        email: '', 
        username: '', 
        role: '', 
        password: '', 
        groupes: [],
        is_active: true 
      });
      setErrorMessage('');
      fetchUsers();
    } catch (error) {
      console.error("Error adding user:", error);
      setErrorMessage("Error al añadir el usuario");
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateUser = async () => {
    if (!userToEdit.nom || !userToEdit.prenom || !userToEdit.email || !userToEdit.username || !userToEdit.role) {
      setErrorMessage("Por favor, complete todos los campos obligatorios.");
      return;
    }

    const formData = new FormData();
    Object.keys(userToEdit).forEach(key => {
      if (key === 'groupes') {
        formData.append(key, JSON.stringify(userToEdit[key]));
      } else if (key === 'avatarFile' && userToEdit[key]) {
        formData.append('avatar', userToEdit[key]);
      } else if (key !== 'avatar' && key !== 'id') {
        // Conversion du booléen en chaîne pour is_active
        const value = key === 'is_active' ? String(userToEdit[key]) : userToEdit[key];
        formData.append(key, value);
      }
    });
    formData.append('section', section);

    setIsLoading(true);
    try {
      await axios.put(`${backendUrl}/api/users/${userToEdit.id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setEditUserDialogOpen(false);
      setUserToEdit(null);
      fetchUsers();
    } catch (error) {
      console.error("Error updating user:", error);
      setErrorMessage("Error al actualizar el usuario");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteUser = async () => {
    if (!userToDelete) return;
    setIsLoading(true);
    try {
      await axios.delete(`${backendUrl}/api/users/${userToDelete}`, { params: { section } });
      setUsers(users.filter((user) => user.id !== userToDelete));
      setFilteredUsers(filteredUsers.filter((user) => user.id !== userToDelete));
      setDeleteConfirmDialogOpen(false);
      setUserToDelete(null);
    } catch (error) {
      console.error("Error deleting user:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleCardExpansion = (userId) => {
    setExpandedCards(prev => ({
      ...prev,
      [userId]: !prev[userId]
    }));
  };

  return (
    <Box sx={{ p: isMobile ? 2 : 4 }}>
      <Typography variant="h4" sx={{ mb: 4 }}>
        Usuarios
      </Typography>

      {isMobile && (
        <Fab
          color="primary"
          aria-label="add"
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: 1000,
          }}
          onClick={() => setAddUserDialogOpen(true)}
        >
          <Add />
        </Fab>
      )}

      {!isMobile && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setAddUserDialogOpen(true)}
          sx={{ mb: 4 }}
          fullWidth={isMobile}
        >
          Añadir Usuario
        </Button>
      )}

      {isMobile ? (
        <Box>
          {filteredUsers.map((user) => (
            <MobileUserCard
              key={user.id}
              user={user}
              onEdit={() => handleEditUser(user)}
              onDelete={(id) => {
                setUserToDelete(id);
                setDeleteConfirmDialogOpen(true);
              }}
              expanded={expandedCards[user.id]}
              onToggleExpand={() => toggleCardExpansion(user.id)}
            />
          ))}
        </Box>) : (
        <TableContainer component={Paper}>
          <Table aria-label="Lista de Usuarios">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell>Apellido</TableCell>
                <TableCell>Correo electrónico</TableCell>
                <TableCell>Contraseña</TableCell>
                <TableCell>Nombre de usuario</TableCell>
                <TableCell>Rol</TableCell>
                <TableCell>Grupos</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers.length > 0 ? (
                filteredUsers.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell>
                      {user.id && (
                        <img
                          src={`${process.env.REACT_APP_MEDIA_URL}/users/${user.id}.png?${new Date().getTime()}`}
                          alt={`${user.nom} ${user.prenom}`}
                          style={{ width: 40, height: 40, borderRadius: '50%' }}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <Box sx={{ 
                        width: 10, 
                        height: 10, 
                        borderRadius: '50%', 
                        bgcolor: user.is_active ? '#4caf50' : '#f44336' 
                      }}/>
                    </TableCell>
                    <TableCell>{user.prenom}</TableCell>
                    <TableCell>{user.nom}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>••••••</TableCell>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{user.role}</TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
                        {user.groupes && user.groupes.map((group) => (
                          <Box
                            key={group.id}
                            sx={{
                              display: 'inline-flex',
                              alignItems: 'center',
                              px: 1,
                              py: 0.5,
                              backgroundColor: group.code_couleur,
                              color: '#fff',
                              borderRadius: '16px',
                              fontSize: '0.75rem',
                            }}
                          >
                            {group.libelle}
                          </Box>
                        ))}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleEditUser(user)} color="primary">
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => {
                        setUserToDelete(user.id);
                        setDeleteConfirmDialogOpen(true);
                      }} color="error">
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    No se encontraron usuarios.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Add User Dialog */}
      <Dialog open={addUserDialogOpen} onClose={() => setAddUserDialogOpen(false)} fullWidth maxWidth="sm">
        <DialogContent sx={{ position: 'relative', minHeight: '300px' }}>
          {isLoading && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 9999,
              }}
            >
              <CircularProgress color="primary" />
              <Typography variant="h6" sx={{ color: 'white', mt: 2 }}>
                Procesando...
              </Typography>
            </Box>
          )}
          <Typography variant="h6">Añadir un nuevo usuario</Typography>
          {errorMessage && (
            <Typography color="error" sx={{ mt: 1, mb: 2 }}>
              {errorMessage}
            </Typography>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 2 }}>
            <IconButton component="label" sx={{ position: 'relative' }}>
              <Avatar
                sx={{ width: 100, height: 100 }}
                src={newUser.avatar}
                alt="Avatar"
              />
              <input
                type="file"
                accept="image/*"
                capture="environment"
                hidden
                onChange={(e) => handleCapturePhoto(e, false)}
              />
              <PhotoCamera sx={{ position: 'absolute', bottom: 0, right: 0, backgroundColor: 'white', borderRadius: '50%', padding: '4px' }} />
            </IconButton>
          </Box>

          <TextField
            label="Nombre"
            variant="outlined"
            fullWidth
            sx={{ mt: 2 }}
            value={newUser.prenom}
            onChange={(e) => setNewUser({ ...newUser, prenom: e.target.value })}
          />
          <TextField
            label="Apellido"
            variant="outlined"
            fullWidth
            sx={{ mt: 2 }}
            value={newUser.nom}
            onChange={(e) => setNewUser({ ...newUser, nom: e.target.value })}
          />
          <TextField
            label="Correo electrónico"
            variant="outlined"
            fullWidth
            sx={{ mt: 2 }}
            value={newUser.email}
            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
          />
          <TextField
            label="Nombre de usuario"
            variant="outlined"
            fullWidth
            sx={{ mt: 2 }}
            value={newUser.username}
            onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
          />
          <TextField
            label="Contraseña"
            type="password"
            variant="outlined"
            fullWidth
            sx={{ mt: 2 }}
            value={newUser.password}
            onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
          />
          <Select
            value={newUser.role}
            onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
            displayEmpty
            fullWidth
            sx={{ mt: 2 }}
          >
            <MenuItem value="" disabled>Seleccionar Rol</MenuItem>
            {getAvailableRoles().map((role) => (
              <MenuItem key={role} value={role}>{role}</MenuItem>
            ))}
          </Select>

          {newUser.role === 'entrenador' && (
            <Autocomplete
              multiple
              fullWidth
              options={groups}
              getOptionLabel={(group) => group?.libelle || ''}
              value={groups.filter(group => newUser.groupes.includes(group.id))}
              onChange={(event, newValue) => {
                setNewUser({
                  ...newUser,
                  groupes: newValue.map(group => group.id)
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Seleccionar Grupo(s)"
                  variant="outlined"
                  sx={{ mt: 2 }}
                />
              )}
              renderOption={(props, group) => (
                <Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Chip
                    label={group.libelle}
                    size="small"
                    style={{
                      backgroundColor: group.code_couleur,
                      color: '#fff',
                      borderRadius: '16px'
                    }}
                  />
                </Box>
              )}
              renderTags={(groupValues, getTagProps) =>
                groupValues.map((group, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    key={group.id}
                    label={group.libelle}
                    size="small"
                    style={{
                      backgroundColor: group.code_couleur,
                      color: '#fff',
                      borderRadius: '16px',
                      marginRight: 4
                    }}
                  />
                ))
              }
            />
          )}

          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
            <Switch
              checked={newUser.is_active}
              onChange={(e) => {
                console.log('Previous state:', userToEdit?.is_active);
                console.log('New state:', e.target.checked);
                setUserToEdit({ ...userToEdit, is_active: e.target.checked })
              }}
            />
            <Typography>
              {newUser.is_active ? 'Usuario activo' : 'Usuario inactivo'}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddUserDialogOpen(false)} color="primary">Cancelar</Button>
          <Button onClick={handleAddUser} color="primary" variant="contained">Guardar</Button>
        </DialogActions>
      </Dialog>

      {/* Edit User Dialog */}
      <Dialog open={editUserDialogOpen} onClose={() => setEditUserDialogOpen(false)} fullWidth maxWidth="sm">
        <DialogContent sx={{ position: 'relative', minHeight: '300px' }}>
          {isLoading && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 9999,
              }}
            >
              <CircularProgress color="primary" />
              <Typography variant="h6" sx={{ color: 'white', mt: 2 }}>
                Procesando...
              </Typography>
            </Box>
          )}
          <Typography variant="h6">Editar usuario</Typography>
          {errorMessage && (
            <Typography color="error" sx={{ mt: 1, mb: 2 }}>
              {errorMessage}
            </Typography>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 2 }}>
            <IconButton component="label" sx={{ position: 'relative' }}>
              <Avatar
                sx={{ width: 100, height: 100 }}
                src={userToEdit?.avatar}
                alt="Avatar"
              />
              <input
                type="file"
                accept="image/*"
                capture="environment"
                hidden
                onChange={(e) => handleCapturePhoto(e, true)}
              />
              <PhotoCamera sx={{ position: 'absolute', bottom: 0, right: 0, backgroundColor: 'white', borderRadius: '50%', padding: '4px' }} />
            </IconButton>
          </Box>

          <TextField
            label="Nombre"
            variant="outlined"
            fullWidth
            sx={{ mt: 2 }}
            value={userToEdit?.prenom || ''}
            onChange={(e) => setUserToEdit({ ...userToEdit, prenom: e.target.value })}
          />
          <TextField
            label="Apellido"
            variant="outlined"
            fullWidth
            sx={{ mt: 2 }}
            value={userToEdit?.nom || ''}
            onChange={(e) => setUserToEdit({ ...userToEdit, nom: e.target.value })}
          />
          <TextField
            label="Correo electrónico"
            variant="outlined"
            fullWidth
            sx={{ mt: 2 }}
            value={userToEdit?.email || ''}
            onChange={(e) => setUserToEdit({ ...userToEdit, email: e.target.value })}
          />
          <TextField
            label="Nombre de usuario"
            variant="outlined"
            fullWidth
            sx={{ mt: 2 }}
            value={userToEdit?.username || ''}
            onChange={(e) => setUserToEdit({ ...userToEdit, username: e.target.value })}
          />
          <TextField
            label="Contraseña"
            type="password"
            variant="outlined"
            fullWidth
            sx={{ mt: 2 }}
            value={userToEdit?.password || ''}
            onChange={(e) => setUserToEdit({ ...userToEdit, password: e.target.value })}
          />
          <Select
            value={userToEdit?.role || ''}
            onChange={(e) => setUserToEdit({ ...userToEdit, role: e.target.value })}
            displayEmpty
            fullWidth
            sx={{ mt: 2 }}
          >
            <MenuItem value="" disabled>Seleccionar Rol</MenuItem>
            {getAvailableRoles().map((role) => (
              <MenuItem key={role} value={role}>{role}</MenuItem>
            ))}
          </Select>

          {userToEdit?.role === 'entrenador' && (
            <Autocomplete
              multiple
              fullWidth
              options={groups}
              getOptionLabel={(group) => group?.libelle || ''}
              value={groups.filter(group => userToEdit.groupes.includes(group.id))}
              onChange={(event, newValue) => {
                setUserToEdit({
                  ...userToEdit,
                  groupes: newValue.map(group => group.id)
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Seleccionar Grupo(s)"
                  variant="outlined"
                  sx={{ mt: 2 }}
                />
              )}
              renderOption={(props, group) => (
                <Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Chip
                    label={group.libelle}
                    size="small"
                    style={{
                      backgroundColor: group.code_couleur,
                      color: '#fff',
                      borderRadius: '16px'
                    }}
                  />
                </Box>
              )}
              renderTags={(groupValues, getTagProps) =>
                groupValues.map((group, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    key={group.id}
                    label={group.libelle}
                    size="small"
                    style={{
                      backgroundColor: group.code_couleur,
                      color: '#fff',
                      borderRadius: '16px',
                      marginRight: 4
                    }}
                  />
                ))
              }
            />
          )}

          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
            <Switch
              checked={userToEdit?.is_active || false}
              onChange={(e) => {
                console.log('Previous state:', userToEdit?.is_active);
                console.log('New state:', e.target.checked);
                setUserToEdit({ ...userToEdit, is_active: e.target.checked })
              }}
            />
            <Typography>
              {userToEdit?.is_active ? 'Usuario activo' : 'Usuario inactivo'}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditUserDialogOpen(false)} color="primary">Cancelar</Button>
          <Button onClick={handleUpdateUser} color="primary" variant="contained">Guardar</Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteConfirmDialogOpen} onClose={() => setDeleteConfirmDialogOpen(false)}>
        <DialogContent>
          <Typography variant="h6">Confirmar eliminación</Typography>
          <Typography>¿Está seguro de que desea eliminar este usuario? Esta acción eliminará todas las fichas asociadas a este usuario. Alternativamente, puede desactivar el usuario manteniendo todos sus datos.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmDialogOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteUser} color="error" variant="contained">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Pagination */}
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={(event, value) => setPage(value)}
          color="primary"
          size={isMobile ? "small" : "medium"}
        />
      </Box>

      <Backdrop
        sx={{ 
          color: '#fff', 
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
        <Typography variant="h6" component="div" sx={{ mt: 2 }}>
          Procesando...
        </Typography>
      </Backdrop>
    </Box>
  );
};

export default Usuarios;