import React from 'react';
import { VariableSizeList } from 'react-window';
import { useTheme } from '@mui/material/styles';

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  const item = data[index];

  return React.cloneElement(item, {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef(function OuterElementType(props, ref) {
  const outerProps = React.useContext(OuterElementContext);
  return (
    <div 
      ref={ref} 
      {...props} 
      {...outerProps} 
      style={{ 
        ...props.style,
        overflowX: 'hidden',
        overflowY: 'scroll', // Pour toujours montrer la barre de défilement verticale
        maxWidth: '100%'
      }}
    />
  );
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const itemCount = itemData.length;
  const itemSize = 48; // Hauteur de chaque élément (à ajuster si nécessaire)

  const getChildSize = () => itemSize;

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemCount * itemSize;
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={getChildSize}
          overscanCount={5}
          itemCount={itemCount}
          style={{
            padding: 0,
            margin: 0,
            overflowX: 'hidden'
          }}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

export default ListboxComponent;