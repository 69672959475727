import React, { useState } from 'react';
import { 
  Button, 
  TextField, 
  Typography, 
  Container, 
  Box, 
  Avatar,
  useTheme,
  useMediaQuery,
  IconButton,
  InputAdornment,
  CircularProgress
} from '@mui/material';
import { 
  createTheme, 
  ThemeProvider,
  responsiveFontSizes 
} from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';

// Créer et optimiser le thème pour le responsive
let theme = createTheme({
  palette: {
    primary: {
      main: '#F25F29',
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          '@media (max-width:600px)': {
            padding: '16px',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            '@media (max-width:600px)': {
              fontSize: '1rem',
              height: '48px',
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '@media (max-width:600px)': {
            padding: '12px',
            fontSize: '1rem',
          },
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

function LoginPage({ onLogin }) {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const avatarPath = process.env.REACT_APP_SECTION === 'hommes'
    ? '/assets/avatar_homme.png'
    : '/assets/avatar_femme.png';

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          login,
          password,
          section: process.env.REACT_APP_SECTION,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        const userData = {
          nom: data.user.nom,
          prenom: data.user.prenom,
          avatar: data.user.avatar,
          role: data.user.role,
          id: data.user.id,
        };
        localStorage.setItem('user', JSON.stringify(userData));
        onLogin(data.token);
        // Redirection conditionnelle selon le rôle
      if (data.user.role === 'entrenador') {
        navigate('/jugadores');
      } else {
        navigate('/panel-de-control');
      }
      } else {
        setError(data.message || 'Nombre de usuario o contraseña incorrecta');
      }
    } catch (err) {
      setError('Error de conexión. Por favor, inténtelo de nuevo.');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          minHeight: '100vh',
          backgroundColor: '#F25F29',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: isMobile ? '16px' : '24px'
        }}
      >
        <Container
          maxWidth="xs"
          sx={{
            backgroundColor: '#ffffff',
            padding: isMobile ? '16px' : '24px',
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            width: '100%'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            component="form"
            onSubmit={handleLogin}
          >
            <img
              src="/assets/valenciaapp.png"
              alt="Valencia App Logo"
              style={{ 
                width: isMobile ? '120px' : '150px', 
                marginBottom: '16px',
                height: 'auto'
              }}
            />
            
            <Box 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                mb: 2,
                flexDirection: isMobile ? 'column' : 'row',
                gap: isMobile ? 2 : 1
              }}
            >
             
              <Avatar
                src={avatarPath}
                alt="Avatar"
                sx={{ 
                  width: isMobile ? 80 : 100, 
                  height: isMobile ? 80 : 100
                }}
              />
            </Box>

            {error && (
              <Typography 
                color="error" 
                variant="body2" 
                sx={{ 
                  mb: 2,
                  textAlign: 'center',
                  width: '100%'
                }}
              >
                {error}
              </Typography>
            )}

            <TextField
              margin="normal"
              fullWidth
              id="login"
              label="Login"
              name="login"
              autoComplete="username"
              autoFocus
              variant="outlined"
              value={login}
              onChange={(e) => setLogin(e.target.value)}
              disabled={isLoading}
              sx={{
                '& .MuiInputBase-root': {
                  borderRadius: '8px'
                }
              }}
            />

            <TextField
              margin="normal"
              fullWidth
              name="password"
              label="Contraseña"
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={isLoading}
              sx={{
                '& .MuiInputBase-root': {
                  borderRadius: '8px'
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isLoading}
              sx={{ 
                mt: 3, 
                mb: 2,
                height: '48px',
                borderRadius: '8px',
                textTransform: 'none',
                fontSize: '1.1rem'
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Iniciar sesión'
              )}
            </Button>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default LoginPage;