import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box,
  Paper, 
  Typography,
  IconButton,
  Avatar,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Autocomplete,
  TextField,
  CircularProgress,
  Alert,
  SwipeableDrawer,
  Divider,
  useTheme,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Fab,
  TablePagination,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction
} from '@mui/material';
import {
  ExpandMore,
  FilterList,
  Edit,
  Delete as DeleteIcon,
  Launch,
  Close
} from '@mui/icons-material';
import axios from 'axios';
import ListboxComponent from '../components/ListboxComponent ';

// Utility functions
const getInitials = (nom, prenom) => {
  const firstInitial = nom ? nom[0] : '';
  const secondInitial = prenom ? prenom[0] : '';
  return (firstInitial + secondInitial).toUpperCase();
};

const getAvatarColor = (name) => {
  if (!name) return '#757575';
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const hue = Math.abs(hash % 360);
  return `hsl(${hue}, 70%, 50%)`;
};

const getContrastColor = (hexcolor) => {
  if (!hexcolor) return '#000000';
  const r = parseInt(hexcolor.slice(1, 3), 16);
  const g = parseInt(hexcolor.slice(3, 5), 16);
  const b = parseInt(hexcolor.slice(5, 7), 16);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.5 ? '#000000' : '#FFFFFF';
};

// UserAvatar component
const UserAvatar = ({ user, mediaUrl, size = 24 }) => (
  <Tooltip
    arrow
    componentsProps={{
      tooltip: {
        sx: {
          bgcolor: 'white',
          '& .MuiTooltip-arrow': {
            color: 'white'
          },
          boxShadow: '0px 2px 8px rgba(0,0,0,0.15)'
        }
      }
    }}
    title={
      <Box sx={{ p: 1, display: 'flex', alignItems: 'center', gap: 2 }}>
        <Avatar
          src={`${mediaUrl}/users/${user.id}.png`}
          sx={{ width: 40, height: 40 }}
        />
        <Box>
          <Typography sx={{ fontWeight: 'bold', color: 'black' }}>
            {user.prenom} {user.nom}
          </Typography>
          {user.username && (
            <Typography variant="body2" sx={{ color: 'rgba(0,0,0,0.7)' }}>
              {user.username}
            </Typography>
          )}
        </Box>
      </Box>
    }
  >
    <Avatar
      sx={{
        width: size,
        height: size,
        fontSize: `${size * 0.5}px`,
        bgcolor: getAvatarColor(user.nom + user.prenom)
      }}
    >
      {getInitials(user.prenom, user.nom)}
    </Avatar>
  </Tooltip>
);

// PlayerCard component with URL link
const PlayerCard = ({ player, onPlayerClick, onPlayerUrlClick, mediaUrl }) => (
  <ListItem
    button
    onClick={() => onPlayerClick(player)}
    sx={{
      mb: 1,
      borderRadius: 1,
      border: '1px solid',
      borderColor: 'divider',
      bgcolor: 'background.paper',
      '&:hover': {
        bgcolor: 'action.hover'
      }
    }}
  >
    <ListItemAvatar>
      <Avatar
        src={`${mediaUrl}/photos/${player.id}.png`}
        alt={player.nom}
        sx={{ width: 40, height: 40 }}
      />
    </ListItemAvatar>
    <ListItemText
      primary={player.nom}
      secondary={
        <>
          <Typography variant="caption" display="block" color="textSecondary">
            {player.poste}
          </Typography>
          {player.team && (
            <Typography variant="caption" display="block" color="textSecondary">
              {player.team}
            </Typography>
          )}
        </>
      }
    />
    {player.url && (
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            onPlayerUrlClick(player.url, e);
          }}
        >
          <Launch fontSize="small" />
        </IconButton>
      </ListItemSecondaryAction>
    )}
  </ListItem>
);

// Filter drawer component
const FilterDrawer = ({
  open,
  onClose,
  players,
  users,
  selectedPlayer,
  selectedUser,
  onPlayerChange,
  onUserChange,
  mediaUrl
}) => {
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
      sx={{
        '& .MuiDrawer-paper': {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          maxHeight: '80vh',
          pb: 2
        }
      }}
    >
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h6">Filtros</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>

        <Divider sx={{ mb: 2 }} />

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              options={players.players || []}
              getOptionLabel={(player) => player?.nom || ''}
              value={selectedPlayer}
              onChange={onPlayerChange}
              renderInput={(params) => (
                <TextField {...params} label="Filtrar por jugador" />
              )}
              renderOption={(props, player) => (
                <Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Avatar
                    src={`${mediaUrl}/photos/${player.id}.png`}
                    alt={player.nom}
                    sx={{ width: 32, height: 32 }}
                  />
                  <Typography noWrap>{player.nom}</Typography>
                </Box>
              )}
              ListboxComponent={ListboxComponent}
              clearOnEscape
            />
          </Grid>
          
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              options={users}
              getOptionLabel={(user) => user ? `${user.prenom} ${user.nom}` : ''}
              value={selectedUser}
              onChange={onUserChange}
              renderInput={(params) => (
                <TextField {...params} label="Filtrar por usuario" />
              )}
              renderOption={(props, user) => (
                <Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <UserAvatar user={user} mediaUrl={mediaUrl} size={32} />
                  <Typography noWrap>{`${user.prenom} ${user.nom}`}</Typography>
                </Box>
              )}
              clearOnEscape
            />
          </Grid>
        </Grid>
      </Box>
    </SwipeableDrawer>
  );
};

// Tag section component
const SeguimientoSection = ({
  tag,
  seguimientos,
  onEdit,
  onDelete,
  onPlayerClick,
  onPlayerUrlClick,
  mediaUrl
}) => (
  <Accordion  sx={{ mb: 1 }}>
    <AccordionSummary
      expandIcon={<ExpandMore />}
      sx={{
        bgcolor: 'background.default',
        borderBottom: '1px solid',
        borderColor: 'divider'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Chip
          label={tag.libelle}
          style={{
            backgroundColor: tag.code_couleur,
            color: getContrastColor(tag.code_couleur)
          }}
        />
        <Typography color="text.secondary">
          {seguimientos.reduce((sum, s) => sum + s.players.length, 0)} Jugador@s
        </Typography>
      </Box>
    </AccordionSummary>
    
    <AccordionDetails sx={{ p: 2 }}>
      {seguimientos.map((seguimiento) => (
        <Paper key={seguimiento.id} sx={{ p: 2, mb: 2, '&:last-child': { mb: 0 } }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                {seguimiento.libelle}
              </Typography>
              <UserAvatar user={seguimiento.user} mediaUrl={mediaUrl} />
            </Box>
            
            <Box>
              <IconButton
                size="small"
                onClick={() => onEdit(seguimiento.id)}
                color="primary"
                sx={{ mr: 1 }}
              >
                <Edit />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => onDelete(seguimiento)}
                color="error"
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>

          <List disablePadding>
            {seguimiento.players
              .sort((a, b) => a.nom.localeCompare(b.nom))
              .map((player) => (
                <PlayerCard
                  key={player.id}
                  player={player}
                  onPlayerClick={onPlayerClick}
                  onPlayerUrlClick={onPlayerUrlClick}
                  mediaUrl={mediaUrl}
                />
              ))}
          </List>
        </Paper>
      ))}
    </AccordionDetails>
  </Accordion>
);
// Main component
const SeguimientoTable = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const section = process.env.REACT_APP_SECTION;

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [seguimientoToDelete, setSeguimientoToDelete] = useState(null);
  const [players, setPlayers] = useState([]);
  const [users, setUsers] = useState([]);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [tags, setTags] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [tagsResponse, playersResponse, usersResponse] = await Promise.all([
          axios.get(`${backendUrl}/api/tags`, { params: { section } }),
          axios.get(`${backendUrl}/api/players/select`, { params: { section } }),
          axios.get(`${backendUrl}/api/users`, { params: { section } })
        ]);

        setTags(tagsResponse.data);
        setPlayers(playersResponse.data);
        setUsers(Array.isArray(usersResponse.data) ? usersResponse.data : []);
      } catch (error) {
        console.error("Error al cargar los datos iniciales:", error);
      }
    };

    fetchInitialData();
    refreshData();
  }, [backendUrl, section]);

  const refreshData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${backendUrl}/api/seguimiento`, {
        params: { section }
      });
      setData(response.data);
      setError(null);
    } catch (error) {
      console.error("Error al cargar los Seguimientos:", error);
      setError("No se pueden cargar los Seguimientos.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleFocus = () => {
      refreshData();
    };
    window.addEventListener('focus', handleFocus);
    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, []);

  const handleEditClick = (seguimientoId) => {
    navigate(`/seguimiento/edit/${seguimientoId}`);
  };

  const handleDelete = async (seguimientoId) => {
    try {
      await axios.delete(`${backendUrl}/api/seguimiento/${seguimientoId}`, {
        params: { section }
      });
      await refreshData();
      setOpenDeleteDialog(false);
      setSeguimientoToDelete(null);
    } catch (error) {
      console.error("Error al eliminar el Seguimiento:", error);
      setError("Error al eliminar el Seguimiento.");
    }
  };

  const handlePlayerClick = (player) => {
    navigate(`/players/${player.id}`);
  };

  const handlePlayerUrlClick = (url, event) => {
    event.stopPropagation();
    window.open(url, '_blank');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Filter and group data
  const filteredData = React.useMemo(() => {
    return data.filter(seguimiento => {
      const matchesPlayer = !selectedPlayer || seguimiento.players.some(p => p.id === selectedPlayer.id);
      const matchesUser = !selectedUser || seguimiento.user.id === selectedUser.id;
      return matchesPlayer && matchesUser;
    });
  }, [data, selectedPlayer, selectedUser]);

  const groupedByTag = React.useMemo(() => {
    const groups = new Map();
    
    tags.forEach(tag => {
      groups.set(tag.id, {
        tag,
        seguimientos: []
      });
    });

    filteredData.forEach(seguimiento => {
      seguimiento.tags.forEach(tag => {
        if (groups.has(tag.id)) {
          groups.get(tag.id).seguimientos.push(seguimiento);
        }
      });
    });

    return Array.from(groups.values())
      .filter(group => group.seguimientos.length > 0)
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [tags, filteredData, page, rowsPerPage]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {error}
      </Alert>
    );
  }

  if (!data || data.length === 0) {
    return (
      <Alert severity="info" sx={{ m: 2 }}>
        No hay Seguimientos disponibles
      </Alert>
    );
  }

  return (
    <Paper elevation={2}>
      <Box sx={{ p: 2, pb: isMobile ? 7 : 2 }}>
        {!isMobile && (
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12} md={6}>
              <Autocomplete
                fullWidth
                options={players.players || []}
                getOptionLabel={(player) => player?.nom || ''}
                value={selectedPlayer}
                onChange={(_, newValue) => setSelectedPlayer(newValue)}
                renderInput={(params) => (
                  <TextField {...params} size="small" label="Filtrar por jugador" />
                )}
                renderOption={(props, player) => (
                  <Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Avatar
                      src={`${mediaUrl}/photos/${player.id}.png`}
                      alt={player.nom}
                      sx={{ width: 32, height: 32 }}
                    />
                    <Typography noWrap>{player.nom}</Typography>
                  </Box>
                )}
                ListboxComponent={ListboxComponent}
                clearOnEscape
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                fullWidth
                options={users}
                getOptionLabel={(user) => user ? `${user.prenom} ${user.nom}` : ''}
                value={selectedUser}
                onChange={(_, newValue) => setSelectedUser(newValue)}
                renderInput={(params) => (
                  <TextField {...params} size="small" label="Filtrar por usuario" />
                )}
                renderOption={(props, user) => (
                  <Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <UserAvatar user={user} mediaUrl={mediaUrl} size={32} />
                    <Typography noWrap>{`${user.prenom} ${user.nom}`}</Typography>
                  </Box>
                )}
                clearOnEscape
              />
            </Grid>
          </Grid>
        )}

        {groupedByTag.map(({ tag, seguimientos }) => (
          <SeguimientoSection
            key={tag.id}
            tag={tag}
            seguimientos={seguimientos}
            onEdit={handleEditClick}
            onDelete={(seguimiento) => {
              setSeguimientoToDelete(seguimiento);
              setOpenDeleteDialog(true);
            }}
            onPlayerClick={handlePlayerClick}
            onPlayerUrlClick={handlePlayerUrlClick}
            mediaUrl={mediaUrl}
          />
        ))}

        {isMobile && (
          <Fab
            color="primary"
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 16,
              zIndex: theme.zIndex.fab
            }}
            onClick={() => setOpenFilterDrawer(true)}
          >
            <FilterList />
          </Fab>
        )}

        <TablePagination
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage="Elementos por página:"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
        />
      </Box>

      <FilterDrawer
        open={openFilterDrawer}
        onClose={() => setOpenFilterDrawer(false)}
        players={players}
        users={users}
        selectedPlayer={selectedPlayer}
        selectedUser={selectedUser}
        onPlayerChange={(_, newValue) => setSelectedPlayer(newValue)}
        onUserChange={(_, newValue) => setSelectedUser(newValue)}
        mediaUrl={mediaUrl}
      />

      <Dialog
        open={openDeleteDialog} onClose={() => {
          setOpenDeleteDialog(false);
          setSeguimientoToDelete(null);
        }}
      >
        <DialogTitle>Confirmar eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Está seguro de que desea eliminar el Seguimiento "{seguimientoToDelete?.libelle}"? 
            Esta acción no se puede deshacer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => {
              setOpenDeleteDialog(false);
              setSeguimientoToDelete(null);
            }}
          >
            Cancelar
          </Button>
          <Button 
            color="error" 
            onClick={() => handleDelete(seguimientoToDelete?.id)}
            variant="contained"
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default SeguimientoTable;