import React, { useState, useEffect } from 'react';
import { 
  Box, Typography, Paper,
  Table, TableBody, TableCell, 
  TableContainer, TableHead, TableRow,
  CircularProgress, Alert,
  Card, CardContent,
  useTheme, useMediaQuery,
  Divider
} from '@mui/material';
import axios from 'axios';
import DOMPurify from 'dompurify';

const PDPTab = ({ playerId, onDataLoaded }) => {
  const [pdpData, setPdpData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const fetchPDPData = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/tecnification/player/${playerId}/fiches`, {
          params: { section }
        });
        setPdpData(response.data);
        onDataLoaded(response.data.length > 0);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching PDP data:', error);
        setError('Error al cargar los datos del programa PDP');
        onDataLoaded(false);
        setLoading(false);
      }
    };

    fetchPDPData();
  }, [playerId, backendUrl, section, onDataLoaded]);

  const createMarkup = (html) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  const MobileView = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {pdpData.map((entry) => (
        <Card key={entry.id} sx={{ width: '100%' }}>
          <CardContent>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mb: 2,
              pb: 1,
              borderBottom: 1,
              borderColor: 'divider'
            }}>
              <Typography variant="subtitle1" sx={{ color: '#FF8C00' }}>
                {new Date(entry.fecha).toLocaleDateString()}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {entry.evaluador}
              </Typography>
            </Box>

            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#FF8C00', mb: 1 }}>
                Objetivos
              </Typography>
              <div dangerouslySetInnerHTML={createMarkup(entry.objetivos)} />
            </Box>

            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#FF8C00', mb: 1 }}>
                Implementar
              </Typography>
              <div dangerouslySetInnerHTML={createMarkup(entry.implementar)} />
            </Box>

            {entry.observaciones && (
              <Box>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#FF8C00', mb: 1 }}>
                  Observaciones
                </Typography>
                <div dangerouslySetInnerHTML={createMarkup(entry.observaciones)} />
              </Box>
            )}
          </CardContent>
        </Card>
      ))}
    </Box>
  );

  const DesktopView = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      {pdpData.map((entry) => (
        <Paper key={entry.id} sx={{ overflow: 'hidden' }}>
          {/* En-tête avec date et évaluateur */}
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#FF8C00',
            p: 1.5,
            color: 'white'
          }}>
            <Typography sx={{ fontWeight: 'medium' }}>
              {new Date(entry.fecha).toLocaleDateString()}
            </Typography>
            <Typography>
              {entry.evaluador}
            </Typography>
          </Box>

          {/* Tableau des contenus */}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: 'rgba(255, 140, 0, 0.1)' }}>
                  <TableCell sx={{ fontWeight: 'bold', width: '33%' }}>
                    Objetivos
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', width: '33%' }}>
                    Implementar
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', width: '33%' }}>
                    Observaciones
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ verticalAlign: 'top', p: 2 }}>
                    <div dangerouslySetInnerHTML={createMarkup(entry.objetivos)} />
                  </TableCell>
                  <TableCell sx={{ verticalAlign: 'top', p: 2 }}>
                    <div dangerouslySetInnerHTML={createMarkup(entry.implementar)} />
                  </TableCell>
                  <TableCell sx={{ verticalAlign: 'top', p: 2 }}>
                    <div dangerouslySetInnerHTML={createMarkup(entry.observaciones)} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ))}
    </Box>
  );

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress sx={{ color: '#FF8C00' }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {error}
      </Alert>
    );
  }

  if (!pdpData || pdpData.length === 0) {
    return (
      <Alert severity="info" sx={{ m: 2 }}>
        No hay datos PDP disponibles para este jugador
      </Alert>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
     

      {isMobile ? <MobileView /> : <DesktopView />}
    </Box>
  );
};

export default PDPTab;