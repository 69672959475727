import React, { useState } from 'react';
import { 
  Box, Button, Typography, Grid,
  useTheme, useMediaQuery, Avatar
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios';

const FichaTecnificacion = ({ playerId, onFichaAdded }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const section = process.env.REACT_APP_SECTION;
  
  const [isEditing, setIsEditing] = useState(false);
  const [objetivos, setObjetivos] = useState('');
  const [implementar, setImplementar] = useState('');
  const [observaciones, setObservaciones] = useState('');

  

  // Récupérer les informations de l'utilisateur du localStorage
  const userInfo = JSON.parse(localStorage.getItem('user'));
  const currentDate = new Date().toLocaleDateString();

  const handleNewFicha = () => {
    setIsEditing(true);
    setObjetivos('');
    setImplementar('');
    setObservaciones('');
  };

  const handleCancel = () => {
    setIsEditing(false);
    setObjetivos('');
    setImplementar('');
    setObservaciones('');
  };

  const handleSaveFicha = async () => {
    if (!objetivos && !implementar && !observaciones) return;

    try {
      await axios.post(`${backendUrl}/api/tecnification/fiches`, {
        tecnification_player_id: playerId,
        user_id: userInfo.id,
        objetivos,
        implementar,
        observaciones,
        section
      });
      
      // Réinitialiser
      setIsEditing(false);
      setObjetivos('');
      setImplementar('');
      setObservaciones('');
      
      // Rafraîchir la liste des fiches
      if (onFichaAdded) {
        onFichaAdded();
      }
    } catch (error) {
      console.error("Error al crear la ficha:", error);
    }
  };

  const editorModules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      ['link'],  // Ajout de l'option des liens
      ['clean']
    ],
  };
  
  const editorFormats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet',
    'link'  // Ajout du format lien
  ];

  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">
        </Typography>
        {!isEditing && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleNewFicha}
          >
            Nueva ficha
          </Button>
        )}
      </Box>

      {isEditing && (
        <>
          {/* En-tête de la fiche avec les infos utilisateur */}
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 2, 
            mb: 3,
            p: 2,
            bgcolor: 'grey.100',
            borderRadius: 1
          }}>
            <Avatar
              src={`${mediaUrl}/users/${userInfo.id}.png`}
              alt={`${userInfo.prenom} ${userInfo.nom}`}
              sx={{ width: 48, height: 48 }}
            />
            <Box>
              <Typography variant="subtitle1">
                {`${userInfo.prenom} ${userInfo.nom}`}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {currentDate}
              </Typography>
            </Box>
          </Box>

          <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
  <Box>
    <Typography variant="subtitle2" gutterBottom>
      Objetivos
    </Typography>
    <Box sx={{ 
      border: '1px solid',
      borderColor: 'divider',
      borderRadius: 1,
      overflow: 'hidden',
      '& .ql-toolbar': {
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderBottom: '1px solid',
        borderColor: 'divider'
      },
      '& .ql-container': {
        border: 'none',
        minHeight: isMobile ? '200px' : '200px'
      },
      '& .ql-editor': {
        minHeight: isMobile ? '200px' : '200px'
      }
    }}>
      <ReactQuill
        value={objetivos}
        onChange={setObjetivos}
        modules={editorModules}
        formats={editorFormats}
      />
    </Box>
  </Box>
</Grid>
            
            {/* Implementar */}
  <Grid item xs={12} md={4}>
    <Box>
      <Typography variant="subtitle2" gutterBottom>
        Implementar
      </Typography>
      <Box sx={{ 
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        overflow: 'hidden',
        '& .ql-toolbar': {
          borderTop: 'none',
          borderLeft: 'none',
          borderRight: 'none',
          borderBottom: '1px solid',
          borderColor: 'divider'
        },
        '& .ql-container': {
          border: 'none',
          minHeight: isMobile ? '200px' : '200px'
        },
        '& .ql-editor': {
          minHeight: isMobile ? '200px' : '200px'
        }
      }}>
        <ReactQuill
          value={implementar}
          onChange={setImplementar}
          modules={editorModules}
          formats={editorFormats}
        />
      </Box>
    </Box>
  </Grid>

  {/* Observaciones */}
  <Grid item xs={12} md={4}>
    <Box>
      <Typography variant="subtitle2" gutterBottom>
        Observaciones
      </Typography>
      <Box sx={{ 
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        overflow: 'hidden',
        '& .ql-toolbar': {
          borderTop: 'none',
          borderLeft: 'none',
          borderRight: 'none',
          borderBottom: '1px solid',
          borderColor: 'divider'
        },
        '& .ql-container': {
          border: 'none',
          minHeight: isMobile ? '200px' : '200px'
        },
        '& .ql-editor': {
          minHeight: isMobile ? '200px' : '200px'
        }
      }}>
        <ReactQuill
          value={observaciones}
          onChange={setObservaciones}
          modules={editorModules}
          formats={editorFormats}
        />
      </Box>
    </Box>
  </Grid>
</Grid>

          <Box sx={{ 
            mt: 2, 
            display: 'flex', 
            justifyContent: 'flex-end',
            gap: 2
          }}>
            <Button
              variant="outlined"
              color="error"
              startIcon={<CancelIcon />}
              onClick={handleCancel}
            >
              Cancelar
            </Button>
            <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={handleSaveFicha}
            disabled={!objetivos && !implementar && !observaciones} // Changé ici - au moins un champ requis
            >
            Guardar ficha
            </Button>
          </Box>
        </>
      )}

     
     
    </Box>
  );
};

export default FichaTecnificacion;