import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  CircularProgress,
  IconButton,
  Avatar,
} from '@mui/material';
import {
  People as PeopleIcon,
  School as SchoolIcon,
  AssignmentOutlined as ReportIcon,
  Psychology as IntelIcon,
  SportsBasketball as TechIcon,
  Assessment as AssessmentIcon,
  NavigateNext,
  NavigateBefore,
} from '@mui/icons-material';
import axios from 'axios';

const Dashboard = () => {
  // États
  const [stats, setStats] = useState({
    proCount: 0,
    canteraCount: 0,
    recentInformes: { count: 0, players: [] },
    recentIntel: { count: 0, players: [] },
    recentTech: { count: 0, players: [] },
    bigBoardCount: 0
  });
  const [canteraPhotos, setCanteraPhotos] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  // Variables d'environnement
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  // Effet pour charger les données
  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/dashboard`, {
          params: { section }
        });
  
        setStats({
          proCount: response.data.proCount,
          canteraCount: response.data.canteraCount,
          recentInformes: {
            count: response.data.recentInformes.count,
            players: response.data.recentInformes.players
          },
          recentIntel: {
            count: response.data.recentIntel.count,
            players: response.data.recentIntel.players
          },
          recentTech: {
            count: response.data.recentTech.count,
            players: response.data.recentTech.players
          },
          bigBoardCount: response.data.bigBoardCount
        });
  
        // Créer un tableau de 1 à 18 et le mélanger
        const photoIds = shuffleArray(Array.from({ length: 17 }, (_, i) => i + 1));
        
        setCanteraPhotos(
          photoIds.map(id => ({
            id,
            photo: `${mediaUrl}/summits/${id}.png`
          }))
        );
  
        setIsLoading(false);
      } catch (error) {
        console.error('Error al cargar los datos:', error);
        setIsLoading(false);
      }
    };
  
    fetchDashboardData();
  }, [backendUrl, section, mediaUrl]);

  // Effet pour le diaporama automatique
  useEffect(() => {
    if (canteraPhotos.length > 0) {
      const timer = setInterval(() => {
        setCurrentSlide(prev => (prev + 1) % canteraPhotos.length);
      }, 3000); // Change toutes les 5 secondes

      return () => clearInterval(timer);
    }
  }, [canteraPhotos]);

  const MAX_PLAYERS = 5; // Limite de joueurs à afficher

  // Composant pour les cartes de statistiques
  const StatCard = ({ icon: Icon, title, count, players }) => (
    
    <Paper
      elevation={3}
      sx={{
        p: 2,
        height: '100%',
        backgroundColor: '#fff',
        transition: 'transform 0.2s',
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: 6
        }
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Icon sx={{ fontSize: 40, color: '#ff9800', mr: 2 }} />
        <Box>
          <Typography variant="h4" component="div">
            {count}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {title}
          </Typography>
        </Box>
      </Box>
      {players && players.length > 0 && (
        <Box>
          <Typography variant="caption" color="text.secondary">
            Jugador@s recientes:
          </Typography>
          <Typography variant="body2">
          {players.slice(0, MAX_PLAYERS).map(p => p.name).join(', ')}
          {players.length > MAX_PLAYERS && ` ... (+${players.length - MAX_PLAYERS})`}
          </Typography>
        </Box>
      )}
    </Paper>
  );

  if (isLoading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh' 
      }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      {/* Section des statistiques */}
      <Grid container spacing={6} sx={{ mb: 6 }}>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            icon={PeopleIcon}
            title="Jugador@s Pro"
            count={stats.proCount}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            icon={SchoolIcon}
            title="Jugador@s Cantera"
            count={stats.canteraCount}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            icon={AssessmentIcon}
            title="Nuevos Big Boards"
            count={stats.bigBoardCount}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            icon={ReportIcon}
            title="Nuevos Informes"
            count={stats.recentInformes.count}
            players={stats.recentInformes.players}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            icon={IntelIcon}
            title="Nuevo Intel"
            count={stats.recentIntel.count}
            players={stats.recentIntel.players}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            icon={TechIcon}
            title="Fichas Técnicas"
            count={stats.recentTech.count}
            players={stats.recentTech.players}
          />
        </Grid>
      </Grid>

      {/* Section Citation et Diaporama */}
      <Box sx={{ 
        display: 'flex', 
        flexDirection: { xs: 'column', md: 'row' }, 
        gap: 3, 
        mt: 4 
      }}>
        {/* Citation */}
        <Paper 
          elevation={3} 
          sx={{ 
            flex: 1, 
            p: 4,
            backgroundColor: '#ff9800',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Typography 
            variant="h3" 
            align="center" 
            sx={{ 
              fontStyle: 'italic',
              fontWeight: 'bold',
              textShadow: '2px 2px 4px rgba(0,0,0,0.2)'
            }}
          >
            "Cultura del esfuerzo"
          </Typography>
        </Paper>

        {/* Diaporama */}
        <Paper 
          elevation={3} 
          sx={{ 
            flex: 1,
            p: 2,
            position: 'relative',
            minHeight: '300px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <IconButton 
            sx={{ position: 'absolute', left: 10 }}
            onClick={() => setCurrentSlide(prev => (prev > 0 ? prev - 1 : canteraPhotos.length - 1))}
          >
            <NavigateBefore />
          </IconButton>

          <Box sx={{ textAlign: 'center' }}>
            {canteraPhotos.length > 0 && (
              <>
                <Avatar
                  src={canteraPhotos[currentSlide]?.photo}
                  alt={canteraPhotos[currentSlide]?.name}
                  sx={{ 
                    width: 200, 
                    height: 200, 
                    margin: '0 auto',
                    border: '4px solid #ff9800'
                  }}
                />
                <Typography variant="h6" sx={{ mt: 2 }}>
                  {canteraPhotos[currentSlide]?.name}
                </Typography>
              </>
            )}
          </Box>

          <IconButton 
            sx={{ position: 'absolute', right: 10 }}
            onClick={() => setCurrentSlide(prev => (prev < canteraPhotos.length - 1 ? prev + 1 : 0))}
          >
            <NavigateNext />
          </IconButton>
        </Paper>
      </Box>
    </Box>
  );
};

export default Dashboard;