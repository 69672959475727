import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Paper,
  TextField,
  Button,
  Typography,
  Grid,
  Chip,
  Alert,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Checkbox,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  TablePagination,
  Slider
} from '@mui/material';
import {
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import axios from 'axios';
import { SeguimientoContext } from '../SeguimientoContext';


const getContrastColor = (hexcolor) => {
  if (!hexcolor) return '#000000';
  const r = parseInt(hexcolor.slice(1, 3), 16);
  const g = parseInt(hexcolor.slice(3, 5), 16);
  const b = parseInt(hexcolor.slice(5, 7), 16);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.5 ? '#000000' : '#FFFFFF';
};

const positionMappings = {
  'base': ['base', 'Point Guard'],
  'escolta': ['escolta', 'Shooting Guard', 'Guard'],
  'alerio': ['alerio', 'Small Forward', 'Swingman'],
  'ala-pivot': ['ala-pivot', 'Power Forward', 'Power Forward / Center'],
  'pivot': ['pivot', 'Center']
};

const DraggablePlayerRow = ({ player, handlePlayerClick, checkedPlayers, handleTogglePlayer, mediaUrl }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'player',
    item: player,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  return (
    <TableRow
      ref={drag}
      key={player.id}
      hover
      sx={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={checkedPlayers.includes(player.id)}
          onChange={() => handleTogglePlayer(player.id)}
        />
      </TableCell>
      <TableCell>
        <Avatar
          src={`${mediaUrl}/photos/${player.id}.png`}
          alt={player.nom}
        />
      </TableCell>
      <TableCell>
        <Box 
          component="a"
          onClick={(e) => {
            e.preventDefault();
            handlePlayerClick(player);
          }}
          sx={{
            color: 'black',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
              cursor: 'pointer'
            }
          }}
        >
          {player.nom}
        </Box>
      </TableCell>
      <TableCell>{player.poste}</TableCell>
      <TableCell>{player.team || '-'}</TableCell>
      <TableCell>{player.league_1 || '-'}</TableCell>
    </TableRow>
  );
};

const SeguimientoModif = () => {
    const { tags = [] } = useContext(SeguimientoContext) || {};
    const { id } = useParams();
    const navigate = useNavigate();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const mediaUrl = process.env.REACT_APP_MEDIA_URL;
    const section = process.env.REACT_APP_SECTION;
  
  
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [seguimientoName, setSeguimientoName] = useState('');
    const [selectedTags, setSelectedTags] = useState([]);
    const [seguimientoPlayers, setSeguimientoPlayers] = useState([]);
    const [availablePlayers, setAvailablePlayers] = useState([]);
    const [allPlayers, setAllPlayers] = useState([]);
    const [checkedPlayers, setCheckedPlayers] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage] = useState(10);
  
    const [nameFilter, setNameFilter] = useState('');
    const [teamFilter, setTeamFilter] = useState('');
    const [positionFilter, setPositionFilter] = useState([]);
    const [heightFilter, setHeightFilter] = useState([130, 230]);
    const [ageFilter, setAgeFilter] = useState([12, 45]);
    const [leagueFilter, setLeagueFilter] = useState([]);
    const [uniqueLeagues, setUniqueLeagues] = useState([]);
  
    const calculateAge = (anneeNaissance) => {
      const currentYear = new Date().getFullYear();
      return currentYear - anneeNaissance;
    };
  
    useEffect(() => {
      const seguimientoPlayerIds = seguimientoPlayers.map(p => p.id);
      const available = allPlayers.filter(p => !seguimientoPlayerIds.includes(p.id));
      
      const leagues = Array.from(new Set(
        available
          .map(player => player.league_1)
          .filter(league => league && league.trim() !== '' && league.trim() !== 'null')
      )).sort();
      setUniqueLeagues(leagues);
      setAvailablePlayers(available);
    }, [seguimientoPlayers, allPlayers]);
  
    useEffect(() => {
      const fetchSeguimiento = async () => {
        try {
          setLoading(true);
          const response = await axios.get(`${backendUrl}/api/seguimiento/${id}`, {
            params: { section }
          });
          const seguimiento = response.data;
          setSeguimientoName(seguimiento.libelle);
          // Trier les joueurs par ordre alphabétique
          const sortedPlayers = seguimiento.players ? [...seguimiento.players].sort((a, b) => a.nom.localeCompare(b.nom)) : [];
          setSeguimientoPlayers(sortedPlayers);
          setSelectedTags(seguimiento.tags || []);
    
          const playersResponse = await axios.get(`${backendUrl}/api/players`, {
            params: { section }
          });
          const allPlayersFetched = playersResponse.data.players;
          setAllPlayers(allPlayersFetched);
    
          const seguimientoPlayerIds = sortedPlayers.map(p => p.id);
          const available = allPlayersFetched.filter(p => !seguimientoPlayerIds.includes(p.id));
          setAvailablePlayers(available);
    
          setError(null);
        } catch (error) {
          console.error("Error al recuperar el Seguimiento:", error);
          setError("No se puede cargar el Seguimiento.");
        } finally {
          setLoading(false);
        }
      };
    
      if (id) {
        fetchSeguimiento();
      }
    }, [id, backendUrl, section]);
  
    const filteredAvailablePlayers = availablePlayers.filter((player) => {
      const playerAge = calculateAge(player.annee_naissance);
      return (
        player.nom.toLowerCase().includes(nameFilter.toLowerCase()) &&
        (!teamFilter || (player.team && player.team.toLowerCase().includes(teamFilter.toLowerCase()))) &&
        (positionFilter.length === 0 || positionFilter.some(pos => 
          positionMappings[pos].some(mappedPos => 
            player.poste.toLowerCase() === mappedPos.toLowerCase()
          )
        )) &&
        (leagueFilter.length === 0 || leagueFilter.includes(player.league_1)) &&
        player.taille >= heightFilter[0] && player.taille <= heightFilter[1] &&
        playerAge >= ageFilter[0] && playerAge <= ageFilter[1]
      );
    });
  
    const handleSave = async () => {
      if (!seguimientoName.trim()) {
        setError("El nombre del seguimiento no puede estar vacío");
        return;
      }
    
      try {
        await axios.put(`${backendUrl}/api/seguimiento/${id}`, {
          section,
          libelle: seguimientoName,
          players: seguimientoPlayers.map(player => player.id),
          tags: selectedTags.map(tag => tag.id)
        });
        navigate(-1);
        setTimeout(() => {
          
        }, 100);
      } catch (error) {
        console.error("Error al actualizar el Seguimiento:", error);
        setError(error.response?.data?.message || "Error al actualizar el Seguimiento.");
      }
    };
  
    const handlePlayerClick = (player) => {
      navigate(`/players/${player.id}`);
    };

    const handleTogglePlayer = (playerId) => {
        setCheckedPlayers(prev => {
          const currentIndex = prev.indexOf(playerId);
          if (currentIndex === -1) {
            return [...prev, playerId];
          }
          return prev.filter(id => id !== playerId);
        });
      };
    
      const movePlayersToSeguimiento = () => {
        const selected = availablePlayers.filter((p) => checkedPlayers.includes(p.id));
        const newPlayers = [...seguimientoPlayers, ...selected].sort((a, b) => 
          a.nom.localeCompare(b.nom)
        );
        setSeguimientoPlayers(newPlayers);
        setCheckedPlayers([]);
      };
    
      const movePlayerToSeguimiento = (player) => {
        if (!seguimientoPlayers.some(p => p.id === player.id)) {
          const newPlayers = [...seguimientoPlayers, player].sort((a, b) => 
            a.nom.localeCompare(b.nom)
          );
          setSeguimientoPlayers(newPlayers);
        }
      };
    
      const removePlayerFromSeguimiento = (player) => {
        const newPlayers = seguimientoPlayers.filter(p => p.id !== player.id);
        setSeguimientoPlayers(newPlayers);
      };
    
      const SeguimientoPlayer = ({ player }) => {
        return (
          <Box 
            sx={{
              display: 'flex',
              alignItems: 'center',
              p: 0.5,
              mb: 0.5,
              backgroundColor: 'background.paper',
              borderRadius: 1,
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
              }
            }}
          >
            <Avatar 
              src={`${mediaUrl}/photos/${player.id}.png`} 
              alt={player.nom}
              sx={{ mr: 2 }}
            />
            <Typography sx={{ flexGrow: 1 }}>{player.nom}</Typography>
            <IconButton onClick={() => removePlayerFromSeguimiento(player)} color="error">
              <DeleteIcon />
            </IconButton>
          </Box>
        );
      };
    
      const SeguimientoDropZone = () => {
        const [{ isOver }, dropRef] = useDrop({
          accept: 'player',
          drop: (player) => movePlayerToSeguimiento(player),
          collect: (monitor) => ({ isOver: monitor.isOver() }),
        });
    
        return (
          <Paper 
            ref={dropRef}
            sx={{ 
              p: 2,
              minHeight: '500px',
              backgroundColor: isOver ? 'rgba(255, 152, 0, 0.1)' : '#f5f5f5'
            }}
          >
            <Typography variant="h6" gutterBottom>
              Seguimiento
            </Typography>
            {seguimientoPlayers.map((player) => (
              <SeguimientoPlayer key={player.id} player={player} />
            ))}
            {seguimientoPlayers.length === 0 && (
              <Typography 
                sx={{ 
                  textAlign: 'center',
                  color: 'text.secondary',
                  mt: 2 
                }}
              >
                Arrastra jugador@s aquí
              </Typography>
            )}
          </Paper>
        );
      };
    
      if (loading) {
        return (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        );
      }
    
      if (error) {
        return (
          <Alert severity="error" sx={{ m: 2 }}>{error}</Alert>
        );
      }
    
      return (
        <DndProvider backend={HTML5Backend}>
          <Box sx={{ p: 3 }}>
            {/* Section des filtres */}
            <Box sx={{ mb: 4 }}>
              <Typography variant="h6" gutterBottom>Filtros</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Filtrar por nombre"
                    variant="outlined"
                    value={nameFilter}
                    onChange={(e) => setNameFilter(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Filtrar por equipo"
                    variant="outlined"
                    value={teamFilter}
                    onChange={(e) => setTeamFilter(e.target.value)}
                  />
                </Grid>
    
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="position-filter-label">Filtrar por posición</InputLabel>
                    <Select
                      labelId="position-filter-label"
                      multiple
                      value={positionFilter}
                      onChange={(e) => setPositionFilter(e.target.value)}
                      renderValue={(selected) => selected.join(', ')}
                    >
                      {Object.entries(positionMappings).map(([position, equivalents]) => (
                        <MenuItem key={position} value={position}>
                          <Checkbox checked={positionFilter.indexOf(position) > -1} />
                          <ListItemText 
                            primary={position}
                            secondary={equivalents.slice(1).join(', ')}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="league-filter-label">Filtrar por liga</InputLabel>
                    <Select
                      labelId="league-filter-label"
                      multiple
                      value={leagueFilter}
                      onChange={(e) => setLeagueFilter(e.target.value)}
                      renderValue={(selected) => selected.join(', ')}
                    >
                      {uniqueLeagues.map((league) => (
                        <MenuItem key={league} value={league}>
                          <Checkbox checked={leagueFilter.indexOf(league) > -1} />
                          <ListItemText primary={league} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
    
              <Grid container spacing={4} sx={{ mt: 3 }}>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ px: 3 }}>
                    <Typography gutterBottom sx={{ textAlign: 'center', mb: 2 }}>
                      Filtrar por altura (130 a 230 cm)
                    </Typography>
                    <Slider
                      value={heightFilter}
                      onChange={(e, newValue) => setHeightFilter(newValue)}
                      valueLabelDisplay="auto"
                      min={130}
                      max={230}
                      sx={{ color: '#ff9800' }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ px: 3 }}>
                    <Typography gutterBottom sx={{ textAlign: 'center', mb: 2 }}>
                      Filtrar por edad (12 a 45 años)
                    </Typography>
                    <Slider
                      value={ageFilter}
                      onChange={(e, newValue) => setAgeFilter(newValue)}
                      valueLabelDisplay="auto"
                      min={12}
                      max={45}
                      sx={{ color: '#ff9800' }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
    
            {/* Configuration du Seguimiento */}
            <Box sx={{ mb: 6, mt: 6 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    label="Nombre del Seguimiento"
                    value={seguimientoName}
                    onChange={(e) => setSeguimientoName(e.target.value)}
                    error={!seguimientoName.trim()}
                    helperText={!seguimientoName.trim() ? "El nombre es obligatorio" : ""}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel>Tags</InputLabel>
                    <Select
                      multiple
                      value={selectedTags}
                      onChange={(e) => setSelectedTags(e.target.value)}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((tag) => (
                            <Chip
                              key={tag.id}
                              label={tag.libelle}
                              style={{
                                backgroundColor: tag.code_couleur,
                                color: getContrastColor(tag.code_couleur)
                              }}
                            />
                          ))}
                        </Box>
                      )}
                    >
                      {tags.map((tag) => (
                        <MenuItem key={tag.id} value={tag}>
                          <Checkbox checked={selectedTags.some(t => t.id === tag.id)} />
                          <ListItemText primary={tag.libelle} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
    
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
                <Button variant="outlined" onClick={() => navigate(-1)}>
                  CANCELAR
                </Button>
                <Button 
                  variant="contained" 
                  onClick={handleSave}
                  sx={{ 
                    backgroundColor: '#1976d2',
                    '&:hover': {
                      backgroundColor: '#1565c0'
                    }
                  }}
                >
                  GUARDAR CAMBIOS
                </Button>
              </Box>
            </Box>
    
            {/* Liste des joueurs et Seguimiento */}
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Button
                  variant="contained"
                  onClick={movePlayersToSeguimiento}
                  disabled={checkedPlayers.length === 0}
                  sx={{ 
                    mb: 2,
                    backgroundColor: '#ff9800',
                    '&:hover': {
                      backgroundColor: '#f57c00'
                    }
                  }}
                >
                  Añadir los jugador@s seleccionados ({checkedPlayers.length})
                </Button>
    
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            indeterminate={checkedPlayers.length > 0 && checkedPlayers.length < filteredAvailablePlayers.length}
                            checked={filteredAvailablePlayers.length > 0 && checkedPlayers.length === filteredAvailablePlayers.length}
                            onChange={(e) => setCheckedPlayers(e.target.checked ? filteredAvailablePlayers.map(p => p.id) : [])}
                          />
                        </TableCell>
                        <TableCell>Avatar</TableCell>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Posición</TableCell>
                        <TableCell>Equipo</TableCell>
                        <TableCell>Liga</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredAvailablePlayers
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((player) => (
                          <DraggablePlayerRow 
                            key={player.id}
                            player={player}
                            handlePlayerClick={handlePlayerClick}
                            checkedPlayers={checkedPlayers}
                            handleTogglePlayer={handleTogglePlayer}
                            mediaUrl={mediaUrl}
                          />
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  count={filteredAvailablePlayers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(event, newPage) => setPage(newPage)}
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                  rowsPerPageOptions={[10]}
                />
              </Grid>
    
              <Grid item xs={12} md={4}>
                <SeguimientoDropZone />
              </Grid>
            </Grid>
          </Box>
        </DndProvider>
      );
    };
    
    export default SeguimientoModif;