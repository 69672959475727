import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  IconButton,
  Collapse
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import axios from 'axios';

const getAvatarUrl = (userId) => {
  return `${process.env.REACT_APP_MEDIA_URL}/users/${userId}.png?${new Date().getTime()}`;
};

const IntelTab = ({ playerId, onDataLoaded }) => {
  const [intelData, setIntelData] = useState([]);
  const [openRows, setOpenRows] = useState({});

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;

  useEffect(() => {
    const fetchIntelData = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/intel`, { 
          params: { 
            playerId,
            section
          } 
        });
        
        setIntelData(response.data);

        if (onDataLoaded) {
          onDataLoaded(response.data.length > 0);
        }

        if (response.data.length > 0) {
          setOpenRows({ [response.data[0].id]: true });
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données d'intel:", error);
      }
    };

    fetchIntelData();
  }, [playerId, backendUrl, section, onDataLoaded]);

  const toggleRow = (rowId) => {
    setOpenRows((prev) => ({ ...prev, [rowId]: !prev[rowId] }));
  };

  if (!intelData.length) {
    return (
      <Box sx={{ p: 2, textAlign: 'center' }}>
        <Typography variant="h6">Aucune donnée disponible</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      {intelData.map((row) => (
        <Card 
          key={row.id} 
          sx={{ 
            mb: 2,
            '&:last-child': { mb: 0 }
          }}
        >
          <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
            <Box
              onClick={() => toggleRow(row.id)}
              sx={{
                p: 2,
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, minWidth: 0 }}>
                <Avatar
                  src={getAvatarUrl(row.usuario_id)}
                  alt={row.usuario_nombre}
                  sx={{ mr: 2 }}
                />
                <Box sx={{ minWidth: 0 }}>
                  <Box sx={{ display: 'flex', alignItems: 'baseline', mb: 0.5 }}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        mr: 1
                      }}
                    >
                      {row.usuario_nombre}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{ flexShrink: 0 }}
                    >
                      {new Date(row.fecha).toLocaleDateString()}
                    </Typography>
                  </Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    Fuente: {row.fuente}
                  </Typography>
                </Box>
              </Box>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  toggleRow(row.id);
                }}
                size="small"
                sx={{ ml: 1 }}
              >
                {openRows[row.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Box>
            <Collapse in={openRows[row.id]}>
              <Box
                sx={{
                  p: 2,
                  bgcolor: '#FFECB3',
                  borderTop: 1,
                  borderColor: 'divider'
                }}
              >
                <Typography
                  variant="body2"
                  component="div"
                  dangerouslySetInnerHTML={{ __html: row.detalles }}
                />
              </Box>
            </Collapse>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default IntelTab;