import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import LoginPage from './components/LoginPage';
import Layout from './components/Layout';
import Dashboard from './screens/Dashboard';
import Grupos from './screens/Grupos';
import Usuarios from './screens/Usuarios';
import PlayerList from './screens/PlayerList';
import CrearJugador from './screens/CrearJugador';
import PlayerProfile from './screens/PlayerProfile';
import BigBoard from './screens/BigBoard';
import Tags from './screens/Tag';
import PlayerCardCreator from './screens/PlayerCardCreator';
import RechercheInforme from './screens/RechercheInforme';
import RechercheIntel from './screens/RechercheIntel';
import BigBoardTable from './screens/BigBoardTable';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import BigBoardModif from './screens/BigBoardModif';
import Importar from './screens/Importar';
import Tecnification from './screens/Tecnification';
import Seguimiento from './screens/Seguimiento';
import SeguimientoTable from './screens/SeguimientoTable';
import SeguimientoModif from './screens/SeguimientoModif';

// Import des providers
import { BigBoardProvider } from './BigBoardContext';
import { BigBoardTableProvider } from './BigBoardTableContext';
import { SeguimientoProvider } from './SeguimientoContext';

// Composant pour regrouper tous les providers
const AppProviders = ({ children }) => {
  return (
    <BigBoardProvider>
      <BigBoardTableProvider>
        <SeguimientoProvider>
          {children}
        </SeguimientoProvider>
      </BigBoardTableProvider>
    </BigBoardProvider>
  );
};



// Composant pour les routes authentifiées
const AuthenticatedRoutes = ({ onLogout }) => {

  const [user, setUser] = useState(null);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('user'));
    setUser(userData);
  }, []);

  return (
    <Routes>
       <Route path="/" element={<Layout onLogout={onLogout} />}>
        {/* Route par défaut qui redirige vers le dashboard */}
        <Route 
          index 
          element={
            user?.role === 'entrenador' ? 
              <Navigate to="/jugadores" replace /> : 
              <Dashboard />
          } 
        />
        <Route index element={<Dashboard />} />
        <Route path="jugadores" element={<PlayerList />} />
        <Route path="panel-de-control" element={<Dashboard />} />
        <Route path="grupos" element={<Grupos />} />
        <Route path="usuarios" element={<Usuarios />} />
        <Route path="big-board" element={<BigBoard />} />
        <Route path="crear-jugador" element={<CrearJugador />} />
        <Route path="tags" element={<Tags />} />
        <Route path="crear-ficha" element={<PlayerCardCreator />} />
        <Route path="buscar-informe" element={<RechercheInforme />} />
        <Route path="buscar-intel" element={<RechercheIntel />} />
        <Route path="buscar-bigboard" element={<BigBoardTable />} />
        <Route path="bigboard/edit/:id" element={<BigBoardModif />} />
        <Route path="importar" element={<Importar />} />
        <Route path="tecnification" element={<Tecnification />} />
        <Route path="/players/:id" element={<PlayerProfile />} />

        <Route path="seguimiento" element={<Seguimiento />} />
        <Route path="buscar-seguimiento" element={<SeguimientoTable />} />
        <Route path="seguimiento/edit/:id" element={<SeguimientoModif />} />


      </Route>
    </Routes>
  );
};

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
    setIsLoading(false);
  }, []);

  const handleLogin = (token) => {
    localStorage.setItem('authToken', token);
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    setIsAuthenticated(false);
  };

  if (isLoading) {
    return (
      <Box sx={{
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <AppProviders>
      <Router>
        <Routes>
          <Route
            path="/login"
            element={
              isAuthenticated ? (
                <Navigate to="/" replace />
              ) : (
                <LoginPage onLogin={handleLogin} />
              )
            }
          />

          {/* Route authentifiée avec tous les providers nécessaires */}
          <Route
            path="/*"
            element={
              isAuthenticated ? (
                <AuthenticatedRoutes onLogout={handleLogout} />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
        </Routes>
      </Router>
    </AppProviders>
  );
}

export default App;