import React, { useState, useEffect, useContext } from 'react';
import {
  Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Avatar, Paper, Grid, Checkbox,
  IconButton, Button, TablePagination, TextField, FormControl, InputLabel, Select, MenuItem, ListItemText, Slider, Chip
} from '@mui/material';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Visibility, Delete as DeleteIcon } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';
import { SeguimientoContext } from '../SeguimientoContext';

const Seguimiento = () => {
  const [players, setPlayers] = useState([]);
  const { seguimientoPlayers, setSeguimientoPlayers } = useContext(SeguimientoContext);
  const [checkedPlayers, setCheckedPlayers] = useState([]);
  const [page, setPage] = useState(0);
  const rowsPerPage = 50;

  // États pour les filtres
  const [nameFilter, setNameFilter] = useState('');
  const [teamFilter, setTeamFilter] = useState('');
  const [positionFilter, setPositionFilter] = useState([]);
  const [heightFilter, setHeightFilter] = useState([130, 230]);
  const [ageFilter, setAgeFilter] = useState([12, 45]);
  const [uniqueLeagues, setUniqueLeagues] = useState([]);
  const [leagueFilter, setLeagueFilter] = useState([]);

  const positionMappings = {
    'base': ['base', 'Point Guard'],
    'escolta': ['escolta', 'Shooting Guard', 'Guard'],
    'alerio': ['alerio', 'Small Forward', 'Swingman'],
    'ala-pivot': ['ala-pivot', 'Power Forward', 'Power Forward / Center'],
    'pivot': ['pivot', 'Center']
  };

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [seguimientoName, setSeguimientoName] = useState('');
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Effet pour charger les filtres depuis l'URL au chargement initial
  useEffect(() => {
    if (isInitialLoad) {
      const params = new URLSearchParams(location.search);
      setNameFilter(params.get('nameFilter') || '');
      setTeamFilter(params.get('teamFilter') || '');
      setPositionFilter(params.get('positionFilter') ? params.get('positionFilter').split(',') : []);
      setLeagueFilter(params.get('leagueFilter') ? params.get('leagueFilter').split(',') : []);
      setHeightFilter(params.get('heightFilter') ? params.get('heightFilter').split(',').map(Number) : [130, 230]);
      setAgeFilter(params.get('ageFilter') ? params.get('ageFilter').split(',').map(Number) : [12, 45]);
      setPage(params.get('page') ? parseInt(params.get('page'), 10) : 0);
      setIsInitialLoad(false);
    }
  }, [location.search, isInitialLoad]);

  // Effet pour mettre à jour l'URL lorsque les filtres changent
  useEffect(() => {
    if (!isInitialLoad) {
      const params = new URLSearchParams();
      if (nameFilter) params.set('nameFilter', nameFilter);
      if (teamFilter) params.set('teamFilter', teamFilter);
      if (positionFilter.length) params.set('positionFilter', positionFilter.join(','));
      if (leagueFilter.length) params.set('leagueFilter', leagueFilter.join(','));
      if (heightFilter.length) params.set('heightFilter', heightFilter.join(','));
      if (ageFilter.length) params.set('ageFilter', ageFilter.join(','));
      if (page) params.set('page', page);

      const newUrl = `${location.pathname}?${params.toString()}`;
      if (location.search !== `?${params.toString()}`) {
        navigate(newUrl, { replace: true });
      }
    }
  }, [nameFilter, teamFilter, positionFilter, leagueFilter, heightFilter, ageFilter, page, isInitialLoad, location.pathname, navigate]);

  // Récupérer les joueurs depuis le backend
  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/players?section=${section}`);
        const allPlayers = response.data.players;

        const leagues = Array.from(new Set(
          allPlayers
            .map(player => player.league_1)
            .filter(league => 
              league !== null && 
              league !== undefined && 
              typeof league === 'string' && 
              league.trim() !== '' && 
              league.trim() !== 'null'
            )
        )).sort();  
        setUniqueLeagues(leagues);

        const seguimientoPlayerIds = seguimientoPlayers.map(player => player.id);
        const availablePlayers = allPlayers.filter(player => !seguimientoPlayerIds.includes(player.id));

        setPlayers(availablePlayers);
      } catch (error) {
        console.error('Erreur lors de la récupération des joueurs:', error);
      }
    };
    fetchPlayers();
  }, [backendUrl, section, seguimientoPlayers]);

  // Récupérer les tags
  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/tags`, { params: { section } });
        setTags(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des tags:', error);
      }
    };
    fetchTags();
  }, [backendUrl, section]);

  const calculateAge = (anneeNaissance) => {
    const currentYear = new Date().getFullYear();
    return currentYear - anneeNaissance;
  };

  // Filtrer les joueurs
  const filteredPlayers = players.filter((player) => {
    const playerAge = calculateAge(player.annee_naissance);
    const searchTermName = nameFilter.toLowerCase();
    const searchTermTeam = teamFilter.toLowerCase();

    const positionMatches = positionFilter.length === 0 || 
      positionFilter.some(pos => 
        positionMappings[pos].some(mappedPos => 
          player.poste.toLowerCase() === mappedPos.toLowerCase()
        )
      );

    return (
      player.nom.toLowerCase().includes(searchTermName) &&
      (!searchTermTeam || (player.team && player.team.toLowerCase().includes(searchTermTeam))) &&
      positionMatches &&
      (leagueFilter.length === 0 || leagueFilter.includes(player.league_1)) &&
      player.taille >= heightFilter[0] && player.taille <= heightFilter[1] &&
      playerAge >= ageFilter[0] && playerAge <= ageFilter[1]
    );
  });

  const getCurrentPagePlayers = () => {
    return filteredPlayers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  const handleTogglePlayer = (playerId) => {
    const currentIndex = checkedPlayers.indexOf(playerId);
    const newChecked = [...checkedPlayers];

    if (currentIndex === -1) {
      newChecked.push(playerId);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedPlayers(newChecked);
  };

  const movePlayersToSeguimiento = () => {
    const selected = filteredPlayers.filter((p) => checkedPlayers.includes(p.id));
    const newSeguimientoPlayers = [...seguimientoPlayers, ...selected].sort((a, b) => 
      a.nom.localeCompare(b.nom)
    );
    setSeguimientoPlayers(newSeguimientoPlayers);
    setPlayers(players.filter((p) => !checkedPlayers.includes(p.id)));
    setCheckedPlayers([]);
  };

  const movePlayerToSeguimiento = (player) => {
    if (!seguimientoPlayers.some((p) => p.id === player.id)) {
      const newSeguimientoPlayers = [...seguimientoPlayers, player].sort((a, b) => 
        a.nom.localeCompare(b.nom)
      );
      setSeguimientoPlayers(newSeguimientoPlayers);
      setPlayers(players.filter((p) => p.id !== player.id));
    }
  };

  const removePlayerFromSeguimiento = (player) => {
    setSeguimientoPlayers(seguimientoPlayers.filter((p) => p.id !== player.id));
    setPlayers([...players, player].sort((a, b) => a.nom.localeCompare(b.nom)));
  };

  const handleViewPlayer = (player) => {
    navigate(`/players/${player.id}`);
  };

  const handleSaveSeguimiento = async () => {
    if (!seguimientoName) {
      alert('Por favor, introduce un nombre para el Seguimiento.');
      return;
    }
    if (seguimientoPlayers.length === 0) {
      alert('Por favor, añade jugador@s al Seguimiento.');
      return;
    }
    
    try {
      const userData = JSON.parse(localStorage.getItem('user'));
      const userId = userData?.id;
      if (!userId) {
        alert('Error: ID de usuario no disponible.');
        return;
      }
      
      await axios.post(`${backendUrl}/api/seguimiento`, {
        nom: seguimientoName,
        tags: selectedTags,
        players: seguimientoPlayers.map((player) => player.id),
        user_id: userId,
        section,
      });
      
      alert('Seguimiento guardado con éxito.');
      
      setSeguimientoName('');
      setSelectedTags([]);
      setSeguimientoPlayers([]);
    } catch (error) {
      console.error('Error al guardar el Seguimiento.', error);
      alert('Error al guardar el Seguimiento.');
    }
  };

  const PlayerRow = ({ player }) => {
    const [{ isDragging }, dragRef] = useDrag({
      type: 'player',
      item: player,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    return (
      <TableRow 
        ref={dragRef} 
        key={player.id}
        style={{ opacity: isDragging ? 0.5 : 1 }}
        sx={{
          cursor: 'move',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        }}
      >
        <TableCell padding="checkbox">
          <Checkbox
            checked={checkedPlayers.indexOf(player.id) !== -1}
            onChange={() => handleTogglePlayer(player.id)}
            onClick={(e) => e.stopPropagation()}
          />
        </TableCell>
        <TableCell>
          <Avatar 
            src={`${mediaUrl}/photos/${player.id}.png`} 
            alt={player.nom}
            sx={{ width: 40, height: 40 }}
          />
        </TableCell>
        <TableCell>
          <Box 
            component="a"
            href={`/players/${player.id}`}
            onClick={(e) => {
              e.preventDefault();
              handleViewPlayer(player);
            }}
            sx={{
              color: 'black',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
                cursor: 'pointer'
              }
            }}
          >
            {player.nom}
          </Box>
        </TableCell>
        <TableCell>{player.annee_naissance}</TableCell>
        <TableCell>{player.taille}</TableCell>
        <TableCell>{player.poste}</TableCell>
        <TableCell>{player.team || '-'}</TableCell>
        <TableCell>{player.league_1 || '-'}</TableCell>
      </TableRow>
    );
  };

  const SeguimientoPlayer = ({ player }) => {
    return (
      <Box display="flex" alignItems="center" mb={2}>
        <Avatar src={`${mediaUrl}/photos/${player.id}.png`} alt={player.nom} />
        <Typography style={{ marginLeft: '10px' }}>{player.nom}</Typography>
        <IconButton onClick={() => removePlayerFromSeguimiento(player)} style={{ marginLeft: 'auto' }}>
          <DeleteIcon color="error" />
        </IconButton>
      </Box>
    );
  };

  const SeguimientoDropZone = () => {
    const [{ isOver }, dropRef] = useDrop({
      accept: 'player',
      drop: (player) => movePlayerToSeguimiento(player),
      collect: (monitor) => ({ isOver: monitor.isOver() }),
    });

    return (
      <Paper ref={dropRef} style={{ minHeight: '400px', backgroundColor: isOver ? '#e0f7fa' : '#f5f5f5', padding: '20px' }}>
        <Typography variant="h6">Seguimiento</Typography>
        {seguimientoPlayers.length === 0 && <p>Arrastra jugador@s aquí.</p>}
        {seguimientoPlayers.map((player) => (
          <SeguimientoPlayer key={player.id} player={player} />
        ))}
      </Paper>
    );
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box sx={{ p: 2 }}>
        <Box sx={{ mt: 4, px: 4 }}>
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Filtrar por nombre"
                variant="outlined"
                value={nameFilter}
                onChange={(e) => setNameFilter(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Filtrar por equipo"
                variant="outlined"
                value={teamFilter}
                onChange={(e) => setTeamFilter(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="position-filter-label">Filtrar por posición</InputLabel>
                <Select
                  labelId="position-filter-label"
                  multiple
                  value={positionFilter}
                  onChange={(e) => setPositionFilter(e.target.value)}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {Object.entries(positionMappings).map(([position, equivalents]) => (
                    <MenuItem key={position} value={position}>
                      <Checkbox checked={positionFilter.indexOf(position) > -1} />
                      <ListItemText 
                        primary={position}
                        secondary={equivalents.slice(1).join(', ')}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="league-filter-label">Filtrar por liga</InputLabel>
                <Select
                  labelId="league-filter-label"
                  multiple
                  value={leagueFilter}
                  onChange={(e) => setLeagueFilter(e.target.value)}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {uniqueLeagues.map((league) => (
                    <MenuItem key={league} value={league}>
                      <Checkbox checked={leagueFilter.indexOf(league) > -1} />
                      <ListItemText primary={league} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', gap: 5, mb: 3 }}>
            <Box sx={{ width: isSmallScreen ? '100%' : '50%' }}>
              <Typography gutterBottom sx={{ textAlign: 'center' }}>Filtrar por altura (130 a 230 cm)</Typography>
              <Slider
                value={heightFilter}
                onChange={(e, newValue) => setHeightFilter(newValue)}
                valueLabelDisplay="auto"
                min={130}
                max={230}
                sx={{ color: '#ff9800' }}
              />
            </Box>

            <Box sx={{ width: isSmallScreen ? '100%' : '50%' }}>
              <Typography gutterBottom sx={{ textAlign: 'center' }}>Filtrar por edad (12 a 45 años)</Typography>
              <Slider
                value={ageFilter}
                onChange={(e, newValue) => setAgeFilter(newValue)}
                valueLabelDisplay="auto"
                min={12}
                max={45}
                sx={{ color: '#ff9800' }}
              />
            </Box>
          </Box>

          <Grid container spacing={2} sx={{ mb: 4 }}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Nombre del Seguimiento"
                variant="outlined"
                value={seguimientoName}
                onChange={(e) => setSeguimientoName(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="tags-select-label">Seleccionar Tags</InputLabel>
                <Select
                  labelId="tags-select-label"
                  multiple
                  value={selectedTags}
                  onChange={(e) => setSelectedTags(e.target.value)}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((tagId) => {
                        const tag = tags.find((t) => t.id === tagId);
                        return (
                          <Chip
                            key={tagId}
                            label={tag.libelle}
                            sx={{ backgroundColor: tag.code_couleur, color: 'white' }}
                          />
                        );
                      })}
                    </Box>
                  )}
                >
                  {tags.map((tag) => (
                    <MenuItem key={tag.id} value={tag.id}>
                      <Checkbox checked={selectedTags.indexOf(tag.id) > -1} />
                      <Chip
                        label={tag.libelle}
                        sx={{ backgroundColor: tag.code_couleur, color: 'white', mr: 1 }}
                      />
                      <ListItemText primary={tag.libelle} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Button
            variant="contained"
            sx={{ backgroundColor: '#ff9800', color: 'white', mb: 4 }}
            onClick={handleSaveSeguimiento}
          >
            Guardar Seguimiento
          </Button>

          <Button
            variant="contained"
            sx={{ mt: 2, mb: 4, ml: 2, backgroundColor: '#ff9800', color: 'white' }}
            onClick={movePlayersToSeguimiento}
            disabled={checkedPlayers.length === 0}
          >
            Mover jugador@s seleccionados ({checkedPlayers.length})
          </Button>

          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          indeterminate={
                            checkedPlayers.length > 0 && 
                            checkedPlayers.length < getCurrentPagePlayers().length
                          }
                          checked={
                            getCurrentPagePlayers().length > 0 && 
                            getCurrentPagePlayers().every(p => checkedPlayers.includes(p.id))
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              const pagePlayerIds = getCurrentPagePlayers().map(p => p.id);
                              setCheckedPlayers([...new Set([...checkedPlayers, ...pagePlayerIds])]);
                            } else {
                              const pagePlayerIds = getCurrentPagePlayers().map(p => p.id);
                              setCheckedPlayers(checkedPlayers.filter(id => !pagePlayerIds.includes(id)));
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell>Avatar</TableCell>
                      <TableCell>Nombre</TableCell>
                      <TableCell>Año de nacimiento</TableCell>
                      <TableCell>Altura (cm)</TableCell>
                      <TableCell>Posición</TableCell>
                      <TableCell>Equipo</TableCell>
                      <TableCell>Liga</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredPlayers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((player) => (
                        <PlayerRow key={player.id} player={player} />
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={filteredPlayers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                labelRowsPerPage="Filas por página"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <SeguimientoDropZone />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </DndProvider>
  );
};

export default Seguimiento;