import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Paper,
  TextField,
  Button,
  Typography,
  Grid,
  Chip,
  Alert,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Checkbox,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  TablePagination,
  Slider
} from '@mui/material';
import {
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import axios from 'axios';
import { useBigBoardTable } from '../BigBoardTableContext';

const getContrastColor = (hexcolor) => {
  if (!hexcolor) return '#000000';
  const r = parseInt(hexcolor.slice(1, 3), 16);
  const g = parseInt(hexcolor.slice(3, 5), 16);
  const b = parseInt(hexcolor.slice(5, 7), 16);

  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  return luminance > 0.5 ? '#000000' : '#FFFFFF';
};

const positionMappings = {
  'base': ['base', 'Point Guard'],
  'escolta': ['escolta', 'Shooting Guard', 'Guard'],
  'alerio': ['alerio', 'Small Forward', 'Swingman'],
  'ala-pivot': ['ala-pivot', 'Power Forward', 'Power Forward / Center'],
  'pivot': ['pivot', 'Center']
};

const DraggablePlayerRow = ({ player, handlePlayerClick, checkedPlayers, handleTogglePlayer, mediaUrl }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'player',
    item: player,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  return (
    <TableRow
      ref={drag}
      key={player.id}
      hover
      sx={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={checkedPlayers.includes(player.id)}
          onChange={() => handleTogglePlayer(player.id)}
        />
      </TableCell>
      <TableCell>
        <Avatar
          src={`${mediaUrl}/photos/${player.id}.png`}
          alt={player.nom}
        />
      </TableCell>
      <TableCell>
        <Box 
          component="a"
          onClick={(e) => {
            e.preventDefault();
            handlePlayerClick(player);
          }}
          sx={{
            color: 'black',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
              cursor: 'pointer'
            }
          }}
        >
          {player.nom}
        </Box>
      </TableCell>
      <TableCell>{player.poste}</TableCell>
      <TableCell>{player.team || '-'}</TableCell>
      <TableCell>{player.league_1 || '-'}</TableCell>
    </TableRow>
  );
};

const BigBoardModif = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const section = process.env.REACT_APP_SECTION;

  const { tags } = useBigBoardTable();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [bigBoardName, setBigBoardName] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [bigBoardPlayers, setBigBoardPlayers] = useState([]);
  const [availablePlayers, setAvailablePlayers] = useState([]);
  const [allPlayers, setAllPlayers] = useState([]);
  const [checkedPlayers, setCheckedPlayers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const { refreshData } = useBigBoardTable();

  const [nameFilter, setNameFilter] = useState('');
  const [teamFilter, setTeamFilter] = useState('');
  const [positionFilter, setPositionFilter] = useState([]);
  const [heightFilter, setHeightFilter] = useState([130, 230]);
  const [ageFilter, setAgeFilter] = useState([12, 45]);
  const [leagueFilter, setLeagueFilter] = useState([]);
  const [uniqueLeagues, setUniqueLeagues] = useState([]);

  const calculateAge = (anneeNaissance) => {
    const currentYear = new Date().getFullYear();
    return currentYear - anneeNaissance;
  };

  useEffect(() => {
    const boardPlayerIds = bigBoardPlayers.map(p => p.id);
    const available = allPlayers.filter(p => !boardPlayerIds.includes(p.id));
    
    const leagues = Array.from(new Set(
      available
        .map(player => player.league_1)
        .filter(league => league && league.trim() !== '' && league.trim() !== 'null')
    )).sort();
    setUniqueLeagues(leagues);
    
    setAvailablePlayers(available);
  }, [bigBoardPlayers, allPlayers]);

  useEffect(() => {
    const fetchBigBoard = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${backendUrl}/api/bigboard/${id}`, {
          params: { section }
        });
        const board = response.data;
        setBigBoardName(board.libelle);
        // Trier les joueurs par classement dans l'ordre inverse (1 en bas)
        const sortedPlayers = board.players ? [...board.players].sort((a, b) => b.classement - a.classement) : [];
        setBigBoardPlayers(sortedPlayers);
        setSelectedTags(board.tags || []);
  
        const playersResponse = await axios.get(`${backendUrl}/api/players`, {
          params: { section }
        });
        const allPlayersFetched = playersResponse.data.players;
        setAllPlayers(allPlayersFetched);
  
        const boardPlayerIds = sortedPlayers.map(p => p.id);
        const available = allPlayersFetched.filter(p => !boardPlayerIds.includes(p.id));
        setAvailablePlayers(available);
  
        setError(null);
      } catch (error) {
        console.error("Error al recuperar el Big Board:", error);
        setError("No se puede cargar el Big Board.");
      } finally {
        setLoading(false);
      }
    };
  
    if (id) {
      fetchBigBoard();
    }
  }, [id, backendUrl, section]);

  const filteredAvailablePlayers = availablePlayers.filter((player) => {
    const playerAge = calculateAge(player.annee_naissance);
    return (
      player.nom.toLowerCase().includes(nameFilter.toLowerCase()) &&
      (!teamFilter || (player.team && player.team.toLowerCase().includes(teamFilter.toLowerCase()))) &&
      (positionFilter.length === 0 || positionFilter.some(pos => 
        positionMappings[pos].some(mappedPos => 
          player.poste.toLowerCase() === mappedPos.toLowerCase()
        )
      )) &&
      (leagueFilter.length === 0 || leagueFilter.includes(player.league_1)) &&
      player.taille >= heightFilter[0] && player.taille <= heightFilter[1] &&
      playerAge >= ageFilter[0] && playerAge <= ageFilter[1]
    );
  });

  const handleSave = async () => {
    if (!bigBoardName.trim()) {
      setError("El nombre del bigboard no puede estar vacío");
      return;
    }
  
    try {
      // Inverser l'ordre des joueurs pour l'enregistrement
      const playersToSave = [...bigBoardPlayers].reverse();
  
      await axios.put(`${backendUrl}/api/bigboard/${id}`, {
        section,
        libelle: bigBoardName,
        players: playersToSave.map(player => player.id),
        tags: selectedTags.map(tag => tag.id)
      });
      navigate(-1);
      setTimeout(() => {
        refreshData();
      }, 100);
    } catch (error) {
      console.error("Error al actualizar el Big Board:", error);
      setError(error.response?.data?.message || "Error al actualizar el Big Board.");
    }
  };

  const handlePlayerClick = (player) => {
    navigate(`/players/${player.id}`);
  };

  const handleTogglePlayer = (playerId) => {
    setCheckedPlayers(prev => {
      const currentIndex = prev.indexOf(playerId);
      if (currentIndex === -1) {
        return [...prev, playerId];
      }
      return prev.filter(id => id !== playerId);
    });
  };

  const movePlayersToBigBoard = () => {
    const selected = availablePlayers.filter((p) => checkedPlayers.includes(p.id));
    const newPlayers = [...bigBoardPlayers];
    
    // Ajouter les nouveaux joueurs avec des classements consécutifs
    selected.forEach((player) => {
      newPlayers.unshift({
        ...player,
        classement: newPlayers.length + 1
      });
    });
  
    // Mettre à jour les classements pour tous les joueurs
    const updatedPlayers = newPlayers.map((player, index) => ({
      ...player,
      classement: newPlayers.length - index
    }));
  
    setBigBoardPlayers(updatedPlayers);
    setCheckedPlayers([]);
  };

  const updatePlayersRanking = (players) => {
    return players.map((player, index) => ({
      ...player,
      classement: players.length - index
    }));
  };

  const movePlayerToBigBoard = (player) => {
    if (!bigBoardPlayers.some(p => p.id === player.id)) {
      // Ajouter le joueur avec le plus grand classement
      const newPlayer = {
        ...player,
        classement: 1 // Le plus petit classement
      };
      
      // Déplacer tous les autres classements
      const updatedPlayers = bigBoardPlayers.map(p => ({
        ...p,
        classement: p.classement + 1
      }));
      
      // Ajouter le nouveau joueur à la fin du tableau
      setBigBoardPlayers([...updatedPlayers, newPlayer]);
    }
  };

  const removePlayerFromBigBoard = (player) => {
    // Retirer le joueur
    const filteredPlayers = bigBoardPlayers.filter(p => p.id !== player.id);
    
    // Mettre à jour les classements des joueurs restants
    const updatedPlayers = filteredPlayers.map((p, index) => ({
      ...p,
      classement: filteredPlayers.length - index
    }));
  
    setBigBoardPlayers(updatedPlayers);
  };

  const movePlayerInBigBoard = (dragIndex, hoverIndex) => {
    const newPlayers = [...bigBoardPlayers];
    const [movedPlayer] = newPlayers.splice(dragIndex, 1);
    newPlayers.splice(hoverIndex, 0, movedPlayer);
    const updatedPlayers = updatePlayersRanking(newPlayers);
    setBigBoardPlayers(updatedPlayers);
  };

  // Composant pour le Big Board avec glisser-déposer
  const DraggableBigBoardPlayer = ({ player, index }) => {
    const ref = React.useRef(null);
    const [, drag] = useDrag({
      type: 'bigboard-player',
      item: { index },
      end: (item, monitor) => {
        if (!monitor.didDrop()) {
          removePlayerFromBigBoard(player);
        }
      }
    });
    const [, drop] = useDrop({
      accept: 'bigboard-player',
      hover: (item) => {
        if (item.index !== index) {
          movePlayerInBigBoard(item.index, index);
          item.index = index;
        }
      }
    });
  
    drag(drop(ref));
  
    return (
      <Box 
        ref={ref} 
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 0.5,
          mb: 0.5,
          backgroundColor: 'background.paper',
          borderRadius: 1,
          cursor: 'move',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
          }
        }}
      >
        <Typography sx={{ mr: 2, minWidth: '30px' }}>{player.classement}</Typography>
        <Avatar 
          src={`${mediaUrl}/photos/${player.id}.png`} 
          alt={player.nom}
          sx={{ mr: 2 }}
        />
        <Typography sx={{ flexGrow: 1 }}>{player.nom}</Typography>
        <IconButton onClick={() => removePlayerFromBigBoard(player)} color="error">
          <DeleteIcon />
        </IconButton>
      </Box>
    );
  };

// Zone de dépôt pour le Big Board
const BigBoardDropZone = () => {
  const [{ isOver }, dropRef] = useDrop({
    accept: 'player',
    drop: (player) => movePlayerToBigBoard(player),
    collect: (monitor) => ({ isOver: monitor.isOver() }),
  });

  // Créer une copie inversée pour l'affichage uniquement
  const displayPlayers = [...bigBoardPlayers].reverse();

  return (
    <Paper 
      ref={dropRef}
      sx={{ 
        p: 2,
        minHeight: '500px',
        backgroundColor: isOver ? 'rgba(255, 152, 0, 0.1)' : '#f5f5f5'
      }}
    >
      <Typography variant="h6" gutterBottom>
        Big Board
      </Typography>
      {displayPlayers.map((player, index) => (
        <DraggableBigBoardPlayer
          key={player.id}
          player={player}
          // L'index réel est l'inverse de l'index d'affichage
          index={bigBoardPlayers.length - 1 - index}
        />
      ))}
      {bigBoardPlayers.length === 0 && (
        <Typography 
          sx={{ 
            textAlign: 'center',
            color: 'text.secondary',
            mt: 2 
          }}
        >
          Arrastra jugador@s aquí
        </Typography>
      )}
    </Paper>
  );
};


if (loading) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
      <CircularProgress />
    </Box>
  );
}

if (error) {
  return (
    <Alert severity="error" sx={{ m: 2 }}>{error}</Alert>
  );
}

return (
  <DndProvider backend={HTML5Backend}>
    <Box sx={{ p: 3 }}>
      {/* Section des filtres d'abord */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom>Filtros</Typography>
        <Grid container spacing={2}>
          {/* Filtres texte */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Filtrar por nombre"
              variant="outlined"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Filtrar por equipo"
              variant="outlined"
              value={teamFilter}
              onChange={(e) => setTeamFilter(e.target.value)}
            />
          </Grid>

          {/* Filtres select */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="position-filter-label">Filtrar por posición</InputLabel>
              <Select
                labelId="position-filter-label"
                multiple
                value={positionFilter}
                onChange={(e) => setPositionFilter(e.target.value)}
                renderValue={(selected) => selected.join(', ')}
              >
                {Object.entries(positionMappings).map(([position, equivalents]) => (
                  <MenuItem key={position} value={position}>
                    <Checkbox checked={positionFilter.indexOf(position) > -1} />
                    <ListItemText 
                      primary={position}
                      secondary={equivalents.slice(1).join(', ')}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="league-filter-label">Filtrar por liga</InputLabel>
              <Select
                labelId="league-filter-label"
                multiple
                value={leagueFilter}
                onChange={(e) => setLeagueFilter(e.target.value)}
                renderValue={(selected) => selected.join(', ')}
              >
                {uniqueLeagues.map((league) => (
                  <MenuItem key={league} value={league}>
                    <Checkbox checked={leagueFilter.indexOf(league) > -1} />
                    <ListItemText primary={league} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {/* Sliders avec plus d'espacement */}
        <Grid container spacing={4} sx={{ mt: 3 }}>
          <Grid item xs={12} sm={6}>
            <Box sx={{ px: 3 }}>
              <Typography gutterBottom sx={{ textAlign: 'center', mb: 2 }}>
                Filtrar por altura (130 a 230 cm)
              </Typography>
              <Slider
                value={heightFilter}
                onChange={(e, newValue) => setHeightFilter(newValue)}
                valueLabelDisplay="auto"
                min={130}
                max={230}
                sx={{ color: '#ff9800' }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ px: 3 }}>
              <Typography gutterBottom sx={{ textAlign: 'center', mb: 2 }}>
                Filtrar por edad (12 a 45 años)
              </Typography>
              <Slider
                value={ageFilter}
                onChange={(e, newValue) => setAgeFilter(newValue)}
                valueLabelDisplay="auto"
                min={12}
                max={45}
                sx={{ color: '#ff9800' }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Configuration du Big Board sous les filtres */}
      <Box sx={{ mb: 6, mt: 6 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label="Nombre del Big Board"
              value={bigBoardName}
              onChange={(e) => setBigBoardName(e.target.value)}
              error={!bigBoardName.trim()}
              helperText={!bigBoardName.trim() ? "El nombre es obligatorio" : ""}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Tags</InputLabel>
              <Select
                multiple
                value={selectedTags}
                onChange={(e) => setSelectedTags(e.target.value)}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((tag) => (
                      <Chip
                        key={tag.id}
                        label={tag.libelle}
                        style={{
                          backgroundColor: tag.code_couleur,
                          color: getContrastColor(tag.code_couleur)
                        }}
                      />
                    ))}
                  </Box>
                )}
              >
                {tags.map((tag) => (
                  <MenuItem key={tag.id} value={tag}>
                    <Checkbox checked={selectedTags.some(t => t.id === tag.id)} />
                    <ListItemText primary={tag.libelle} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
          <Button variant="outlined" onClick={() => navigate(-1)}>
            CANCELAR
          </Button>
          <Button 
            variant="contained" 
            onClick={handleSave}
            sx={{ 
              backgroundColor: '#1976d2',
              '&:hover': {
                backgroundColor: '#1565c0'
              }
            }}
          >
            GUARDAR CAMBIOS
          </Button>
        </Box>
      </Box>

      {/* Section avec la table et le Big Board */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Button
            variant="contained"
            onClick={movePlayersToBigBoard}
            disabled={checkedPlayers.length === 0}
            sx={{ 
              mb: 2,
              backgroundColor: '#ff9800',
              '&:hover': {
                backgroundColor: '#f57c00'
              }
            }}
          >
            Añadir los jugador@s seleccionados ({checkedPlayers.length})
          </Button>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={checkedPlayers.length > 0 && checkedPlayers.length < filteredAvailablePlayers.length}
                      checked={filteredAvailablePlayers.length > 0 && checkedPlayers.length === filteredAvailablePlayers.length}
                      onChange={(e) => setCheckedPlayers(e.target.checked ? filteredAvailablePlayers.map(p => p.id) : [])}
                    />
                  </TableCell>
                  <TableCell>Avatar</TableCell>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Posición</TableCell>
                  <TableCell>Equipo</TableCell>
                  <TableCell>Liga</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredAvailablePlayers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((player) => (
                    <DraggablePlayerRow 
                      key={player.id}
                      player={player}
                      handlePlayerClick={handlePlayerClick}
                      checkedPlayers={checkedPlayers}
                      handleTogglePlayer={handleTogglePlayer}
                      mediaUrl={mediaUrl}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={filteredAvailablePlayers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
            rowsPerPageOptions={[10]}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <BigBoardDropZone />
        </Grid>
      </Grid>
    </Box>
  </DndProvider>
);
};

export default BigBoardModif;