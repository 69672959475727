import React, { useState } from 'react';
import { TextField, Typography, Box } from '@mui/material';
import axios from 'axios';

const EditableStatField = ({ 
  item, 
  fieldName, 
  label, 
  icon: IconComponent, 
  unit, 
  playerId,
  onUpdate 
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(item[fieldName]);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;

  // Validation des nombres décimaux
  const decimalPattern = /^[0-9]{0,3}(\.[0-9]{0,2})?$/;

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleUpdate = async (newValue) => {
    // Si la valeur est vide ou nulle, on garde l'ancienne valeur
    if (newValue === '' || newValue === null || newValue === undefined) {
      setValue(item[fieldName]); // Restaure l'ancienne valeur
      setIsEditing(false);
      return;
    }

    try {
      await axios.put(`${backendUrl}/api/technique/${item.id}`, {
        [fieldName]: newValue,
        playerId,
        section
      });
      
      if (onUpdate) {
        onUpdate();
      }
    } catch (error) {
      console.error("Error updating field:", error);
      alert("Error al actualizar el campo");
      setValue(item[fieldName]); // Restaure l'ancienne valeur en cas d'erreur
    }
    setIsEditing(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleUpdate(value);
    } else if (e.key === 'Escape') {
      setValue(item[fieldName]);
      setIsEditing(false);
    }
  };

  const handleBlur = () => {
    handleUpdate(value);
  };

  if (isEditing) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconComponent sx={{ color: '#ff9800', mr: 2 }} />
        <TextField
          autoFocus
          value={value}
          onChange={(e) => {
            if (decimalPattern.test(e.target.value)) {
              setValue(e.target.value);
            }
          }}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          size="small"
          sx={{ width: '100px' }}
        />
        <Typography sx={{ ml: 1 }}>{unit}</Typography>
      </Box>
    );
  }

  // Ne montre pas le champ si la valeur est 0 ou négative
  if (item[fieldName] <= 0) return null;

  return (
    <Box 
      onDoubleClick={handleDoubleClick}
      sx={{ 
        display: 'flex', 
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)'
        }
      }}
    >
      <IconComponent sx={{ color: '#ff9800', mr: 2 }} />
      <Typography>
        <strong>{label}:</strong> {item[fieldName]}{unit}
      </Typography>
    </Box>
  );
};

export default EditableStatField;