import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Paper,
  Typography,
  Alert,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import axios from 'axios';

const Importar = () => {
  const [formData, setFormData] = useState({
    login: 'girarderic62@gmail.com',
    password: '51Basket',
    details: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const [playersData, setPlayersData] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedPlayerOptions, setSelectedPlayerOptions] = useState([]);
  const [currentPlayerName, setCurrentPlayerName] = useState('');

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);
    setPlayersData([]);

    try {
      const response = await axios.post(`${backendUrl}/api/importar`, {
        ...formData,
        section
      });

      setSuccess(true);
      setPlayersData(response.data.players);

    } catch (err) {
      setError(err.response?.data?.message || 'Une erreur est survenue lors de l\'importation');
    } finally {
      setLoading(false);
    }
  };

  const handleOptionClick = async (player) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(`${backendUrl}/api/importar/player`, {
        url: player.link,
        login: formData.login,
        password: formData.password,
        section
      });

      // Mettre à jour l'interface utilisateur pour refléter l'importation réussie
      setSuccess(true);

      // Mettre à jour le playersData pour refléter le joueur importé
      setPlayersData(prevData => prevData.map(p => {
        if (p.name === currentPlayerName) {
          return { ...response.data.player, multiple: false, found: true };
        }
        return p;
      }));
      setOpenDialog(false);

    } catch (err) {
      setError(err.response?.data?.message || 'Une erreur est survenue lors de l\'importation du joueur');
    } finally {
      setLoading(false);
    }
  };

  const handleViewOptions = (playerName, options) => {
    setCurrentPlayerName(playerName);
    setSelectedPlayerOptions(options);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedPlayerOptions([]);
  };

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', mt: 4 }}>
      <Paper sx={{ p: 4 }}>
        <Typography variant="h5" component="h1" gutterBottom>
          Importación de Datos
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {success && (
          <Alert severity="success" sx={{ mb: 2 }}>
            Importación realizada con éxito
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Login"
            name="login"
            value={formData.login}
            onChange={handleChange}
            margin="normal"
            required
            variant="outlined"
          />

          <TextField
            fullWidth
            label="Contraseña"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            margin="normal"
            required
            variant="outlined"
          />

          <TextField
            fullWidth
            label="Detalles"
            name="details"
            value={formData.details}
            onChange={handleChange}
            margin="normal"
            required
            multiline
            rows={10}
            variant="outlined"
          />

          <Button
            type="submit"
            variant="contained"
            sx={{ 
              mt: 3,
              backgroundColor: '#F25F29',
              '&:hover': {
                backgroundColor: '#d44d1a',
              }
            }}
            disabled={loading}
            fullWidth
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Importar'
            )}
          </Button>
        </form>

        {/* Afficher les données des joueurs */}
        {playersData.length > 0 && (
          <Box mt={4}>
            <Typography variant="h6">Resultados:</Typography>
            {playersData.map((player, index) => (
              <Box key={index} mt={2}>
                {player.multiple ? (
                  <>
                    <Typography variant="subtitle1">
                      {`Múltiples jugador@s encontrados para ${player.name}:`}
                    </Typography>
                    <Button variant="outlined" onClick={() => handleViewOptions(player.name, player.options)}>
                      Ver opciones
                    </Button>
                  </>
                ) : player.found === false ? (
                  <Typography variant="subtitle1">
                    {`No se encontró jugador para ${player.name}`}
                  </Typography>
                ) : (
                  <Typography variant="subtitle1">
                    {`Jugador ${player.nom} importado con éxito`}
                  </Typography>
                )}
              </Box>
            ))}
          </Box>
        )}

        {/* Dialog pour afficher les options multiples */}
        <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="md" fullWidth>
          <DialogTitle>{`Opciones para ${currentPlayerName}`}</DialogTitle>
          <DialogContent>
            <List>
              {selectedPlayerOptions.map((option, idx) => (
                <ListItem key={idx} disablePadding>
                  <ListItemButton onClick={() => handleOptionClick(option)}>
                    <ListItemText
                      primary={`${option.name} - ${option.pos} - ${option.team}`}
                      secondary={`Born: ${option.born}, Nationality: ${option.nat}`}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cerrar</Button>
          </DialogActions>
        </Dialog>

      </Paper>
    </Box>
  );
};

export default Importar;
