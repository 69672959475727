// src/models/playerModel.js

class Player {
  constructor({
    id,
    nom,
    anneeNaissance,
    dateNaissance,
    taille,
    poids,
    poste,
    type,
    groupes = [],
    nationalite,
    jeJi,
    seleccionJeJi,
    mano,
    rolOfensivo,
    rolDefensivo,
    pasaporte,
    estatus,
    equipo,
    liga1,
    liga2,
    equipoPreDraft,
    añoDraft,
    posicionDraft,
    grado,
    experienciaAcb,
    nivel,
    proyeccion,
    agencia,
    comparacionPro,
    comportamiento,
    rangoPrecios,
    contrato,
    url,
    team,
    league_1
  }) {
    this.id = id;                      // ID unique du joueur
    this.nom = nom;                    // Nom du joueur
    this.anneeNaissance = anneeNaissance; // Année de naissance
    this.dateNaissance = dateNaissance;   // Date de naissance complète
    this.taille = taille;              // Taille du joueur en cm
    this.poids = poids;                // Poids du joueur en kg
    this.poste = poste;                // Poste du joueur (e.g., "base", "alerio")
    this.type = type;                  // Type du joueur ("pro" ou "cantera")
    this.groupes = groupes;            // Liste des groupes du joueur, s'il est dans la cantera

    // Champs supplémentaires
    this.nationalite = nationalite;          // Nationalité du joueur
    this.jeJi = jeJi;                        // JE/JI
    this.seleccionJeJi = seleccionJeJi;      // Sélection JE/JI
    this.mano = mano;                        // Main dominante
    this.rolOfensivo = rolOfensivo;          // Rôle offensif
    this.rolDefensivo = rolDefensivo;        // Rôle défensif
    this.pasaporte = pasaporte;              // Pasaporte
    this.estatus = estatus;                  // Estatus
    this.equipo = equipo;                    // Équipe actuelle
    this.liga1 = liga1;                      // Ligue principale
    this.liga2 = liga2;                      // Ligue secondaire
    this.equipoPreDraft = equipoPreDraft;    // Équipe pré-draft
    this.añoDraft = añoDraft;                // Année de draft
    this.posicionDraft = posicionDraft;      // Position dans la draft
    this.grado = grado;                      // Grade
    this.experienciaAcb = experienciaAcb;    // Expérience ACB
    this.nivel = nivel;                      // Niveau
    this.proyeccion = proyeccion;            // Projection
    this.agencia = agencia;                  // Agence
    this.comparacionPro = comparacionPro;    // Comparaison professionnelle
    this.comportamiento = comportamiento;    // Comportement
    this.rangoPrecios = rangoPrecios;        // Rango de Precios
    this.contrato = contrato;                // Contrat
    this.url = url;                          // URL EuroBasket
    this.team = team;
    this.league_1= league_1   
  }

  // Méthode pour instancier un joueur à partir d'un objet brut (idéal pour les données fetchées)
  static fromJSON(json) {
    return new Player({
      id: json.id,
      nom: json.nom,
      anneeNaissance: json.annee_naissance,
      dateNaissance: json.date_naissance,
      taille: json.taille,
      poids: json.poids,
      poste: json.poste,
      type: json.type,
      groupes: json.groupes || [], // Par défaut, liste vide si aucun groupe

      // Correspondance des champs JSON avec les propriétés du modèle
      nationalite: json.nationalite,
      jeJi: json.je_ji,
      seleccionJeJi: json.selection_je_ji,
      mano: json.mano,
      rolOfensivo: json.offensive_role,
      rolDefensivo: json.defensive_role,
      pasaporte: json.passport,
      estatus: json.statut,
      equipo: json.team,
      liga1: json.league_1,
      liga2: json.league_2,
      equipoPreDraft: json.pre_draft_team,
      añoDraft: json.draft_year,
      posicionDraft: json.draft_pick,
      grado: json.grade,
      experienciaAcb: json.acb_experience,
      nivel: json.level,
      proyeccion: json.proyeccion,
      agencia: json.agency,
      comparacionPro: json.pro_comparaison,
      comportamiento: json.flag_behaviour,
      rangoPrecios: json.price_range,
      contrato: json.contract,
      url: json.url,
      team: json.team,
      league_1: json.league_1,
    });
  }
}

export default Player;
