import React, { useEffect, useState } from 'react';
import {
  Button, Box, Typography, Grid, Paper, Accordion, AccordionSummary, AccordionDetails, 
  Avatar, Divider, Chip
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import HeightIcon from '@mui/icons-material/Height';
import SpeedIcon from '@mui/icons-material/Speed';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import HandIcon from '@mui/icons-material/PanTool';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import EditableStatField from './EditableStatField';
import EditableTextField from './EditableTextField';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import jsPDF from 'jspdf';
import 'jspdf-autotable';



// Fonction utilitaire pour déterminer l'année de la saison
const getSeasonYear = (date) => {
  const d = new Date(date);
  const month = d.getMonth(); // 0-11
  const year = d.getFullYear();
  return month >= 7 ? year : year - 1;
};

// Fonction utilitaire pour générer le libellé de la saison
const getSeasonLabel = (year) => `Temporada ${year}-${year + 1}`;

// Fonction utilitaire pour obtenir l'URL de l'avatar
const getAvatarUrl = (userId) => {
  return `${process.env.REACT_APP_MEDIA_URL}/users/${userId}.png?${new Date().getTime()}`;
};

// Fonction utilitaire pour convertir le HTML en texte pour le PDF
const convertHtmlToText = (html) => {
  if (!html) return '';

  // Création d'un élément DOM temporaire pour parser le HTML
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;

  const traverse = (node) => {
    let text = '';

    if (node.nodeType === Node.TEXT_NODE) {
      return node.textContent;
    }

    if (node.nodeType === Node.ELEMENT_NODE) {
      const tagName = node.tagName.toLowerCase();

      if (tagName === 'br') {
        return '\n';
      }

      if (tagName === 'li') {
        const parentTag = node.parentElement.tagName.toLowerCase();
        const isOrdered = parentTag === 'ol';
        const prefix = isOrdered ? '1. ' : '• ';
        text += prefix;
      }

      // Parcours récursif des nœuds enfants
      for (let child of node.childNodes) {
        text += traverse(child);
      }

      // Ajout de retours à la ligne après les paragraphes et les éléments de liste
      if (tagName === 'p' || tagName === 'li') {
        text += '\n';
      }

      return text;
    }

    return '';
  };

  let result = traverse(tempDiv);

  // Remplacement des multiples retours à la ligne consécutifs par un seul
  result = result.replace(/\n{2,}/g, '\n');

  // Suppression des espaces et retours à la ligne en début et fin de texte
  return result.trim();
};



const TechnicaTab = ({ playerId, dateNaissance, nom, onDataLoaded }) => {
  const [techData, setTechData] = useState([]);
  const [heightData, setHeightData] = useState([]);
  const [groupedData, setGroupedData] = useState({});

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;

  const fetchTechData = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/technique`, { 
        params: { 
          playerId,
          section
        } 
      });
      
      const data = response.data;
      setTechData(data);

      // Traitement des données de hauteur pour le graphique
      if (data.length >= 2 && dateNaissance) {
        const processedData = data
          .filter(item => item.height_wo_shoes)
          .map(item => {
            const measurementDate = new Date(item.fecha);
            const birthDate = new Date(dateNaissance);
            const ageAtMeasurement = (measurementDate - birthDate) / (1000 * 60 * 60 * 24 * 365.25);
            
            return {
              age: parseFloat(ageAtMeasurement.toFixed(1)),
              height: item.height_wo_shoes,
              date: new Date(item.fecha).toLocaleDateString()
            };
          })
          .sort((a, b) => a.age - b.age);
          
        setHeightData(processedData);
      }

      // Grouper les données par saison
      const grouped = data.reduce((acc, item) => {
        const seasonYear = getSeasonYear(item.fecha);
        if (!acc[seasonYear]) {
          acc[seasonYear] = [];
        }
        acc[seasonYear].push(item);
        return acc;
      }, {});

      // Trier les données dans chaque saison par date (plus récent en premier)
      Object.keys(grouped).forEach(season => {
        grouped[season].sort((a, b) => new Date(b.fecha) - new Date(a.fecha));
      });

      setGroupedData(grouped);

      if (onDataLoaded) {
        onDataLoaded(data.length > 0);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des données techniques:", error);
    }
  };

  useEffect(() => {
    fetchTechData();
  }, [playerId, backendUrl, section, onDataLoaded, dateNaissance]);

  const getCurrentSeason = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1; // getMonth() returns 0-11
    
    // If we're between August and December, we're in year/year+1 season
    // Otherwise we're in year-1/year season
    if (month >= 8) {
      return `${year}/${year + 1}`;
    }
    return `${year - 1}/${year}`;
  };
  
  

  const generatePDF = async (item) => {
    try {
      const doc = new jsPDF();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      const contentWidth = pageWidth - 40; // Marges de 20 de chaque côté
  
      // Constantes pour la mise en page
      const lineHeight = 7; // Hauteur d'une ligne de texte
      const sectionSpacing = 5; // Espacement entre les sections
      const marginTop = 20;
      const marginBottom = 20;
      const marginLeft = 20;
      const marginRight = 20;
  
      // Fonction pour ajouter le template de page
      // Fonction pour ajouter le template de page
const addPageTemplate = (doc) => {
  const pageNumber = doc.getNumberOfPages();

  // Bordure orange
  doc.setDrawColor(255, 140, 0);
  doc.setLineWidth(2);
  doc.rect(10, 10, pageWidth - 20, pageHeight - 20);

  // Footer avec police plus petite et logos plus petits
  const footerY = doc.internal.pageSize.height - 22;
  const logoSize = 10; // Taille des logos

  // Logos et texte du footer
  doc.addImage(`${process.env.PUBLIC_URL}/assets/LogoValenciaBasket.png`, 'PNG', 25, footerY, logoSize, logoSize);

  // Assurez-vous que la couleur du texte est définie sur noir
  doc.setTextColor(0);

  doc.setFontSize(9); // Police plus petite pour le footer
  doc.text(
    `VALENCIA BASKET - DEPARTAMENTO DE SCOUTING ${getCurrentSeason()}`,
    doc.internal.pageSize.width / 2,
    footerY + 7,
    { align: 'center' }
  );
  doc.addImage(`${process.env.PUBLIC_URL}/assets/alqueria-del-basket.png`, 'PNG', doc.internal.pageSize.width - 35, footerY, logoSize, logoSize);
  doc.setFontSize(8);
  doc.text(pageNumber.toString(), 20, doc.internal.pageSize.height - 15);
};

  
      // Ajouter le template à la première page
      addPageTemplate(doc);
  
      // Position de départ pour le contenu
      let yPosition = marginTop;
  
      // Ajouter la photo du joueur (si disponible)
      try {
        const avatarUrl = `${process.env.REACT_APP_MEDIA_URL}/photos/${playerId}.png`;
        const response = await fetch(`${backendUrl}/api/proxy-image?url=${encodeURIComponent(avatarUrl)}`);
        const blob = await response.blob();
        const imgData = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });
        doc.addImage(imgData, 'PNG', marginLeft, yPosition, 30, 30);
      } catch (err) {
        console.error('Erreur lors du chargement de la photo du joueur:', err);
      }
  
      // En-têtes
      doc.setFontSize(24);
      doc.setTextColor(255, 140, 0);
      doc.text('Ficha Técnica', pageWidth / 2, yPosition + 15, { align: 'center' });
  
      doc.setFontSize(18);
      doc.setTextColor(0);
      doc.text(nom, pageWidth / 2, yPosition + 30, { align: 'center' });
  
      // Mise à jour de yPosition après les en-têtes
      yPosition += 40;
  
      doc.setFontSize(12);
      doc.text(`Fecha: ${new Date(item.fecha).toLocaleDateString()}`, marginLeft, yPosition);
      doc.text(`Evaluador: ${item.usuario_nombre}`, marginLeft, yPosition + lineHeight);
  
      // Mise à jour de yPosition après les informations
      yPosition += 20;
  
      // Titre "Medidas Físicas"
      doc.setFontSize(16);
      doc.setTextColor(255, 140, 0);
      doc.text('Medidas Físicas', marginLeft, yPosition);
  
      // Mise à jour de yPosition après le titre
      yPosition += 10;
  
      // Tableau des mesures physiques
      doc.autoTable({
        startY: yPosition,
        head: [['Medida', 'Valor']],
        body: [
          ['Body Fat', item.body_fat ? `${item.body_fat}%` : '-'],
          ['Height W/O Shoes', item.height_wo_shoes ? `${item.height_wo_shoes} cm` : '-'],
          ['Height With Shoes', item.height_with_shoes ? `${item.height_with_shoes} cm` : '-'],
          ['Shuttle Run', item.shuttle_run ? `${item.shuttle_run} s` : '-'],
          ['Wingspan Horizontal', item.wingspan_horizontal ? `${item.wingspan_horizontal} cm` : '-'],
          ['Hand Length', item.hand_length ? `${item.hand_length} cm` : '-'],
          ['Wingspan Vertical', item.wingspan_vertical ? `${item.wingspan_vertical} cm` : '-'],
          ['Standing Vertical Leap', item.standing_vertical_leap ? `${item.standing_vertical_leap} cm` : '-'],
          ['Max Vertical Leap', item.max_vertical_leap ? `${item.max_vertical_leap} cm` : '-'],
          ['Lane Agility', item.lane_agility ? `${item.lane_agility} s` : '-'],
          ['3/4 Sprint', item.three_four_sprint ? `${item.three_four_sprint} s` : '-'],
          ['Hand Width', item.hand_width ? `${item.hand_width} cm` : '-'],
        ],
        theme: 'striped',
        headStyles: {
          fillColor: [255, 140, 0],
          textColor: [255, 255, 255],
          fontSize: 10,
          fontStyle: 'bold',
        },
        styles: {
          fontSize: 9,
          cellPadding: 3,
          overflow: 'linebreak',
        },
        columnStyles: {
          0: { cellWidth: contentWidth * 0.7 },
          1: { cellWidth: contentWidth * 0.3, halign: 'center' },
        },
        margin: { left: marginLeft, right: marginRight },
        didDrawPage: function (data) {
          // Ajouter le template à chaque page générée par autoTable
          addPageTemplate(doc);
        },
      });
  
      // Mise à jour de yPosition après le tableau
      yPosition = doc.lastAutoTable.finalY + sectionSpacing;
  
      // Sections textuelles
      const textualFields = [
        {
          label: 'Puntos Fuertes y Débiles OF/DEF',
          value: convertHtmlToText(item.puntos_fuertes_debiles).trim(),
        },
        { label: 'Habilidad Atlética', value: convertHtmlToText(item.habilidad_atletica).trim() },
        { label: 'Aspectos Psicológicos', value: convertHtmlToText(item.aspectos_psicologicos).trim() },
        {
          label: 'Ámbito Académico y Familiar',
          value: convertHtmlToText(item.ambito_academico_familiar).trim(),
        },
        { label: 'Intel', value: convertHtmlToText(item.intel).trim() },
        {
          label: 'Aspectos para Seguir Desarrollando',
          value: convertHtmlToText(item.aspectos_para_seguir_desarrollando).trim(),
        },
        { label: 'Conclusiones', value: convertHtmlToText(item.conclusiones).trim() },
      ];
  
      for (let i = 0; i < textualFields.length; i++) {
        const field = textualFields[i];
  
        if (field.value) {
          // Nettoyage des espaces et retours à la ligne
          field.value = field.value.replace(/^\s+|\s+$/g, '');
          field.value = field.value.replace(/\n{2,}/g, '\n');
  
          // Split du texte pour gérer les retours à la ligne
          const textLines = doc.splitTextToSize(field.value, contentWidth);
  
          // Calcul de la hauteur nécessaire pour cette section
          const requiredHeight = lineHeight + textLines.length * lineHeight + sectionSpacing;
  
          // Vérification si on doit ajouter une nouvelle page
          if (yPosition + requiredHeight > pageHeight - marginBottom) {
            doc.addPage();
            addPageTemplate(doc);
            yPosition = marginTop;
          }
  
          // Titre de la section
          doc.setFontSize(14);
          doc.setTextColor(255, 140, 0);
          doc.text(field.label, marginLeft, yPosition);
  
          // Mise à jour de yPosition après le titre
          yPosition += lineHeight;
  
          // Contenu de la section
          doc.setFontSize(11);
          doc.setTextColor(0);
          doc.text(textLines, marginLeft, yPosition);
  
          // Mise à jour de yPosition après le contenu
          yPosition += textLines.length * lineHeight;
  
          // Ajout d'un espacement entre les sections (sauf pour la dernière)
          if (i < textualFields.length - 1) {
            yPosition += sectionSpacing;
          }
        }
      }
  
      // Enregistrement du PDF
      doc.save(
        `ficha_tecnica_${nom}_${new Date(item.fecha)
          .toLocaleDateString()
          .replace(/\//g, '-')}.pdf`
      );
    } catch (error) {
      console.error('Erreur lors de la génération du PDF:', error);
    }
  };
  
  

  const HeightEvolutionChart = () => {
    if (heightData.length < 2) return null;

    return (
      <Box sx={{ width: '100%', height: 200, mb: 3 }}>
        <ResponsiveContainer>
          <LineChart data={heightData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="age" 
              label={{ value: 'Edad (años)', position: 'bottom' }}
            />
            <YAxis 
              domain={['auto', 'auto']}
              label={{ value: 'Altura (cm)', angle: -90, position: 'insideLeft' }}
            />
            <Tooltip 
              formatter={(value, name) => [`${value} cm`, 'Altura']}
              labelFormatter={(age) => `Edad: ${age} años`}
            />
            <Line 
              type="monotone" 
              dataKey="height" 
              stroke="#ff9800" 
              strokeWidth={2}
              dot={{ r: 4 }} 
              activeDot={{ r: 6 }} 
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    );
  };

  const renderPhysicalMeasurements = (seasonData, isLatestSeason) => (
    <Accordion 
      defaultExpanded={isLatestSeason}
      sx={{ 
        mb: 4,
        border: '2px solid #ff9800',
        borderRadius: 2,
        overflow: 'hidden',
        '&:before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary 
        expandIcon={<ExpandMoreIcon />}
        sx={{
          borderBottom: '2px solid #ff9800',
          backgroundColor: '#fff',
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Medidas Físicas
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 2 }}>
        {seasonData.map((item, index) => (
          <Box 
            key={item.id}
            sx={{ 
              mb: 2,
              backgroundColor: index % 2 === 0 ? '#fff' : '#fff8e1',
              p: 2,
              borderRadius: 1
            }}
          >
            <Chip 
              label={new Date(item.fecha).toLocaleDateString()}
              sx={{ mb: 2 }}
              avatar={<Avatar src={getAvatarUrl(item.usuario_id)} />}
              size="small"
            />
            <Grid container spacing={1}>
              <Grid item xs={6} sm={4} md={3}>
                <EditableStatField
                  item={item}
                  fieldName="body_fat"
                  label="Body Fat"
                  icon={FitnessCenterIcon}
                  unit="%"
                  playerId={playerId}
                  onUpdate={fetchTechData}
                  size="small"
                  sx={{
                    '& .MuiTypography-root': { fontSize: '0.875rem' },
                    '& .MuiSvgIcon-root': { fontSize: '1.2rem' }
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <EditableStatField
                  item={item}
                  fieldName="height_wo_shoes"
                  label="Height W/O Shoes"
                  icon={HeightIcon}
                  unit="cm"
                  playerId={playerId}
                  onUpdate={fetchTechData}
                  size="small"
                  sx={{
                    '& .MuiTypography-root': { fontSize: '0.875rem' },
                    '& .MuiSvgIcon-root': { fontSize: '1.2rem' }
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <EditableStatField
                  item={item}
                  fieldName="height_with_shoes"
                  label="Height W/Shoes"
                  icon={HeightIcon}
                  unit="cm"
                  playerId={playerId}
                  onUpdate={fetchTechData}
                  size="small"
                  sx={{
                    '& .MuiTypography-root': { fontSize: '0.875rem' },
                    '& .MuiSvgIcon-root': { fontSize: '1.2rem' }
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <EditableStatField
                  item={item}
                  fieldName="shuttle_run"
                  label="Shuttle Run"
                  icon={SpeedIcon}
                  unit="s"
                  playerId={playerId}
                  onUpdate={fetchTechData}
                  size="small"
                  sx={{
                    '& .MuiTypography-root': { fontSize: '0.875rem' },
                    '& .MuiSvgIcon-root': { fontSize: '1.2rem' }
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <EditableStatField
                  item={item}
                  fieldName="wingspan_horizontal"
                  label="Wingspan Horiz."
                  icon={AccessibilityNewIcon}
                  unit="cm"
                  playerId={playerId}
                  onUpdate={fetchTechData}
                  size="small"
                  sx={{
                    '& .MuiTypography-root': { fontSize: '0.875rem' },
                    '& .MuiSvgIcon-root': { fontSize: '1.2rem' }
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <EditableStatField
                  item={item}
                  fieldName="hand_length"
                  label="Hand Length"
                  icon={HandIcon}
                  unit="cm"
                  playerId={playerId}
                  onUpdate={fetchTechData}
                  size="small"
                  sx={{
                    '& .MuiTypography-root': { fontSize: '0.875rem' },
                    '& .MuiSvgIcon-root': { fontSize: '1.2rem' }
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <EditableStatField
                  item={item}
                  fieldName="wingspan_vertical"
                  label="Wingspan Vert."
                  icon={AccessibilityNewIcon}
                  unit="cm"
                  playerId={playerId}
                  onUpdate={fetchTechData}
                  size="small"
                  sx={{
                    '& .MuiTypography-root': { fontSize: '0.875rem' },
                    '& .MuiSvgIcon-root': { fontSize: '1.2rem' }
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <EditableStatField
                  item={item}
                  fieldName="standing_vertical_leap"
                  label="Standing Vert."
                  icon={DirectionsRunIcon}
                  unit="cm"
                  playerId={playerId}
                  onUpdate={fetchTechData}
                  size="small"
                  sx={{
                    '& .MuiTypography-root': { fontSize: '0.875rem' },
                    '& .MuiSvgIcon-root': { fontSize: '1.2rem' }
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <EditableStatField
                  item={item}
                  fieldName="max_vertical_leap"
                  label="Max Vertical"
                  icon={DirectionsRunIcon}
                  unit="cm"
                  playerId={playerId}
                  onUpdate={fetchTechData}
                  size="small"
                  sx={{
                    '& .MuiTypography-root': { fontSize: '0.875rem' },
                    '& .MuiSvgIcon-root': { fontSize: '1.2rem' }
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <EditableStatField
                  item={item}
                  fieldName="lane_agility"
                  label="Lane Agility"
                  icon={SpeedIcon}
                  unit="s"
                  playerId={playerId}
                  onUpdate={fetchTechData}
                  size="small"
                  sx={{
                    '& .MuiTypography-root': { fontSize: '0.875rem' },
                    '& .MuiSvgIcon-root': { fontSize: '1.2rem' }
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <EditableStatField
                  item={item}
                  fieldName="three_four_sprint"
                  label="3/4 Sprint"
                  icon={SpeedIcon}
                  unit="s"
                  playerId={playerId}
                  onUpdate={fetchTechData}
                  size="small"
                  sx={{
                    '& .MuiTypography-root': { fontSize: '0.875rem' },
                    '& .MuiSvgIcon-root': { fontSize: '1.2rem' }
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <EditableStatField
                  item={item}
                  fieldName="hand_width"
                  label="Hand Width"
                  icon={HandIcon}
                  unit="cm"
                  playerId={playerId}
                  onUpdate={fetchTechData}
                  size="small"
                  sx={{
                    '& .MuiTypography-root': { fontSize: '0.875rem' },
                    '& .MuiSvgIcon-root': { fontSize: '1.2rem' }
                  }}
                />
              </Grid>
            </Grid>
            {index < seasonData.length - 1 && <Divider sx={{ mt: 2 }} />}
          </Box>
        ))}
      </AccordionDetails>
    </Accordion>
  );
  const renderTextSection = (seasonData, fieldName, label, isLatestSeason) => (
    <Accordion 
      defaultExpanded={isLatestSeason}
      sx={{ 
        mb: 4,
        border: '2px solid #ff9800',
        borderRadius: 2,
        overflow: 'hidden',
        '&:before': {
          display: 'none', // Enlève la ligne par défaut de l'accordéon
        },
      }}
    >
      <AccordionSummary 
        expandIcon={<ExpandMoreIcon />}
        sx={{
          borderBottom: '2px solid #ff9800',
          backgroundColor: '#fff',
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          {label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 2 }}>
        <Box sx={{ width: '100%' }}>
          {seasonData.map((item, index) => (
            <Box 
              key={item.id} 
              sx={{ 
                mb: index < seasonData.length - 1 ? 3 : 0,
                backgroundColor: index % 2 === 0 ? '#fff' : '#fff8e1',
                p: 2,
                borderRadius: 1
              }}
            >
              <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                <Chip 
                  label={new Date(item.fecha).toLocaleDateString()}
                  avatar={<Avatar src={getAvatarUrl(item.usuario_id)} />}
                />
              </Box>
              <Box sx={{ pl: 2 }}>
                <EditableTextField
                  item={item}
                  fieldName={fieldName}
                  label=""
                  playerId={playerId}
                  onUpdate={fetchTechData}
                  forceExpanded={true}
                  variant="standard"
                  fullWidth
                  multiline
                />
              </Box>
              {index < seasonData.length - 1 && <Divider sx={{ mt: 3 }} />}
            </Box>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );

  if (!Object.keys(groupedData).length) {
    return <Typography variant="h6">Aucune donnée disponible</Typography>;
  }

  return (
    <Box sx={{ padding: 2 }}>
      <HeightEvolutionChart />
      {Object.entries(groupedData)
        .sort(([yearA], [yearB]) => Number(yearB) - Number(yearA))
        .map(([year, seasonData], index) => {
          const isLatestSeason = index === 0; // Première saison dans l'ordre décroissant

          return (
            <Accordion 
            key={year} 
            defaultExpanded={isLatestSeason}
          >
            <AccordionSummary 
              expandIcon={<ExpandMoreIcon />}
              sx={{
                minHeight: '64px',
                '& .MuiAccordionSummary-content': {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }
              }}
            >
              <Box
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  backgroundColor: '#fff',
                  border: '2px solid #ff9800',
                  borderRadius: '25px',
                  padding: '8px 16px',
                  color: '#ff9800',
                  fontWeight: 'bold',
                }}
              >
                <Typography 
                  variant="h6"
                  sx={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                >
                {getSeasonLabel(Number(year))}
              </Typography>
              </Box>
              </AccordionSummary>
              <AccordionDetails>
                {renderPhysicalMeasurements(seasonData, isLatestSeason)}
                
                {renderTextSection(seasonData, "puntos_fuertes_debiles", "Puntos Fuertes y Débiles OF/DEF", isLatestSeason)}
                {renderTextSection(seasonData, "habilidad_atletica", "Habilidad Atlética", isLatestSeason)}
                {renderTextSection(seasonData, "aspectos_psicologicos", "Aspectos Psicológicos", isLatestSeason)}
                {renderTextSection(seasonData, "ambito_academico_familiar", "Ámbito Académico y Familiar", isLatestSeason)}
                {renderTextSection(seasonData, "intel", "Intel", isLatestSeason)}
                {renderTextSection(seasonData, "aspectos_para_seguir_desarrollando", "Aspectos para Seguir Desarrollando", isLatestSeason)}
                {renderTextSection(seasonData, "conclusiones", "Conclusiones", isLatestSeason)}
                
                {/* Boutons PDF pour chaque fiche de la saison */}
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h6" sx={{ mb: 2 }}>Exportar PDF</Typography>
                  <Grid container spacing={2}>
                    {seasonData.map((item) => (
                      <Grid item key={item.id}>
                        <Button
                          variant="contained"
                          startIcon={<PictureAsPdfIcon />}
                          onClick={() => generatePDF(item)}
                        >
                          {new Date(item.fecha).toLocaleDateString()}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>
          );
        })}
    </Box>
  );
};

export default TechnicaTab;