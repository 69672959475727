import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Avatar, Paper,
  IconButton, Box, Typography, TablePagination, Chip, Dialog, DialogTitle, DialogContent,
  TextField, useMediaQuery, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, Slider,
  DialogActions, Button, FormControlLabel, Switch, Card, CardContent, CardHeader, CardActions,
  SwipeableDrawer, Divider, List, ListItem, ListItemAvatar, ListItemSecondaryAction,
  Fab, Grid
} from '@mui/material';
import { 
  Visibility, 
  Info as InfoIcon, 
  Delete as DeleteIcon,
  FilterList,
  Close,
  Add
} from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import Player from '../models/playerModel';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';

// Fonction utilitaire pour déterminer la couleur du texte
const getContrastColor = (hexcolor) => {
  if (!hexcolor) return '#000000';
  const r = parseInt(hexcolor.slice(1, 3), 16);
  const g = parseInt(hexcolor.slice(3, 5), 16);
  const b = parseInt(hexcolor.slice(5, 7), 16);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.5 ? '#000000' : '#FFFFFF';
};

// Composant carte pour la vue mobile
const PlayerCard = ({ player, mediaUrl, timestamp, onView, onDelete, groupColors }) => {
  const theme = useTheme();

  return (
    <Card sx={{ mb: 2 }}>
      <CardHeader
        avatar={
          <Avatar
            src={`${mediaUrl}/photos/${player.id}.png?${timestamp}`}
            alt={player.nom}
            sx={{ width: 60, height: 60 }}
          />
        }
        title={
          <Typography variant="h6" component="div">
            {player.nom}
          </Typography>
        }
        subheader={
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, mt: 1 }}>
            <Typography variant="body2">{player.poste}</Typography>
            <Typography variant="body2">{player.team || 'Sin equipo'}</Typography>
          </Box>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              Año: {player.anneeNaissance}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              Altura: {player.taille} cm
            </Typography>
          </Grid>
        </Grid>
        {player.league_1 && (
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            Liga: {player.league_1}
          </Typography>
        )}
        {player.groupes && player.groupes[0]?.id !== null && (
          <Box sx={{ mt: 1 }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {player.groupes.map((group) => (
                <Chip
                  key={group.id}
                  label={group.libelle}
                  size="small"
                  sx={{
                    backgroundColor: group.code_couleur || 'grey',
                    color: getContrastColor(group.code_couleur),
                  }}
                />
              ))}
            </Box>
          </Box>
        )}
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button
          size="small"
          startIcon={<Visibility />}
          onClick={() => onView(player)}
          sx={{ color: theme.palette.primary.main }}
        >
          Ver
        </Button>
        <Button
          size="small"
          startIcon={<DeleteIcon />}
          onClick={(e) => {
            e.stopPropagation();
            onDelete(player, e);
          }}
          sx={{ color: theme.palette.error.main }}
        >
          Eliminar
        </Button>
      </CardActions>
    </Card>
  );
};

// Composant du tiroir de filtres
const FilterDrawer = ({
  open,
  onClose,
  positionFilter,
  setPositionFilter,
  groupFilter,
  setGroupFilter,
  leagueFilter,
  setLeagueFilter,
  heightFilter,
  setHeightFilter,
  ageFilter,
  setAgeFilter,
  canteraOnly,
  setCanteraOnly,
  groups,
  groupColors,
  uniqueLeagues,
  positionMappings
}) => {
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
      sx={{
        '& .MuiDrawer-paper': {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          maxHeight: '80vh',
          pb: 2
        }
      }}
    >
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h6">Filtros</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Divider sx={{ mb: 2 }} />

        <FormControlLabel
          control={
            <Switch
              checked={canteraOnly}
              onChange={(e) => setCanteraOnly(e.target.checked)}
              color="primary"
            />
          }
          label="Cantera"
          sx={{ mb: 2 }}
        />

        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Posición</InputLabel>
          <Select
            multiple
            value={positionFilter}
            onChange={(e) => setPositionFilter(e.target.value)}
            renderValue={(selected) => selected.join(', ')}
          >
            {Object.entries(positionMappings).map(([position, equivalents]) => (
              <MenuItem key={position} value={position}>
                <Checkbox checked={positionFilter.indexOf(position) > -1} />
                <ListItemText
                  primary={position}
                  secondary={equivalents.slice(1).join(', ')}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Grupos</InputLabel>
          <Select
            multiple
            value={groupFilter}
            onChange={(e) => setGroupFilter(e.target.value)}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((group) => (
                  <Chip
                    key={group}
                    label={group}
                    sx={{
                      backgroundColor: groupColors[group] || 'grey',
                      color: getContrastColor(groupColors[group])
                    }}
                  />
                ))}
              </Box>
            )}
          >
            {groups.map((group) => (
              <MenuItem key={group.libelle} value={group.libelle}>
                <Checkbox checked={groupFilter.indexOf(group.libelle) > -1} />
                <ListItemText primary={group.libelle} />
                <Box
                  sx={{
                    width: 16,
                    height: 16,
                    backgroundColor: group.code_couleur || 'grey',
                    borderRadius: '50%',
                    ml: 1
                  }}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mb: 3 }}>
          <InputLabel>Liga</InputLabel>
          <Select
            multiple
            value={leagueFilter}
            onChange={(e) => setLeagueFilter(e.target.value)}
            renderValue={(selected) => selected.join(', ')}
          >
            {uniqueLeagues.map((league) => (
              <MenuItem key={league} value={league}>
                <Checkbox checked={leagueFilter.indexOf(league) > -1} />
                <ListItemText primary={league} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Typography gutterBottom>Altura (130-230 cm)</Typography>
        <Slider
          value={heightFilter}
          onChange={(e, newValue) => setHeightFilter(newValue)}
          valueLabelDisplay="auto"
          min={130}
          max={230}
          sx={{ mb: 3 }}
        />

        <Typography gutterBottom>Edad (12-45 años)</Typography>
        <Slider
          value={ageFilter}
          onChange={(e, newValue) => setAgeFilter(newValue)}
          valueLabelDisplay="auto"
          min={12}
          max={45}
        />
      </Box>
    </SwipeableDrawer>
  );
};

// Position mappings
const positionMappings = {
  'base': ['base', 'Point Guard'],
  'escolta': ['escolta', 'Shooting Guard', 'Guard'],
  'alerio': ['alerio', 'Small Forward', 'Swingman'],
  'ala-pivot': ['ala-pivot', 'Power Forward', 'Power Forward / Center'],
  'pivot': ['pivot', 'Center']
};
const PlayerList = () => {
  const [players, setPlayers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupColors, setGroupColors] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [positionFilter, setPositionFilter] = useState([]);
  const [groupFilter, setGroupFilter] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [nameFilter, setNameFilter] = useState('');
  const [heightFilter, setHeightFilter] = useState([130, 230]);
  const [ageFilter, setAgeFilter] = useState([12, 45]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [uniqueLeagues, setUniqueLeagues] = useState([]);
  const [leagueFilter, setLeagueFilter] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [playerToDelete, setPlayerToDelete] = useState(null);
  const [canteraOnly, setCanteraOnly] = useState(false);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const location = useLocation();

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const timestamp = Date.now();

  // Charger les filtres depuis l'URL
  useEffect(() => {
    if (isInitialLoad) {
      const params = new URLSearchParams(location.search);
      setNameFilter(params.get('nameFilter') || '');
      setPositionFilter(params.get('positionFilter') ? params.get('positionFilter').split(',') : []);
      setGroupFilter(params.get('groupFilter') ? params.get('groupFilter').split(',') : []);
      setLeagueFilter(params.get('leagueFilter') ? params.get('leagueFilter').split(',') : []);
      setHeightFilter(params.get('heightFilter') ? params.get('heightFilter').split(',').map(Number) : [130, 230]);
      setAgeFilter(params.get('ageFilter') ? params.get('ageFilter').split(',').map(Number) : [12, 45]);
      setCanteraOnly(params.get('canteraOnly') === 'true');
      setPage(params.get('page') ? parseInt(params.get('page'), 10) : 0);
      setIsInitialLoad(false);
    }
  }, [location.search, isInitialLoad]);

  // Mettre à jour l'URL avec les filtres
  useEffect(() => {
    if (!isInitialLoad) {
      const params = new URLSearchParams();
      if (nameFilter) params.set('nameFilter', nameFilter);
      if (positionFilter.length) params.set('positionFilter', positionFilter.join(','));
      if (groupFilter.length) params.set('groupFilter', groupFilter.join(','));
      if (leagueFilter.length) params.set('leagueFilter', leagueFilter.join(','));
      if (heightFilter.length) params.set('heightFilter', heightFilter.join(','));
      if (ageFilter.length) params.set('ageFilter', ageFilter.join(','));
      if (canteraOnly) params.set('canteraOnly', 'true');
      if (page) params.set('page', page);

      const newUrl = `${location.pathname}?${params.toString()}`;
      if (location.search !== `?${params.toString()}`) {
        navigate(newUrl, { replace: true });
      }
    }
  }, [nameFilter, positionFilter, groupFilter, heightFilter, ageFilter, canteraOnly, page, isInitialLoad, location.pathname, navigate]);

  // Charger les données
  useEffect(() => {                                                                                         
    const fetchData = async () => {
      try {
        const user = JSON.parse(localStorage.getItem('user'));
        
        const playersEndpoint = user?.role === 'entrenador'                                               
          ? `${backendUrl}/api/players/coach/${user.id}?section=${section}`
          : `${backendUrl}/api/players?section=${section}`;
   
        const [playersResponse, groupsResponse] = await Promise.all([
          fetch(playersEndpoint), 
          axios.get(`${backendUrl}/api/groups`, { params: { section } }),
        ]);
        
        const playersData = await playersResponse.json();
        setPlayers(playersData.players.map((item) => Player.fromJSON(item)));
        
        const leagues = Array.from(new Set(
          playersData.players
            .map(player => player.league_1) 
            .filter(league => league?.trim() && league.trim() !== 'null')
        )).sort();
        setUniqueLeagues(leagues);
   
        setGroups(groupsResponse.data);
        const colors = {};
        groupsResponse.data.forEach((group) => {
          colors[group.libelle] = group.code_couleur;  
        });
        setGroupColors(colors);
   
      } catch (error) {
        console.error("Erreur:", error);
      }
    };
   
    if (isInitialLoad) fetchData();
   }, [section, backendUrl, isInitialLoad]);


  // Event handlers
  const calculateAge = (dateNaissance) => {
    const birthDate = new Date(dateNaissance);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const handleViewPlayer = (player) => {
    navigate(`/players/${player.id}`);
  };

  const handleDeleteClick = (player, event) => {
    event.stopPropagation();
    setPlayerToDelete(player);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(`${backendUrl}/api/players/${playerToDelete.id}?section=${section}`);
      setPlayers(players.filter(player => player.id !== playerToDelete.id));
      handleCloseDeleteDialog();
    } catch (error) {
      console.error("Error al eliminar el jugador:", error);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setPlayerToDelete(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Filter players
  const filteredPlayers = players.filter(player => {
    const playerAge = calculateAge(player.dateNaissance);
    const playerGroups = player.groupes.map(group => group.libelle);
    const searchTerm = nameFilter.toLowerCase();

    const positionMatches = positionFilter.length === 0 || 
      positionFilter.some(pos => 
        positionMappings[pos].some(mappedPos => 
          player.poste.toLowerCase() === mappedPos.toLowerCase()
        )
      );

    const canteraMatches = !canteraOnly || player.type === "cantera";

    return (
      (player.nom.toLowerCase().includes(searchTerm) || 
       (player.team && player.team.toLowerCase().includes(searchTerm))) &&
      positionMatches &&
      canteraMatches &&
      (leagueFilter.length === 0 || leagueFilter.includes(player.league_1)) &&
      player.taille >= heightFilter[0] && player.taille <= heightFilter[1] &&
      playerAge >= ageFilter[0] && playerAge <= ageFilter[1] &&
      (groupFilter.length === 0 || groupFilter.some(group => playerGroups.includes(group)))
    );
  });

  return (
    <Box sx={{ mt: 4, px: 2, pb: isMobile ? 7 : 4 }}>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        gap: 2, 
        mb: 3
      }}>
        <TextField
          label="Filtrar por nombre o equipo"
          variant="outlined"
          value={nameFilter}
          onChange={(e) => setNameFilter(e.target.value)}
          fullWidth
        />
        
        {isMobile ? (
          <Fab
            color="primary"
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 16,
              zIndex: theme.zIndex.fab
            }}
            onClick={() => setOpenFilterDrawer(true)}
          >
            <FilterList />
          </Fab>
        ) : (
          // Desktop filters layout
          <>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <FormControl sx={{ flex: 1 }}>
                <InputLabel>Filtrar por posición</InputLabel>
                <Select
                  multiple
                  value={positionFilter}
                  onChange={(e) => setPositionFilter(e.target.value)}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {Object.entries(positionMappings).map(([position, equivalents]) => (
                    <MenuItem key={position} value={position}>
                      <Checkbox checked={positionFilter.indexOf(position) > -1} />
                      <ListItemText 
                        primary={position}
                        secondary={equivalents.slice(1).join(', ')}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ flex: 1 }}>
                <InputLabel>Filtrar por grupos</InputLabel>
                <Select
                  multiple
                  value={groupFilter}
                  onChange={(e) => setGroupFilter(e.target.value)}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((group) => (
                        <Chip
                          key={group}
                          label={group}
                          sx={{ 
                            backgroundColor: groupColors[group] || 'grey',
                            color: getContrastColor(groupColors[group])
                          }}
                        />
                      ))}
                    </Box>
                  )}
                >
                  {groups.map((group) => (
                    <MenuItem key={group.libelle} value={group.libelle}>
                      <Checkbox checked={groupFilter.indexOf(group.libelle) > -1} />
                      <ListItemText primary={group.libelle} />
                      <Box
                        sx={{
                          width: 16,
                          height: 16,
                          backgroundColor: group.code_couleur || 'grey',
                          borderRadius: '50%',
                          ml: 1
                        }}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ display: 'flex', gap: 2 }}>
              <FormControl sx={{ flex: 1 }}>
                <Typography gutterBottom>Altura (130-230 cm)</Typography>
                <Slider
                  value={heightFilter}
                  onChange={(e, newValue) => setHeightFilter(newValue)}
                  valueLabelDisplay="auto"
                  min={130}
                  max={230}
                />
              </FormControl>

              <FormControl sx={{ flex: 1 }}>
                <Typography gutterBottom>Edad (12-45 años)</Typography>
                <Slider
                  value={ageFilter}
                  onChange={(e, newValue) => setAgeFilter(newValue)}
                  valueLabelDisplay="auto"
                  min={12}
                  max={45}
                />
              </FormControl>
            </Box>

            <FormControlLabel
              control={
                <Switch
                  checked={canteraOnly}
                  onChange={(e) => setCanteraOnly(e.target.checked)}
                  color="primary"
                />
              }
              label="Cantera"
            />
          </>
        )}
      </Box>

      <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>
        Total: {filteredPlayers.length} jugador@s
      </Typography>

      {isMobile ? (
        // Mobile view with cards
        <Box>
          {filteredPlayers
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((player) => (
              <PlayerCard
                key={player.id}
                player={player}
                mediaUrl={mediaUrl}
                timestamp={timestamp}
                onView={handleViewPlayer}
                onDelete={handleDeleteClick}
                groupColors={groupColors}
              />
            ))}
        </Box>
      ) : (
        // Desktop view with table
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ backgroundColor: '#ffe0b2' }}></TableCell>
                <TableCell sx={{ backgroundColor: '#ffe0b2', fontWeight: 'bold' }}>Nombre</TableCell>
                <TableCell sx={{ backgroundColor: '#ffe0b2', fontWeight: 'bold' }}>Posición</TableCell>
                <TableCell sx={{ backgroundColor: '#ffe0b2', fontWeight: 'bold' }}>Año</TableCell>
                <TableCell sx={{ backgroundColor: '#ffe0b2', fontWeight: 'bold' }}>Altura</TableCell>
                <TableCell sx={{ backgroundColor: '#ffe0b2', fontWeight: 'bold' }}>Equipo</TableCell>
                <TableCell sx={{ backgroundColor: '#ffe0b2', fontWeight: 'bold' }}>Liga</TableCell>
                <TableCell sx={{ backgroundColor: '#ffe0b2', fontWeight: 'bold' }}>Grupos</TableCell>
                <TableCell sx={{ backgroundColor: '#ffe0b2', fontWeight: 'bold' }}>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredPlayers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((player) => (
                  <TableRow key={player.id} hover>
                    <TableCell>
                      <Avatar
                        src={`${mediaUrl}/photos/${player.id}.png?${timestamp}`}
                        alt={player.nom}
                        sx={{ width: 40, height: 40 }}
                      />
                    </TableCell>
                    <TableCell>{player.nom}</TableCell>
                    <TableCell>{player.poste}</TableCell>
                    <TableCell>{player.anneeNaissance}</TableCell>
                    <TableCell>{player.taille}</TableCell>
                    <TableCell>{player.team}</TableCell>
                    <TableCell>{player.league_1}</TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {player.groupes && player.groupes[0]?.id !== null && 
                          player.groupes.map((group) => (
                            <Chip
                              key={group.id}
                              label={group.libelle}
                              size="small"
                              sx={{
                                backgroundColor: group.code_couleur || 'grey',
                                color: getContrastColor(group.code_couleur)
                              }}
                            />
                          ))
                        }
                      </Box>
                    </TableCell>
                    <TableCell>
                      <IconButton 
                        onClick={() => handleViewPlayer(player)}
                        sx={{ color: '#ff9800' }}
                      >
                        <Visibility />
                      </IconButton>
                      <IconButton
                        onClick={(e) => handleDeleteClick(player, e)}
                        sx={{ color: '#f44336' }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <TablePagination
        component="div"
        count={filteredPlayers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 25, 50, 100]}
        labelRowsPerPage="Filas por página:"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
      />

      {/* Mobile Filter Drawer */}
      <FilterDrawer
        open={openFilterDrawer}
        onClose={() => setOpenFilterDrawer(false)}
        positionFilter={positionFilter}
        setPositionFilter={setPositionFilter}
        groupFilter={groupFilter}
        setGroupFilter={setGroupFilter}
        leagueFilter={leagueFilter}
        setLeagueFilter={setLeagueFilter}
        heightFilter={heightFilter}
        setHeightFilter={setHeightFilter}
        ageFilter={ageFilter}
        setAgeFilter={setAgeFilter}
        canteraOnly={canteraOnly}
        setCanteraOnly={setCanteraOnly}
        groups={groups}
        groupColors={groupColors}
        uniqueLeagues={uniqueLeagues}
        positionMappings={positionMappings}
      />

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">
          Confirmar Eliminación
        </DialogTitle>
        <DialogContent>
          <Typography>
            ¿Está seguro que desea eliminar al jugador {playerToDelete?.nom}? 
            Esta acción no se puede deshacer.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>
            Cancelar
          </Button>
          <Button 
            onClick={handleConfirmDelete} 
            color="error" 
            variant="contained"
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PlayerList;