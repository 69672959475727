import React, { createContext, useState } from 'react';

export const SeguimientoContext = createContext();

export const SeguimientoProvider = ({ children }) => {
  const [seguimientoPlayers, setSeguimientoPlayers] = useState([]);

  return (
    <SeguimientoContext.Provider value={{ seguimientoPlayers, setSeguimientoPlayers }}>
      {children}
    </SeguimientoContext.Provider>
  );
};