import React, { useEffect, useState } from 'react';
import { VariableSizeList } from 'react-window';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  IconButton,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  Grid,
  useTheme,
  useMediaQuery,
  SwipeableDrawer,
  Fab,
  Divider,
  Card,
  CardContent,
  CardHeader,
  CardActions,
} from '@mui/material';
import { Tooltip } from '@mui/material';
import { Autocomplete } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Configuration de la virtualisation pour Autocomplete
const LISTBOX_PADDING = 8;

function renderRow(props) {
  const { data, index, style } = props;
  const item = data[index];
  return React.cloneElement(item, {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return (
    <div 
      ref={ref} 
      {...props} 
      {...outerProps} 
      style={{ 
        ...props.style,
        overflowX: 'hidden',
        overflowY: 'scroll',
        maxWidth: '100%'
      }}
    />
  );
});

function useResetCache(data) {
  const ref = React.useRef(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const ListboxComponent = React.forwardRef((props, ref) => {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const itemCount = itemData.length;
  const itemSize = 48;

  const getChildSize = () => itemSize;

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemCount * itemSize;
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={getChildSize}
          overscanCount={5}
          itemCount={itemCount}
          style={{
            padding: 0,
            margin: 0,
            overflowX: 'hidden'
          }}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

// Fonctions utilitaires
const getInitials = (nom, prenom) => {
  const firstInitial = nom ? nom[0] : '';
  const secondInitial = prenom ? prenom[0] : '';
  return (firstInitial + secondInitial).toUpperCase();
};

const getAvatarColor = (name) => {
  if (!name) return '#757575';
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const hue = Math.abs(hash % 360);
  return `hsl(${hue}, 70%, 50%)`;
};

const getAvatarUrl = (userId) => {
  return userId
    ? `${process.env.REACT_APP_MEDIA_URL}/users/${userId}.png?${new Date().getTime()}`
    : '/path/to/default-avatar.png';
};

const getPlayerAvatarUrl = (playerId) => {
  return playerId
    ? `${process.env.REACT_APP_MEDIA_URL}/photos/${playerId}.png?${new Date().getTime()}`
    : '/path/to/default-player-avatar.png';
};
// Composant pour la vue mobile d'un intel
const MobileIntelCard = ({ intel, onEdit, onDelete, open, onToggle }) => {
  const theme = useTheme();

  return (
    <Card sx={{ mb: 2, boxShadow: theme.shadows[2] }}>
      <CardHeader
        avatar={
          <Avatar
            src={getPlayerAvatarUrl(intel.player_id)}
            alt={intel.player_nom}
          />
        }
        title={intel.player_nom}
        subheader={new Date(intel.fecha).toLocaleDateString()}
        action={
          <IconButton onClick={() => onToggle(intel.id)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        }
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <CardContent>
          <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
            <Avatar
              src={getAvatarUrl(intel.usuario_id)}
              sx={{ width: 24, height: 24 }}
            />
            <Typography variant="body2" color="text.secondary">
              {intel.usuario_prenom} {intel.usuario_nom}
            </Typography>
          </Box>
          <Typography variant="subtitle1" gutterBottom>
            <strong>Fuente:</strong> {intel.fuente}
          </Typography>
          <Typography
            variant="body2"
            component="div"
            sx={{
              backgroundColor: '#FFECB3',
              p: 2,
              borderRadius: 1,
              mt: 1,
              mb: 2,
            }}
            dangerouslySetInnerHTML={{ __html: intel.detalles }}
          />
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
          <Button
            size="small"
            startIcon={<EditIcon />}
            onClick={() => onEdit(intel)}
          >
            Editar
          </Button>
          <Button
            size="small"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={() => onDelete(intel)}
          >
            Eliminar
          </Button>
        </CardActions>
      </Collapse>
    </Card>
  );
};

// Composant pour le tiroir de filtres mobile
const MobileFilterDrawer = ({
  open,
  onClose,
  players,
  users,
  selectedPlayer,
  selectedUser,
  onPlayerChange,
  onUserChange,
}) => {
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
      sx={{
        '& .MuiDrawer-paper': {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          maxHeight: '80vh',
        },
      }}
    >
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h6">Filtros</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider sx={{ mb: 2 }} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                options={players}
                getOptionLabel={(player) => player.nom}
                value={selectedPlayer}
                onChange={onPlayerChange}
                ListboxComponent={ListboxComponent}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Seleccionar jugador"
                    variant="outlined"
                  />
                )}
                renderOption={(props, player) => (
                  <Box
                    component="li"
                    {...props}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Avatar
                      src={getPlayerAvatarUrl(player.id)}
                      alt={player.nom}
                      sx={{ mr: 1, width: 32, height: 32 }}
                    />
                    {player.nom}
                  </Box>
                )}
                clearOnEscape
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                options={users}
                getOptionLabel={(user) => `${user.prenom} ${user.nom}`}
                value={selectedUser}
                onChange={onUserChange}
                ListboxComponent={ListboxComponent}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Seleccionar usuario"
                    variant="outlined"
                  />
                )}
                renderOption={(props, user) => (
                  <Box
                    component="li"
                    {...props}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Avatar
                      src={getAvatarUrl(user.id)}
                      alt={user.nom}
                      sx={{ mr: 1, width: 32, height: 32 }}
                    />
                    {user.prenom} {user.nom}
                  </Box>
                )}
                clearOnEscape
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </SwipeableDrawer>
  );
};
// Composant principal
const RechercheIntel = ({ onDataLoaded }) => {
  const [allIntelData, setAllIntelData] = useState([]);
  const [intelData, setIntelData] = useState([]);
  const [openRows, setOpenRows] = useState({});
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [currentIntel, setCurrentIntel] = useState(null);
  const [editedDetalles, setEditedDetalles] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [intelToDelete, setIntelToDelete] = useState(null);
  const [players, setPlayers] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Effets
  // Au début du composant RechercheIntel, ajoutez:
const [user, setUser] = useState(null);

// Dans le premier useEffect qui charge les intel, modifiez comme suit:
useEffect(() => {
  const fetchAllIntel = async () => {
    try {
      const params = { section };
      const response = await axios.get(`${backendUrl}/api/intel/all`, { params });
      
      // Récupérer l'utilisateur connecté du localStorage
      const loggedUser = JSON.parse(localStorage.getItem('user'));
      setUser(loggedUser);

      // Filtrer les intel si c'est un entrenador
      let intel = response.data;
      if (loggedUser?.role === 'entrenador') {
        intel = response.data.filter(item => item.usuario_id === loggedUser.id);
      }

      setAllIntelData(intel);
      setIntelData(intel);
      if (onDataLoaded) {
        onDataLoaded(intel.length > 0);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des Intel:", error);
    }
  };

  fetchAllIntel();
}, [backendUrl, section, onDataLoaded]);

  useEffect(() => {
    let filteredData = allIntelData;
    if (selectedPlayer) {
      filteredData = filteredData.filter((intel) => intel.player_id === selectedPlayer.id);
    }
    if (selectedUser) {
      filteredData = filteredData.filter((intel) => intel.usuario_id === selectedUser.id);
    }
    setIntelData(filteredData);
  }, [allIntelData, selectedPlayer, selectedUser]);

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/players/select`, {
          params: { section }
        });
        setPlayers(Array.isArray(response.data) ? response.data : response.data.players || []);
      } catch (error) {
        console.error("Erreur lors de la récupération des joueurs:", error);
        setPlayers([]);
      }
    };
    fetchPlayers();
  }, [backendUrl, section]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/users`, {
          params: { section }
        });
        setUsers(Array.isArray(response.data) ? response.data : response.data.users || []);
      } catch (error) {
        console.error("Erreur lors de la récupération des utilisateurs:", error);
        setUsers([]);
      }
    };
    fetchUsers();
  }, [backendUrl, section]);

  // Gestionnaires d'événements
  const toggleRow = (rowId) => {
    setOpenRows((prev) => ({ ...prev, [rowId]: !prev[rowId] }));
  };

  const handleEditClick = (intel) => {
    setCurrentIntel(intel);
    setEditedDetalles(intel.detalles);
    setOpenEditDialog(true);
  };

  const handleEditSave = async () => {
    try {
      const transformedDetalles = editedDetalles
        .replace(/<p><br><\/p>/g, '<br>')
        .replace(/<p>/g, '')
        .replace(/<\/p>/g, '<br>')
        .replace(/<br><br>/g, '<br>');

      await axios.put(`${backendUrl}/api/intel/${currentIntel.id}`,
        { detalles: transformedDetalles },
        { params: { section } }
      );

      setAllIntelData((prevAll) =>
        prevAll.map((item) =>
          item.id === currentIntel.id ? { ...item, detalles: transformedDetalles } : item
        )
      );

      setOpenEditDialog(false);
      alert("Intel actualizado con éxito!");
    } catch (error) {
      console.error("Error al actualizar el Intel:", error);
      alert("Error al actualizar el Intel.");
    }
  };

  const handleDeleteClick = (intel) => {
    setIntelToDelete(intel);
    setOpenDeleteDialog(true);
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(`${backendUrl}/api/intel/${intelToDelete.id}`, {
        params: { section }
      });
      setAllIntelData((prevAll) =>
        prevAll.filter((item) => item.id !== intelToDelete.id)
      );
      setOpenDeleteDialog(false);
      alert("Intel eliminado con éxito!");
    } catch (error) {
      console.error("Error al eliminar el Intel:", error);
      alert("Error al eliminar el Intel.");
    }
  };

  const cancelDelete = () => {
    setOpenDeleteDialog(false);
    setIntelToDelete(null);
  };

  return (
    <Box sx={{ position: 'relative', pb: isMobile ? 7 : 0 }}>
      {isMobile ? (
        <>
          {/* Vue mobile */}
          <Box sx={{ p: 2 }}>
            {intelData.map((intel) => (
              <MobileIntelCard
                key={intel.id}
                intel={intel}
                open={openRows[intel.id]}
                onToggle={toggleRow}
                onEdit={handleEditClick}
                onDelete={handleDeleteClick}
              />
            ))}
          </Box>

          {/* Bouton de filtre flottant */}
          <Fab
            color="primary"
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 16,
              zIndex: theme.zIndex.fab
            }}
            onClick={() => setOpenFilterDrawer(true)}
          >
            <FilterListIcon />
          </Fab>

          {/* Tiroir de filtres mobile */}
          <MobileFilterDrawer
            open={openFilterDrawer}
            onClose={() => setOpenFilterDrawer(false)}
            players={players}
            users={users}
            selectedPlayer={selectedPlayer}
            selectedUser={selectedUser}
            onPlayerChange={(_, newValue) => setSelectedPlayer(newValue)}
            onUserChange={(_, newValue) => setSelectedUser(newValue)}
          />
        </>
      ) : (
        /* Vue desktop */
        <>
          <Grid container spacing={2} marginBottom={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Autocomplete
                  options={players}
                  getOptionLabel={(player) => player.nom}
                  value={selectedPlayer}
                  onChange={(_, newValue) => setSelectedPlayer(newValue)}
                  ListboxComponent={ListboxComponent}
                  renderInput={(params) => (
                    <TextField {...params} label="Seleccionar jugador" variant="outlined" />
                  )}
                  renderOption={(props, player) => (
                    <Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar
                        src={getPlayerAvatarUrl(player.id)}
                        alt={player.nom}
                        sx={{ mr: 1 }}
                      />
                      {player.nom}
                    </Box>
                  )}
                  clearOnEscape
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Autocomplete
                  options={users}
                  getOptionLabel={(user) => user.nom}
                  value={selectedUser}
                  onChange={(_, newValue) => setSelectedUser(newValue)}
                  ListboxComponent={ListboxComponent}
                  renderInput={(params) => (
                    <TextField {...params} label="Seleccionar usuario" variant="outlined" />
                  )}
                  renderOption={(props, user) => (
                    <Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar
                        src={getAvatarUrl(user.id)}
                        alt={user.nom}
                        sx={{ width: 30, height: 30, mr: 1 }}
                      />
                      {user.prenom} {user.nom}
                    </Box>
                  )}
                  clearOnEscape
                />
              </FormControl>
            </Grid>
          </Grid>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Creador</TableCell>
                  <TableCell>Jugador</TableCell>
                  <TableCell>Fuente</TableCell>
                  <TableCell>Detalles</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {intelData.map((row) => (
                  <React.Fragment key={row.id}>
                    <TableRow>
                      <TableCell>{new Date(row.fecha).toLocaleDateString()}</TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Tooltip
                            arrow
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: 'white',
                                  '& .MuiTooltip-arrow': {
                                    color: 'white'
                                  },
                                  boxShadow: '0px 2px 8px rgba(0,0,0,0.15)'
                                }
                              }
                            }}
                            title={
                              <Box sx={{ p: 1, display: 'flex', alignItems: 'center', gap: 2 }}>
                                <Avatar
                                  src={getAvatarUrl(row.usuario_id)}
                                  sx={{ width: 50, height: 50 }}
                                />
                                <Box>
                                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'black' }}>
                                    {row.usuario_prenom} {row.usuario_nom}
                                  </Typography>
                                  <Typography variant="body2" sx={{ color: 'rgba(0,0,0,0.7)' }}>
                                    {row.usuario_nombre}
                                  </Typography>
                                </Box>
                              </Box>
                            }
                          >
                            <Avatar
                              sx={{
                                mr: 1,
                                bgcolor: getAvatarColor(row.usuario_nom + row.usuario_prenom)
                              }}
                            >
                              {getInitials(row.usuario_prenom, row.usuario_nom)}
                            </Avatar>
                          </Tooltip>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar src={getPlayerAvatarUrl(row.player_id)} alt={row.player_nom} sx={{ mr: 1 }} />
                          <Typography variant="body2">{row.player_nom}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell>{row.fuente}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => toggleRow(row.id)}>
                          {openRows[row.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleEditClick(row)} color="primary">
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteClick(row)} color="error">
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={openRows[row.id]} timeout="auto" unmountOnExit>
                          <Box margin={1}>
                            <Typography
                              variant="body2"
                              style={{ backgroundColor: '#FFECB3', padding: '10px', borderRadius: '4px' }}
                              dangerouslySetInnerHTML={{ __html: row.detalles }}
                            />
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {/* Dialogs communs */}
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)} fullWidth maxWidth="md">
        <DialogTitle>Modificar el Intel</DialogTitle>
        <DialogContent>
          {currentIntel && (
            <>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Fuente:</strong> {currentIntel.fuente}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Fecha:</strong> {new Date(currentIntel.fecha).toLocaleDateString()}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Detalles:</strong>
              </Typography>
              <ReactQuill
                theme="snow"
                value={editedDetalles}
                onChange={setEditedDetalles}
                placeholder="Modificar los detalles del Intel..."
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleEditSave} color="primary" variant="contained">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDeleteDialog}
        onClose={cancelDelete}
        aria-labelledby="delete-confirmation-dialog"
      >
        <DialogTitle id="delete-confirmation-dialog">Eliminar Intel</DialogTitle>
        <DialogContent>
          <Typography>¿Está seguro de que desea eliminar este Intel?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete} color="secondary">
            Cancelar
          </Button>
          <Button onClick={confirmDelete} color="primary" variant="contained">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RechercheIntel;