import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Tabs, 
  Tab, 
  Box, 
  Avatar,
  Typography,
  Chip,
  Paper,
  IconButton,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Switch,
  FormControlLabel,
  Grid,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Drawer,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Divider,
  Fab, CircularProgress,Alert
} from '@mui/material';
import {
  ArrowBack,
  Edit as EditIcon,
  Close as CloseIcon,
  Save as SaveIcon,
  PhotoCamera,
  Image as ImageIcon,
  Edit,
  Camera,
  Photo
} from '@mui/icons-material';
import axios from 'axios';
import InformeTab from '../components/InformeTab';
import IntelTab from '../components/IntelTab';
import TechnicaTab from '../components/TechnicaTab';
import PDPTab from '../components/PDPTab';
import { OpenInNew } from '@mui/icons-material';

// Utils for dates
const formatDateForDisplay = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const formatDateForInput = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toISOString().split('T')[0];
};

const formatDateForBackend = (input) => {
  if (!input || input.includes('T')) return input;
  const [year, month, day] = input.split('-');
  if (year && month && day) {
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  }
  return input;
};

// Photo capture component
const PhotoCapture = ({ onCapture, onClose }) => {
  const handlePhotoCapture = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Resize the image before upload
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          // Max dimension
          const MAX_DIMENSION = 800;

          if (width > height && width > MAX_DIMENSION) {
            height = height * (MAX_DIMENSION / width);
            width = MAX_DIMENSION;
          } else if (height > MAX_DIMENSION) {
            width = width * (MAX_DIMENSION / height);
            height = MAX_DIMENSION;
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            const resizedFile = new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now()
            });
            onCapture(resizedFile);
          }, 'image/jpeg', 0.8);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box>
      <input
        accept="image/*"
        id="photo-capture"
        type="file"
        capture="environment"
        onChange={handlePhotoCapture}
        style={{ display: 'none' }}
      />
      <label htmlFor="photo-capture">
        <Button
          variant="contained"
          component="span"
          startIcon={<PhotoCamera />}
          fullWidth
        >
          Tomar foto
        </Button>
      </label>
    </Box>
  );
};

// Photo selection from library component
const PhotoSelection = ({ onSelect, onClose }) => {
  const handlePhotoSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Same resize logic as PhotoCapture
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          const MAX_DIMENSION = 800;

          if (width > height && width > MAX_DIMENSION) {
            height = height * (MAX_DIMENSION / width);
            width = MAX_DIMENSION;
          } else if (height > MAX_DIMENSION) {
            width = width * (MAX_DIMENSION / height);
            height = MAX_DIMENSION;
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            const resizedFile = new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now()
            });
            onSelect(resizedFile);
          }, 'image/jpeg', 0.8);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box>
      <input
        accept="image/*"
        id="photo-select"
        type="file"
        onChange={handlePhotoSelect}
        style={{ display: 'none' }}
      />
      <label htmlFor="photo-select">
        <Button
          variant="contained"
          component="span"
          startIcon={<ImageIcon />}
          fullWidth
        >
          Seleccionar foto
        </Button>
      </label>
    </Box>
  );
};

// Mobile drawer menu component
const MobileDrawerMenu = ({ 
  open, 
  onClose, 
  sections, 
  activeSection, 
  onSectionChange 
}) => {
  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: '80%',
          maxWidth: 360
        }
      }}
    >
      <Box sx={{ width: '100%' }}>
        <List>
          <ListItem>
            <Typography variant="h6">Secciones</Typography>
          </ListItem>
          <Divider />
          {sections.map((section, index) => (
            <ListItem key={section.name} disablePadding>
              <ListItemButton
                selected={activeSection === index}
                onClick={() => {
                  onSectionChange(index);
                  onClose();
                }}
              >
                <ListItemIcon>
                  {section.icon}
                </ListItemIcon>
                <ListItemText primary={section.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

// Mobile profile header component
const MobileProfileHeader = ({
  player,
  avatarUrl,
  isEditing,
  onEditPhoto,
  onBack
}) => {
  return (
    <AppBar position="static" color="default" elevation={0}>
      <Toolbar>
        <IconButton edge="start" onClick={onBack}>
          <ArrowBack />
        </IconButton>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          width: '100%',
          py: 2
        }}>
          <Box sx={{ position: 'relative' }}>
            <Avatar
              src={avatarUrl}
              alt={player?.nom}
              sx={{ 
                width: 120, 
                height: 120,
                border: '3px solid white',
                boxShadow: 1
              }}
            />
            {isEditing && (
              <IconButton
                sx={{
                  position: 'absolute',
                  bottom: -10,
                  right: -10,
                  backgroundColor: 'background.paper',
                  boxShadow: 1,
                  '&:hover': { backgroundColor: 'background.paper' }
                }}
                onClick={onEditPhoto}
              >
                <Edit />
              </IconButton>
            )}
          </Box>
          <Typography variant="h6" sx={{ mt: 2 }}>
            {player?.nom}
          </Typography>
          {player?.type && (
            <Chip
              label={player.type === 'cantera' ? "Cantera" : "Pro"}
              sx={{
                backgroundColor: player.type === 'cantera' ? '#FF8C00' : '#007FFF',
                color: '#fff',
                mt: 1
              }}
            />
          )}
          {player?.url && (
  <Button
    variant="outlined" 
    startIcon={<OpenInNew />}
    href={player.url}
    target="_blank"
    rel="noopener noreferrer"
    sx={{ mt: 1, backgroundColor: 'white' }}
  >
    Ficha Eurobasket
  </Button>
)}
          {player?.groupes && player.groupes.length > 0 && (
            <Box sx={{ 
              display: 'flex', 
              flexWrap: 'wrap', 
              gap: 0.5,
              justifyContent: 'center',
              mt: 1 
            }}>
              {player.groupes.map((group) => (
                <Chip
                  key={group.id}
                  label={group.libelle}
                  size="small"
                  sx={{
                    backgroundColor: group.code_couleur || 'grey',
                    color: '#fff',
                  }}
                />
              ))}
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

// Mobile photo edit dialog
const PhotoEditDialog = ({
  open,
  onClose,
  onCapture,
  onSelect
}) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        Cambiar foto
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 1 }}>
          <PhotoCapture onCapture={(file) => {
            onCapture(file);
            onClose();
          }} />
          <PhotoSelection onSelect={(file) => {
            onSelect(file);
            onClose();
          }} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  );
};

// Mobile section component
const MobileSection = ({
  title,
  isEditing,
  children
}) => {
  return (
    <Paper 
      elevation={0} 
      sx={{ 
        mb: 2,
        backgroundColor: 'background.default'
      }}
    >
      <Typography 
        variant="subtitle1" 
        sx={{ 
          fontWeight: 'medium',
          px: 2,
          py: 1,
          backgroundColor: 'background.paper'
        }}
      >
        {title}
      </Typography>
      <Box sx={{ p: 2 }}>
        {children}
      </Box>
    </Paper>
  );
};
const PlayerProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;

  // State
  const [player, setPlayer] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editablePlayer, setEditablePlayer] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [allGroups, setAllGroups] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [avatarTimestamp, setAvatarTimestamp] = useState(Date.now());
  const [openPhotoDialog, setOpenPhotoDialog] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedImagePreview, setSelectedImagePreview] = useState(null);

  const [hasInformeData, setHasInformeData] = useState(false);
  const [hasIntelData, setHasIntelData] = useState(false);
  const [hasTechnicaData, setHasTechnicaData] = useState(false);
  const [hasPDPData, setHasPDPData] = useState(false);

// Effects
useEffect(() => {
  const fetchPlayer = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${backendUrl}/api/players/${id}?section=${section}`);

      const mappedPlayer = {
        ...response.data,
        dateNaissance: response.data.date_naissance,
        nacionalidad: response.data.nationalite,
        peso: response.data.poids,
        rolOfensivo: response.data.offensive_role,
        rolDefensivo: response.data.defensive_role,
        pasaporte: response.data.passport,
        estatus: response.data.statut,
        equipo: response.data.team,
        liga1: response.data.league_1,
        liga2: response.data.league_2,
        equipoPreDraft: response.data.pre_draft_team,
        añoDraft: response.data.draft_year,
        posicionDraft: response.data.draft_pick,
        grado: response.data.grade,
        experienciaAcb: response.data.acb_experience,
        agencia: response.data.agency,
        comparacionPro: response.data.pro_comparaison,
        comportamiento: response.data.flag_behaviour,
        rangoPrecios: response.data.price_range,
        contrato: response.data.contract,
        jeJi: response.data.je_ji === "1",
        seleccionJeJi: response.data.selection_je_ji === "1",
      };

      setPlayer(mappedPlayer);
      setEditablePlayer(mappedPlayer);
      setError(null);

    } catch (err) {
      setError(err.response?.data?.message || 'Error al cargar los datos del jugador');
      console.error('Error fetching player:', err);
    } finally {
      setIsLoading(false);
    }
  };

  if (id) fetchPlayer();
}, [id, backendUrl, section]);

useEffect(() => {
  const fetchData = async () => {
    if (player) {
      try {
        const [informeRes, intelRes, technicaRes, pdpRes] = await Promise.all([
          axios.get(`${backendUrl}/api/informe`, { params: { playerId: player.id, section } }),
          axios.get(`${backendUrl}/api/intel`, { params: { playerId: player.id, section } }),
          axios.get(`${backendUrl}/api/technique`, { params: { playerId: player.id, section } }),
          axios.get(`${backendUrl}/api/tecnification/player/${player.id}/fiches`, { params: { section } })
        ]);

        setHasInformeData(informeRes.data.length > 0);
        setHasIntelData(intelRes.data.length > 0);
        setHasTechnicaData(technicaRes.data.length > 0);
        setHasPDPData(pdpRes.data.length > 0);
      } catch (error) {
        console.error("Error al recuperar los datos:", error);
      }
    }
  };

  fetchData();
}, [player, section, backendUrl]);

useEffect(() => {
  const fetchGroups = async () => {
    if (player && editablePlayer.type === 'cantera') {
      try {
        const response = await axios.get(`${backendUrl}/api/groups`, { params: { section } });
        setAllGroups(response.data);
      } catch (error) {
        console.error("Error al recuperar grupos:", error);
      }
    }
  };

  fetchGroups();
}, [player, editablePlayer.type, section, backendUrl]);

// Handlers
const handleChange = (e) => {
  const { name, value, checked, type } = e.target;
  const fieldValue = type === 'checkbox' ? checked : value;
  setEditablePlayer((prev) => ({ ...prev, [name]: fieldValue }));
};

const handlePhotoCapture = (file) => {
  setSelectedImage(file);
  setSelectedImagePreview(URL.createObjectURL(file));
  setOpenPhotoDialog(false);
};

const handlePhotoSelect = (file) => {
  setSelectedImage(file);
  setSelectedImagePreview(URL.createObjectURL(file));
  setOpenPhotoDialog(false);
};

const handleGroupChange = (e, group) => {
  setEditablePlayer((prev) => {
    const isGroupSelected = prev.groupes.some(g => g.id === group.id);
    const updatedGroups = isGroupSelected
      ? prev.groupes.filter(g => g.id !== group.id)
      : [...prev.groupes, group];
    return { ...prev, groupes: updatedGroups };
  });
};

const handleUpdate = async () => {
  const dateNaissanceFormatted = formatDateForBackend(editablePlayer.dateNaissance);
  const anneeNaissanceFormatted = new Date(dateNaissanceFormatted).getFullYear();
  const formData = new FormData();

  // Map frontend field names to backend field names
  const fieldMappings = {
    'nom': 'nom',
    'annee_naissance': 'annee_naissance',
    'dateNaissance': 'date_naissance',
    'taille': 'taille',
    'poste': 'poste',
    'type': 'type',
    'peso': 'poids',
    'nacionalidad': 'nationalite',
    'jeJi': 'je_ji',
    'seleccionJeJi': 'selection_je_ji',
    'mano': 'mano',
    'rolOfensivo': 'offensive_role',
    'rolDefensivo': 'defensive_role',
    'pasaporte': 'passport',
    'estatus': 'statut',
    'equipo': 'team',
    'liga1': 'league_1',
    'liga2': 'league_2',
    'equipoPreDraft': 'pre_draft_team',
    'añoDraft': 'draft_year',
    'posicionDraft': 'draft_pick',
    'grado': 'grade',
    'experienciaAcb': 'acb_experience',
    'nivel': 'level',
    'proyeccion': 'proyeccion',
    'agencia': 'agency',
    'comparacionPro': 'pro_comparaison',
    'comportamiento': 'flag_behaviour',
    'rangoPrecios': 'price_range',
    'contrato': 'contract',
    'url': 'url'
  };

  // Append mapped fields to formData
  Object.entries(fieldMappings).forEach(([frontendKey, backendKey]) => {
    let value = editablePlayer[frontendKey];
    if (value === undefined || value === null) {
      value = '';
    } else if (frontendKey === 'jeJi' || frontendKey === 'seleccionJeJi') {
      value = value ? 1 : 0;
    } else if (frontendKey === 'dateNaissance') {
      value = dateNaissanceFormatted;
    } else if (frontendKey === 'annee_naissance') {
      value = anneeNaissanceFormatted;
    }
    formData.append(backendKey, value);
  });

  // Handle 'groupes' separately
  if (editablePlayer.groupes) {
    formData.append('groupes', JSON.stringify(editablePlayer.groupes.map(g => g.id)));
  }

  if (selectedImage) formData.append('avatar', selectedImage);

  try {
    await axios.put(
      `${backendUrl}/api/players/${editablePlayer.id}?section=${section}`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );

    // Mettre à jour l'état 'player' avec les nouvelles données
    setPlayer({
      ...editablePlayer,
      date_naissance: dateNaissanceFormatted,
      annee_naissance: anneeNaissanceFormatted
    });

    setIsEditing(false);
    setAvatarTimestamp(Date.now());
    if (isMobile) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  } catch (error) {
    setErrorMessage(error.response?.data?.message || 'Error al actualizar el jugador');
    console.error('Update error:', error);
  }
};


// Define available sections based on data
const sections = [
  {
    id: 'info',
    name: 'Información',
    icon: <EditIcon />,
    available: true
  },
  {
    id: 'tecnica',
    name: 'Técnica',
    icon: <EditIcon />,
    available: hasTechnicaData
  },
  {
    id: 'informe', 
    name: 'Informe',
    icon: <EditIcon />,
    available: hasInformeData
  },
  {
    id: 'intel',
    name: 'Intel', 
    icon: <EditIcon />,
    available: hasIntelData
  },
  {
    id: 'pdp',
    name: 'PDP',
    icon: <EditIcon />,
    available: hasPDPData
  }
].filter(section => section.available);

// Fonction pour obtenir la section courante
const getCurrentSection = () => {
  return sections[activeTab]?.id || 'info';
};

const renderBasicInfo = () => (
  <MobileSection title="Información Básica">
    {isEditing ? (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Nombre"
            name="nom"
            value={editablePlayer.nom || ''}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Tipo</InputLabel>
            <Select
              name="type"
              value={editablePlayer.type || ''}
              onChange={handleChange}
              label="Tipo"
            >
              <MenuItem value="cantera">Cantera</MenuItem>
              <MenuItem value="pro">Pro</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Puesto</InputLabel>
            <Select
              name="poste"
              value={editablePlayer.poste || ''}
              onChange={handleChange}
              label="Puesto"
            >
              {['base', 'alerio', 'pivot', 'escolta', 'ala-pivot'].map((pos) => (
                <MenuItem key={pos} value={pos}>{pos}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Fecha de Nacimiento"
            name="dateNaissance"
            type="date"
            value={formatDateForInput(editablePlayer.dateNaissance)}
            onChange={(e) => setEditablePlayer((prev) => ({ 
              ...prev, 
              dateNaissance: e.target.value 
            }))}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Altura (cm)"
            name="taille"
            type="number"
            value={editablePlayer.taille || ''}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Peso (kg)"
            name="peso"
            type="number"
            value={editablePlayer.peso || ''}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Nacionalidad"
            name="nacionalidad"
            value={editablePlayer.nacionalidad || ''}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
      </Grid>
    ) : (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {player.poste && (
          <Typography><strong>Puesto:</strong> {player.poste}</Typography>
        )}
        {player.dateNaissance && (
          <Typography>
            <strong>Fecha de Nacimiento:</strong> {formatDateForDisplay(player.dateNaissance)}
          </Typography>
        )}
        {player.taille && (
          <Typography><strong>Altura:</strong> {player.taille} cm</Typography>
        )}
        {player.peso && (
          <Typography><strong>Peso:</strong> {player.peso} kg</Typography>
        )}
        {player.nacionalidad && (
          <Typography><strong>Nacionalidad:</strong> {player.nacionalidad}</Typography>
        )}
      </Box>
    )}
  </MobileSection>
);

const renderCharacteristics = () => (
  <MobileSection title="Características">
    {isEditing ? (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Mano Dominante</InputLabel>
            <Select
              name="mano"
              value={editablePlayer.mano || ''}
              onChange={handleChange}
              label="Mano Dominante"
            >
              <MenuItem value="derecha">Derecha</MenuItem>
              <MenuItem value="izquierda">Izquierda</MenuItem>
              <MenuItem value="ambas">Ambas</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Rol Ofensivo"
            name="rolOfensivo"
            value={editablePlayer.rolOfensivo || ''}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Rol Defensivo"
            name="rolDefensivo"
            value={editablePlayer.rolDefensivo || ''}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Nivel"
            name="nivel"
            value={editablePlayer.nivel || ''}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Proyección</InputLabel>
            <Select
              name="proyeccion"
              value={editablePlayer.proyeccion || ''}
              onChange={handleChange}
              label="Proyección"
            >
              {[
                "BOTTOM BCL/EUROCUP (60-65)",
                "EL MID-LEVEL (75-80)",
                "EL TOP (80-90)",
                "MID BCL/EUROCUP (65-70)",
                "MID LEB (55-60)",
                "NBA ALL-STAR (95-100)",
                "NBA ROTATION (85-90)",
                "TOP BCL/EUROCUP (70-75)"
              ].map(option => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    ) : (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {player.mano && (
          <Typography><strong>Mano Dominante:</strong> {player.mano}</Typography>
        )}
        {player.rolOfensivo && (
          <Typography><strong>Rol Ofensivo:</strong> {player.rolOfensivo}</Typography>
        )}
        {player.rolDefensivo && (
          <Typography><strong>Rol Defensivo:</strong> {player.rolDefensivo}</Typography>
        )}
        {player.nivel && (
          <Typography><strong>Nivel:</strong> {player.nivel}</Typography>
        )}
        {player.proyeccion && (
          <Typography><strong>Proyección:</strong> {player.proyeccion}</Typography>
        )}
      </Box>
    )}
  </MobileSection>
);

const renderAdminInfo = () => (
  <MobileSection title="Información Administrativa">
    {isEditing ? (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Pasaporte"
            name="pasaporte"
            value={editablePlayer.pasaporte || ''}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Estatus"
            name="estatus"
            value={editablePlayer.estatus || ''}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Equipo Actual"
            name="equipo"
            value={editablePlayer.equipo || ''}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Liga Principal"
            name="liga1"
            value={editablePlayer.liga1 || ''}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Liga Secundaria"
            name="liga2"
            value={editablePlayer.liga2 || ''}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
      </Grid>
    ) : (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {player.pasaporte && (
          <Typography><strong>Pasaporte:</strong> {player.pasaporte}</Typography>
        )}
        {player.estatus && (
          <Typography><strong>Estatus:</strong> {player.estatus}</Typography>
        )}
        {player.equipo && (
          <Typography><strong>Equipo Actual:</strong> {player.equipo}</Typography>
        )}
        {player.liga1 && (
          <Typography><strong>Liga Principal:</strong> {player.liga1}</Typography>
        )}
        {player.liga2 && (
          <Typography><strong>Liga Secundaria:</strong> {player.liga2}</Typography>
        )}
      </Box>
    )}
  </MobileSection>
);

const renderDraftInfo = () => (
  <MobileSection title="Información del Draft">
    {isEditing ? (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Equipo Pre-Draft"
            name="equipoPreDraft"
            value={editablePlayer.equipoPreDraft || ''}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Año del Draft"
            name="añoDraft"
            type="number"
            value={editablePlayer.añoDraft || ''}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Posición en el Draft"
            name="posicionDraft"
            value={editablePlayer.posicionDraft || ''}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Grado"
            name="grado"
            value={editablePlayer.grado || ''}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
      </Grid>
    ) : (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {player.equipoPreDraft && (
          <Typography><strong>Equipo Pre-Draft:</strong> {player.equipoPreDraft}</Typography>
        )}
        {player.añoDraft && (
          <Typography><strong>Año del Draft:</strong> {player.añoDraft}</Typography>
        )}
        {player.posicionDraft && (
          <Typography><strong>Posición en el Draft:</strong> {player.posicionDraft}</Typography>
        )}
        {player.grado && (
          <Typography><strong>Grado:</strong> {player.grado}</Typography>
        )}
      </Box>
    )}
  </MobileSection>
);

const renderProfessionalInfo = () => (
  <MobileSection title="Información Profesional">
    {isEditing ? (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Experiencia ACB"
            name="experienciaAcb"
            value={editablePlayer.experienciaAcb || ''}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Agencia"
            name="agencia"
            value={editablePlayer.agencia || ''}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Comparación Pro"
            name="comparacionPro"
            value={editablePlayer.comparacionPro || ''}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Comportamiento"
            name="comportamiento"
            value={editablePlayer.comportamiento || ''}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Rango de Precios"
            name="rangoPrecios"
            value={editablePlayer.rangoPrecios || ''}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Contrato"
            name="contrato"
            value={editablePlayer.contrato || ''}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
      </Grid>
    ) : (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {player.experienciaAcb && (
          <Typography><strong>Experiencia ACB:</strong> {player.experienciaAcb}</Typography>
        )}
        {player.agencia && (
          <Typography><strong>Agencia:</strong> {player.agencia}</Typography>
        )}
        {player.comparacionPro && (
          <Typography><strong>Comparación Pro:</strong> {player.comparacionPro}</Typography>
        )}
        {player.comportamiento && (
          <Typography><strong>Comportamiento:</strong> {player.comportamiento}</Typography>
        )}
        {player.rangoPrecios && (
          <Typography><strong>Rango de Precios:</strong> {player.rangoPrecios}</Typography>
        )}
        {player.contrato && (
          <Typography><strong>Contrato:</strong> {player.contrato}</Typography>
        )}
      </Box>
    )}
  </MobileSection>
);

const renderAdditionalInfo = () => (
  <MobileSection title="Información Adicional">
    {isEditing ? (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="URL EuroBasket"
            name="url"
            value={editablePlayer.url || ''}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={editablePlayer.jeJi || false}
                onChange={(e) => setEditablePlayer((prev) => ({
                  ...prev,
                  jeJi: e.target.checked
                }))}
                name="jeJi"
              />
            }
            label="JE/JI"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={editablePlayer.seleccionJeJi || false}
                onChange={(e) => setEditablePlayer((prev) => ({
                  ...prev,
                  seleccionJeJi: e.target.checked
                }))}
                name="seleccionJeJi"
              />
            }
            label="Selección JE/JI"
          />
        </Grid>
      </Grid>
    ) : (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {(player.jeJi === true || player.jeJi === false) && (
          <Typography><strong>JE/JI:</strong> {player.jeJi ? 'Sí' : 'No'}</Typography>
        )}
        {(player.seleccionJeJi === true || player.seleccionJeJi === false) && (
          <Typography><strong>Selección JE/JI:</strong> {player.seleccionJeJi ? 'Sí' : 'No'}</Typography>
        )}
      </Box>
    )}
  </MobileSection>
);

const renderGroups = () => {
  if (player?.type !== 'cantera') return null;

  return (
    <MobileSection title="Grupos">
      {isEditing ? (
        <FormControl fullWidth>
          <InputLabel>Grupos</InputLabel>
          <Select
            multiple
            value={editablePlayer.groupes || []}
            renderValue={(selected) => selected.map(group => group.libelle).join(', ')}
          >
            {allGroups.map((group) => (
              <MenuItem key={group.id} value={group}>
                <Checkbox
                  checked={editablePlayer.groupes.some(g => g.id === group.id)}
                  onChange={(e) => handleGroupChange(e, group)}
                  style={{ color: group.code_couleur || '#000' }}
                />
                <ListItemText primary={group.libelle} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {player.groupes?.length > 0 ? (
            player.groupes.map((group) => (
              <Chip
                key={group.id}
                label={group.libelle}
                sx={{
                  backgroundColor: group.code_couleur || 'grey',
                  color: '#fff',
                }}
              />
            ))
          ) : (
            <Typography>No hay grupos asignados</Typography>
          )}
        </Box>
      )}
    </MobileSection>
  );
};

// Main render
if (isLoading) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
      <CircularProgress />
    </Box>
  );
}

if (error || !player) {
  return (
    <Alert severity="error" sx={{ m: 2 }}>
      {error || 'Error al cargar el jugador'}
    </Alert>
  );
}

if (isMobile) {
  return (
    <Box sx={{ pb: 8, minHeight: '100vh', backgroundColor: '#f5f5f5' }}>
      <MobileProfileHeader
        player={player}
        avatarUrl={selectedImagePreview || `${mediaUrl}/photos/${player.id}.png?${avatarTimestamp}`}
        isEditing={isEditing}
        onEditPhoto={() => setOpenPhotoDialog(true)}
        onBack={() => navigate(-1)}
      />

<Box sx={{ px: 2, py: 3 }}>
{(() => {
  const currentSection = getCurrentSection();
  switch (currentSection) {
    case 'info':
      return (
        <>
          {renderBasicInfo()}
          {renderCharacteristics()}
          {renderAdminInfo()}
          {renderDraftInfo()}
          {renderProfessionalInfo()}
          {renderAdditionalInfo()}
          {renderGroups()}
        </>
      );
    case 'tecnica':
      return (
        <TechnicaTab
          playerId={player.id}
          dateNaissance={player.dateNaissance}
          nom={player.nom}
          onDataLoaded={setHasTechnicaData}
        />
      );
    case 'informe':
      return (
        <InformeTab
          playerId={player.id}
          onDataLoaded={setHasInformeData}
        />
      );
    case 'intel':
      return (
        <IntelTab
          playerId={player.id}
          onDataLoaded={setHasIntelData}
        />
      );
    case 'pdp':
      return (
        <PDPTab
          playerId={player.id}
          onDataLoaded={setHasPDPData}
        />
      );
    default:
      return null;
  }
})()}
</Box>

      <PhotoEditDialog
        open={openPhotoDialog}
        onClose={() => setOpenPhotoDialog(false)}
        onCapture={handlePhotoCapture}
        onSelect={handlePhotoSelect}
      />

      <MobileDrawerMenu
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        sections={sections}
        activeSection={activeTab}
        onSectionChange={setActiveTab}
      />

{isMobile && (
  <Box sx={{ position: 'fixed', bottom: 10, left: 0, right: 0, zIndex: theme.zIndex.appBar }}>
    <Paper elevation={3}>
    <Tabs
  value={activeTab}
  onChange={(e, newValue) => setActiveTab(newValue)}
  variant="scrollable"
  scrollButtons="auto"
  allowScrollButtonsMobile
  sx={{ backgroundColor: 'background.paper' }}
>
  {sections.map((section) => (
    <Tab key={section.id} label={section.name} />
  ))}
</Tabs>
    </Paper>
  </Box>
)}

      {/* Bottom AppBar for edit button - Only show on Information tab */}
{activeTab === 0 && (
  <AppBar 
    position="fixed" 
    color="primary" 
    sx={{ 
      top: 'auto', 
      bottom: 58,
      backgroundColor: 'transparent',  // Fond transparent
      boxShadow: 'none'  // Pas d'ombre
    }}
  >
    <Toolbar>
      <Box sx={{ flexGrow: 1 }}>
        
        <Button 
          variant="contained"
          onClick={() => {
            if (isEditing) {
              handleUpdate();
            } else {
              setIsEditing(true);
            }
          }}
          fullWidth
        >
          {isEditing ? 'Guardar' : 'Modificar'}
        </Button>
      </Box>
    </Toolbar>
  </AppBar>
)}
    </Box>
  );
}

// Desktop render
return (
  <Box sx={{ display: 'flex', minHeight: '100vh' }}>
    <Box sx={{ width: 300, pr: 2 }}>
      <Paper sx={{ p: 3, mb: 2, textAlign: 'center' }}>
        <Avatar
          src={selectedImagePreview || `${mediaUrl}/photos/${player.id}.png?${avatarTimestamp}`}
          alt={player.nom}
          sx={{ width: 200, height: 200, mx: 'auto', mb: 2 }}
          onClick={() => isEditing && setOpenPhotoDialog(true)}
        />
        <Typography variant="h6" gutterBottom>{player.nom}</Typography>
        <Box sx={{ mb: 2 }}>
          <Chip
            label={player.type === 'cantera' ? "Cantera" : "Pro"}
            sx={{
              backgroundColor: player.type === 'cantera' ? '#FF8C00' : '#007FFF',
              color: '#fff'
            }}
          />
        </Box>
        {player?.url && (
  <Button
    variant="outlined"
    startIcon={<OpenInNew />}
    href={player.url}
    target="_blank"
    rel="noopener noreferrer"
    sx={{ mb: 2 }}
    fullWidth
  >
    Ficha Eurobasket
  </Button>
)}
        {activeTab === 0 && (
          
    <Button
      variant="contained"
      onClick={() => {
        if (isEditing) {
          handleUpdate();
        } else {
          setIsEditing(true);
        }
      }}
      fullWidth
    >
      {isEditing ? 'Guardar' : 'Modificar'}
    </Button>
  )}
      </Paper>

      {sections.length > 1 && (
        <Paper sx={{ p: 1 }}>
          <Tabs
            value={activeTab}
            onChange={(e, newValue) => setActiveTab(newValue)}
            orientation="vertical"
            variant="scrollable"
          >
            {sections.map((section, index) => (
              <Tab key={section.name} label={section.name} />
            ))}
          </Tabs>
        </Paper>
      )}
    </Box>

    <Box sx={{ flex: 1, p: 2 }}>
  <Paper sx={{ p: 3 }}>
    {(() => {
      const currentSection = getCurrentSection();
      switch (currentSection) {
        case 'info':
          return (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {renderBasicInfo()}
              </Grid>
              <Grid item xs={12}>
                {renderCharacteristics()}
              </Grid>
              <Grid item xs={12}>
                {renderAdminInfo()}
              </Grid>
              <Grid item xs={12}>
                {renderDraftInfo()}
              </Grid>
              <Grid item xs={12}>
                {renderProfessionalInfo()}
              </Grid>
              <Grid item xs={12}>
                {renderAdditionalInfo()}
              </Grid>
              <Grid item xs={12}>
                {renderGroups()}
              </Grid>
            </Grid>
          );
        case 'tecnica':
          return (
            <TechnicaTab
              playerId={player.id}
              dateNaissance={player.dateNaissance}
              nom={player.nom}
              onDataLoaded={setHasTechnicaData}
            />
          );
        case 'informe':
          return (
            <InformeTab
              playerId={player.id}
              onDataLoaded={setHasInformeData}
            />
          );
        case 'intel':
          return (
            <IntelTab
              playerId={player.id}
              onDataLoaded={setHasIntelData}
            />
          );
        case 'pdp':
          return (
            <PDPTab
              playerId={player.id}
              onDataLoaded={setHasPDPData}
            />
          );
        default:
          return null;
      }
    })()}
  </Paper>
</Box>

    <PhotoEditDialog
      open={openPhotoDialog}
      onClose={() => setOpenPhotoDialog(false)}
      onCapture={handlePhotoCapture}
      onSelect={handlePhotoSelect}
    />
  </Box>
);
};

export default PlayerProfile;