// src/BigBoardContext.js
import React, { createContext, useState } from 'react';

export const BigBoardContext = createContext();

export const BigBoardProvider = ({ children }) => {
  const [bigBoardPlayers, setBigBoardPlayers] = useState([]);

  return (
    <BigBoardContext.Provider value={{ bigBoardPlayers, setBigBoardPlayers }}>
      {children}
    </BigBoardContext.Provider>
  );
};
