import React, { useState, useEffect } from 'react';
import {
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Box,
  Collapse,
  useMediaQuery,
  Menu,
  MenuItem,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LabelIcon from '@mui/icons-material/Label';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import SecurityIcon from '@mui/icons-material/Security';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import { Outlet, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import GroupsIcon from '@mui/icons-material/Groups';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import HubIcon from '@mui/icons-material/Hub';
import VisibilityIcon from '@mui/icons-material/Visibility';

function Layout({ onLogout }) {
  const [user, setUser] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [crearOpen, setCrearOpen] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    setMenuOpen(isLargeScreen);
    const userData = JSON.parse(localStorage.getItem('user'));
    if (userData) {
      setUser(userData);
    } else {
      navigate('/login');
    }
  }, [navigate, isLargeScreen]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const getMenuItems = () => {
    if (!user) return [];
    switch (user.role) {
      case 'super admin':
        return ['Panel de control', 'Jugador@s', 'Proyección VBC', 'Informe', 'Intel', 'Big Board','Seguimiento', 'Crear', 'Configuración'];
      case 'admin pro':
        return ['Panel de control', 'Jugador@s', 'Proyección VBC', 'Informe', 'Intel', 'Big Board','Seguimiento', 'Crear', 'Configuración'];
      case 'admin cantera':
        return ['Panel de control', 'Jugador@s', 'Proyección VBC', 'Informe', 'Intel', 'Big Board','Seguimiento', 'Crear', 'Configuración'];
      case 'entrenador':
        return [ 'Jugador@s',  'Informe', 'Intel',  'Crear'];
      default:
        return [];
    }
  };

  const toggleSettings = () => {
    setSettingsOpen(!settingsOpen);
  };

  const toggleCrear = () => {
    setCrearOpen(!crearOpen);
  };

  const handleMenuItemClick = (item, type) => {
    setActiveMenuItem(item);
    switch (item) {
      case 'Configuración':
        break;
      case 'Usuarios':
        navigate('/usuarios');
        break;
      case 'Grupos':
        navigate('/grupos');
        break;
      case 'Tags':
        navigate('/tags');
        break;
      case 'Jugador@s':
        navigate('/jugadores');
        break;
      case 'Proyección VBC':
        navigate('/tecnification');
        break;
      case 'Crear Jugador':
        navigate('/crear-jugador');
        break;
      case 'Big Board':
        if (type === 'search') {
          navigate('/buscar-bigboard');
        } else {
          navigate('/big-board');
        }
        break;
        case 'Seguimiento':
          if (type === 'search') {
            navigate('/buscar-seguimiento');
          } else {
            navigate('/seguimiento');
          }
          break; 
      case 'Crear Ficha':
        navigate('/crear-ficha');
        break;
      case 'Informe':
        navigate('/buscar-informe');
        break;
      case 'Intel':
        navigate('/buscar-intel');
        break;
      case 'Importar':
        navigate('/importar');
        break;
      default:
        navigate(`/${item.toLowerCase().replace(/\s+/g, '-')}`);
    }
  };

  const getAvatarUrl = (userId) => {
    const url = userId
      ? `${process.env.REACT_APP_MEDIA_URL}/users/${userId}.png?${new Date().getTime()}`
      : '/path/to/default-avatar.png';
    return url;
  };

  const handleAvatarClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    handleMenuClose();
    onLogout();
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <AppBar position="fixed" sx={{ zIndex: 1201, backgroundColor: '#F25F29' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <IconButton color="inherit" edge="start" onClick={toggleMenu} aria-label="menu">
              <MenuIcon />
            </IconButton>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              
              <img 
                src="/assets/valenciaapp.png" 
                alt="Valencia App Logo" 
                style={{ 
                  height: '60px',
                  width: 'auto',
                  objectFit: 'contain'
                }} 
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box 
              onClick={handleAvatarClick}
              sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            >
              <span style={{ paddingRight: '10px' }}>
                {user ? `${user.prenom} ${user.nom}` : ''}
              </span>
              <Avatar
                src={getAvatarUrl(user?.id)}
                alt="Avatar"
                sx={{ marginRight: '10px' }}
              />
            </Box>
          </Box>
          <Menu
            id="avatar-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            onClick={(e) => e.stopPropagation()}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{ mt: 1 }}
          >
            <MenuItem onClick={handleLogoutClick}>Desconexión</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <Drawer
        variant={isLargeScreen ? "persistent" : "temporary"}
        anchor="left"
        open={menuOpen}
        onClose={toggleMenu}
        sx={{
          width: 25,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 200,
            boxSizing: 'border-box',
            backgroundColor: '#F25F29',
            color: '#ffffff',
            top: 64,
          },
        }}
      >
        <Toolbar />
        <List>
          {getMenuItems().map((item, index) => {
            if (item === 'Configuración') {
              return (
                <React.Fragment key={index}>
                  <ListItem button onClick={toggleSettings} sx={{
                    color: activeMenuItem === item ? '#FFCC80' : '#ffffff',
                    '&:hover': { color: '#FFCC80' },
                    cursor: 'pointer',
                  }}>
                    <SettingsIcon sx={{ color: activeMenuItem === item ? '#FFCC80' : '#ffffff', mr: 2 }} />
                    <ListItemText primary={item} />
                    {settingsOpen ? <ExpandLessIcon sx={{ color: '#ffffff' }} /> : <ExpandMoreIcon sx={{ color: '#ffffff' }} />}
                  </ListItem>
                  <Collapse in={settingsOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem button sx={{ pl: 4, cursor: 'pointer' }} onClick={() => handleMenuItemClick('Usuarios')}>
                        <GroupIcon sx={{ color: '#ffffff', mr: 2 }} />
                        <ListItemText primary="Usuarios" sx={{ color: '#ffffff' }} />
                      </ListItem>
                      <ListItem button sx={{ pl: 4, cursor: 'pointer' }} onClick={() => handleMenuItemClick('Grupos')}>
                        <HubIcon sx={{ color: '#ffffff', mr: 2 }} />
                        <ListItemText primary="Grupos" sx={{ color: '#ffffff' }} />
                      </ListItem>
                      <ListItem button sx={{ pl: 4, cursor: 'pointer' }} onClick={() => handleMenuItemClick('Tags')}>
                        <LabelIcon sx={{ color: '#ffffff', mr: 2 }} />
                        <ListItemText primary="Tags" sx={{ color: '#ffffff' }} />
                      </ListItem>
                      <ListItem button sx={{ pl: 4, cursor: 'pointer' }} onClick={() => handleMenuItemClick('Importar')}>
                        <FileUploadIcon sx={{ color: '#ffffff', mr: 2 }} />
                        <ListItemText primary="Importar" sx={{ color: '#ffffff' }} />
                      </ListItem>
                    </List>
                  </Collapse>
                </React.Fragment>
              );
            }

            return (
              <React.Fragment key={index}>
                <ListItem 
                  button 
                  onClick={item === 'Crear' ? toggleCrear : () => handleMenuItemClick(item, item === 'Big Board' || item === 'Seguimiento' ? 'search' : null)}
                  sx={{
                    color: activeMenuItem === item ? '#FFCC80' : '#ffffff',
                    '&:hover': { color: '#FFCC80' },
                    cursor: 'pointer',
                  }}
                >
                  {item === 'Panel de control' && <DashboardIcon sx={{ color: activeMenuItem === item ? '#FFCC80' : '#ffffff', mr: 2 }} />}
                  {item === 'Jugador@s' && <GroupsIcon sx={{ color: activeMenuItem === item ? '#FFCC80' : '#ffffff', mr: 2 }} />}
                  {item === 'Proyección VBC' && <SportsBasketballIcon sx={{ color: activeMenuItem === item ? '#FFCC80' : '#ffffff', mr: 2 }} />}
                  {item === 'Big Board' && <ViewKanbanIcon sx={{ color: activeMenuItem === item ? '#FFCC80' : '#ffffff', mr: 2 }} />}
                  {item === 'Seguimiento' && <VisibilityIcon sx={{ color: activeMenuItem === item ? '#FFCC80' : '#ffffff', mr: 2 }} />}
                  {item === 'Informe' && <AssignmentIndIcon sx={{ color: activeMenuItem === item ? '#FFCC80' : '#ffffff', mr: 2 }} />}
                  {item === 'Intel' && <SecurityIcon sx={{ color: activeMenuItem === item ? '#FFCC80' : '#ffffff', mr: 2 }} />}
                  {item === 'Crear' && <AddCircleIcon sx={{ color: activeMenuItem === item ? '#FFCC80' : '#ffffff', mr: 2 }} />}
                  <ListItemText primary={item} />
                  {item === 'Crear' && (crearOpen ? <ExpandLessIcon sx={{ color: '#ffffff' }} /> : <ExpandMoreIcon sx={{ color: '#ffffff' }} />)}
                </ListItem>
                {item === 'Crear' && (
                  <Collapse in={crearOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem button sx={{ pl: 4, cursor: 'pointer' }} onClick={() => handleMenuItemClick('Crear Jugador')}>
                        <PersonIcon sx={{ color: '#ffffff', mr: 2 }} />
                        <ListItemText primary="Jugador" sx={{ color: '#ffffff' }} />
                      </ListItem>
                      <ListItem button sx={{ pl: 4, cursor: 'pointer' }} onClick={() => handleMenuItemClick('Crear Ficha')}>
                        <AssignmentIcon sx={{ color: '#ffffff', mr: 2 }} />
                        <ListItemText primary="Ficha" sx={{ color: '#ffffff' }} />
                      </ListItem>
                      {user.role !== 'entrenador' && (
                        <>
                          <ListItem button sx={{ pl: 4, cursor: 'pointer' }} onClick={() => handleMenuItemClick('Big Board', 'create')}>
                            <ViewKanbanIcon sx={{ color: '#ffffff', mr: 2 }} />
                            <ListItemText primary="Big Board" sx={{ color: '#ffffff' }} />
                          </ListItem>
                          <ListItem button sx={{ pl: 4, cursor: 'pointer' }} onClick={() => handleMenuItemClick('Seguimiento', 'create')}>
                            <VisibilityIcon sx={{ color: '#ffffff', mr: 2 }} />
                            <ListItemText primary="Seguimiento" sx={{ color: '#ffffff' }} />
                          </ListItem>
                        </>
                      )}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            );
          })}
        </List>
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          transition: 'margin 0.3s ease',
          marginLeft: isLargeScreen && menuOpen ? '200px' : '0px',
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}

export default Layout;