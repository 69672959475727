import React, { useState, useEffect } from 'react';
import {
  TextField, Button, MenuItem, Select, FormControl, InputLabel,
  Avatar, Box, Typography, IconButton, Chip, Checkbox, ListItemText, 
  Dialog, DialogTitle, DialogContent, DialogActions, Switch, FormControlLabel, useTheme,
  useMediaQuery, FormHelperText, CircularProgress
} from '@mui/material';
import { PhotoCamera, HelpOutline, Edit } from '@mui/icons-material';
import axios from 'axios';

// Composant pour capturer une photo
const PhotoCapture = ({ onCapture, onClose }) => {
  const handlePhotoCapture = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Redimensionnement de l'image avant l'envoi
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          // Dimension maximale
          const MAX_DIMENSION = 800;

          if (width > height && width > MAX_DIMENSION) {
            height = height * (MAX_DIMENSION / width);
            width = MAX_DIMENSION;
          } else if (height > MAX_DIMENSION) {
            width = width * (MAX_DIMENSION / height);
            height = MAX_DIMENSION;
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            const resizedFile = new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now()
            });
            onCapture(resizedFile);
          }, 'image/jpeg', 0.8);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box>
      <input
        accept="image/*"
        id="photo-capture"
        type="file"
        capture="environment"
        onChange={handlePhotoCapture}
        style={{ display: 'none' }}
      />
      <label htmlFor="photo-capture">
        <Button
          variant="contained"
          component="span"
          startIcon={<PhotoCamera />}
          fullWidth
        >
          Tomar foto
        </Button>
      </label>
    </Box>
  );
};

// Composant pour sélectionner une photo depuis la bibliothèque
const PhotoSelection = ({ onSelect, onClose }) => {
  const handlePhotoSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Même logique de redimensionnement que dans PhotoCapture
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          const MAX_DIMENSION = 800;

          if (width > height && width > MAX_DIMENSION) {
            height = height * (MAX_DIMENSION / width);
            width = MAX_DIMENSION;
          } else if (height > MAX_DIMENSION) {
            width = width * (MAX_DIMENSION / height);
            height = MAX_DIMENSION;
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            const resizedFile = new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now()
            });
            onSelect(resizedFile);
          }, 'image/jpeg', 0.8);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box>
      <input
        accept="image/*"
        id="photo-select"
        type="file"
        onChange={handlePhotoSelect}
        style={{ display: 'none' }}
      />
      <label htmlFor="photo-select">
        <Button
          variant="contained"
          component="span"
          startIcon={<Edit />}
          fullWidth
        >
          Seleccionar foto
        </Button>
      </label>
    </Box>
  );
};

// Composant pour le dialogue de modification de photo
const PhotoEditDialog = ({ open, onClose, onCapture, onSelect }) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        Cambiar foto
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 1 }}>
          <PhotoCapture onCapture={(file) => {
            onCapture(file);
            onClose();
          }} />
          <PhotoSelection onSelect={(file) => {
            onSelect(file);
            onClose();
          }} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  );
};

const CrearJugador = () => {
  // États existants
  const [nombre, setNombre] = useState('');
  const [fechaNacimiento, setFechaNacimiento] = useState('');
  const [altura, setAltura] = useState('');
  const [peso, setPeso] = useState('');
  const [puesto, setPuesto] = useState('');
  const [tipo, setTipo] = useState('');
  const [grupos, setGrupos] = useState([]);
  const [selectedGrupos, setSelectedGrupos] = useState([]);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [tutorialOpen, setTutorialOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  // Nouveaux états
  const [nacionalidad, setNacionalidad] = useState('');
  const [jeJi, setJeJi] = useState(false);
  const [seleccionJeJi, setSeleccionJeJi] = useState(false);
  const [mano, setMano] = useState('');
  const [rolOfensivo, setRolOfensivo] = useState('');
  const [rolDefensivo, setRolDefensivo] = useState('');
  const [pasaporte, setPasaporte] = useState('');
  const [estatus, setEstatus] = useState('');
  const [equipo, setEquipo] = useState('');
  const [liga1, setLiga1] = useState('');
  const [liga2, setLiga2] = useState('');
  const [equipoPreDraft, setEquipoPreDraft] = useState('');
  const [añoDraft, setAñoDraft] = useState('');
  const [posicionDraft, setPosicionDraft] = useState('');
  const [grado, setGrado] = useState('');
  const [experienciaAcb, setExperienciaAcb] = useState('');
  const [nivel, setNivel] = useState('');
  const [proyeccion, setProyeccion] = useState('');
  const [agencia, setAgencia] = useState('');
  const [comparacionPro, setComparacionPro] = useState('');
  const [comportamiento, setComportamiento] = useState('');
  const [rangoPrecios, setRangoPrecios] = useState('');
  const [contrato, setContrato] = useState('');
  const [url, setUrl] = useState('');

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // États pour la gestion de la photo
  const [selectedImage, setSelectedImage] = useState(null);
  const [avatarPreview, setAvatarPreview] = useState(null);
  const [openPhotoDialog, setOpenPhotoDialog] = useState(false);

  // État pour le spinner de chargement
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Calcul automatique de l'année de naissance
  const calcularAñoNacimiento = (fecha) => {
    if (!fecha) return '';
    return new Date(fecha).getFullYear().toString();
  };

  // Fonction pour réinitialiser tous les champs
  const resetForm = () => {
    setNombre('');
    setFechaNacimiento('');
    setAltura('');
    setPeso('');
    setPuesto('');
    setTipo('');
    setSelectedGrupos([]);
    setSelectedImage(null);
    setAvatarPreview(null);
    setNacionalidad('');
    setJeJi(false);
    setSeleccionJeJi(false);
    setMano('');
    setRolOfensivo('');
    setRolDefensivo('');
    setPasaporte('');
    setEstatus('');
    setEquipo('');
    setLiga1('');
    setLiga2('');
    setEquipoPreDraft('');
    setAñoDraft('');
    setPosicionDraft('');
    setGrado('');
    setExperienciaAcb('');
    setNivel('');
    setProyeccion('');
    setAgencia('');
    setComparacionPro('');
    setComportamiento('');
    setRangoPrecios('');
    setContrato('');
    setUrl('');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const fetchGrupos = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/groups`, { params: { section } });
        setGrupos(response.data);
      } catch (error) {
        console.error("Error al obtener los grupos:", error);
      }
    };
    fetchGrupos();
  }, [backendUrl, section]);

  const handleCloseModal = () => {
    setErrorModalOpen(false);
    setErrorMessage('');
  };

  const handleSubmit = async () => {
    // Validation des champs obligatoires
    if (!nombre.trim()) {
      setErrorMessage('El nombre es obligatorio');
      setErrorModalOpen(true);
      return;
    }

    if (!tipo) {
      setErrorMessage('El tipo de jugador es obligatorio');
      setErrorModalOpen(true);
      return;
    }

    if (!fechaNacimiento) {
      setErrorMessage('La fecha de nacimiento es obligatoria');
      setErrorModalOpen(true);
      return;
    }

    if (!altura) {
      setErrorMessage('La altura es obligatoria');
      setErrorModalOpen(true);
      return;
    }

    if (!puesto) {
      setErrorMessage('El puesto es obligatorio');
      setErrorModalOpen(true);
      return;
    }

    if (tipo === 'cantera' && selectedGrupos.length === 0) {
      setErrorMessage('Debe seleccionar al menos un grupo para un jugador de tipo cantera');
      setErrorModalOpen(true);
      return;
    }

    const formData = new FormData();
    formData.append('nom', nombre.trim());
    formData.append('annee_naissance', calcularAñoNacimiento(fechaNacimiento));
    formData.append('date_naissance', fechaNacimiento);
    formData.append('taille', altura || null);
    formData.append('poids', peso || null);
    formData.append('poste', puesto || null);
    formData.append('type', tipo);
    formData.append('section', section);
    formData.append('nationalite', nacionalidad || null);
    formData.append('je_ji', jeJi ? 1 : 0);
    formData.append('selection_je_ji', seleccionJeJi ? 1 : 0);
    formData.append('mano', mano || null);
    formData.append('offensive_role', rolOfensivo || null);
    formData.append('defensive_role', rolDefensivo || null);
    formData.append('passport', pasaporte || null);
    formData.append('statut', estatus || null);
    formData.append('team', equipo || '');
    formData.append('league_1', liga1 || '');
    formData.append('league_2', liga2 || null);
    formData.append('pre_draft_team', equipoPreDraft || null);
    formData.append('draft_year', añoDraft || null);
    formData.append('draft_pick', posicionDraft || null);
    formData.append('grade', grado || null);
    formData.append('acb_experience', experienciaAcb || null);
    formData.append('level', nivel || null);
    formData.append('proyeccion', proyeccion || null);
    formData.append('agency', agencia || null);
    formData.append('pro_comparaison', comparacionPro || null);
    formData.append('flag_behaviour', comportamiento || null);
    formData.append('price_range', rangoPrecios || null);
    formData.append('contract', contrato || null);
    formData.append('url', url || null);
    formData.append('groupes', JSON.stringify(selectedGrupos));
    if (selectedImage) formData.append('avatar', selectedImage);

    setIsSubmitting(true);

    try {
      await axios.post(`${backendUrl}/api/players?section=${section}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setIsSubmitting(false);
      setSuccessModalOpen(true);
      resetForm(); // Réinitialisation du formulaire après création réussie
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Pour un défilement fluide
      });
    } catch (error) {
      setIsSubmitting(false);
      setErrorMessage(error.response?.data?.message || 'Error al crear el jugador');
      setErrorModalOpen(true);
    }
  };

  // Gestion de la capture de photo
  const handlePhotoCapture = (file) => {
    setSelectedImage(file);
    setAvatarPreview(URL.createObjectURL(file));
    setOpenPhotoDialog(false);
  };

  // Gestion de la sélection de photo
  const handlePhotoSelect = (file) => {
    setSelectedImage(file);
    setAvatarPreview(URL.createObjectURL(file));
    setOpenPhotoDialog(false);
  };

  return (
    <Box sx={{ maxWidth: '100%', mx: 'auto', mt: 4, p: 3, border: '1px solid #ddd', borderRadius: '8px' }}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between', // This will push the icon to the right
        mb: 2
      }}>
        <Typography variant="h5">Crear Jugador</Typography>
        <IconButton 
          onClick={() => setTutorialOpen(true)} 
          color="primary"
          sx={{ ml: 2 }} // Add some margin to the left of the icon
        >
          <HelpOutline />
        </IconButton>
      </Box>

      {/* Avatar existant */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <Box sx={{ position: 'relative' }}>
          <Avatar
            sx={{ width: 100, height: 100 }}
            src={avatarPreview}
            alt="Avatar"
          />
          <IconButton
            sx={{
              position: 'absolute',
              bottom: -10,
              right: -10,
              backgroundColor: 'background.paper',
              boxShadow: 1,
              '&:hover': { backgroundColor: 'background.paper' }
            }}
            onClick={() => setOpenPhotoDialog(true)}
          >
            <Edit />
          </IconButton>
        </Box>
      </Box>

      {/* Dialogue pour modifier la photo */}
      <PhotoEditDialog
        open={openPhotoDialog}
        onClose={() => setOpenPhotoDialog(false)}
        onCapture={handlePhotoCapture}
        onSelect={handlePhotoSelect}
      />

      {/* Informations de base */}
      <Typography variant="h6" sx={{ mb: 2 }}>Información Básica</Typography>
      <Box sx={{ 
        display: 'grid', 
        gridTemplateColumns: isSmallScreen ? '1fr' : 'repeat(2, 1fr)', 
        gap: 2 
      }}>
        <TextField
          label="Nombre"
          fullWidth
          value={nombre}
          onChange={(e) => setNombre(e.target.value)}
          error={nombre.trim() === ''}
          helperText={nombre.trim() === '' ? 'El nombre es obligatorio' : ''}
          inputProps={{ style: { textTransform: 'uppercase' } }} 
        />

        <FormControl fullWidth error={!tipo}>
          <InputLabel>Tipo</InputLabel>
          <Select
            value={tipo}
            onChange={(e) => setTipo(e.target.value)}
            label="Tipo"
          >
            <MenuItem value="pro">Pro</MenuItem>
            <MenuItem value="cantera">Cantera</MenuItem>
          </Select>
          {!tipo && (
            <FormHelperText>El tipo de jugador es obligatorio</FormHelperText>
          )}
        </FormControl>

        {tipo === "cantera" && (
          <FormControl fullWidth sx={{ gridColumn: isSmallScreen ? 'auto' : '1 / -1' }} error={tipo === 'cantera' && selectedGrupos.length === 0}>
            <InputLabel>Grupos</InputLabel>
            <Select
              multiple
              value={selectedGrupos}
              onChange={(e) => setSelectedGrupos(e.target.value)}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((id) => {
                    const grupo = grupos.find((g) => g.id === id);
                    return (
                      <Chip
                        key={id}
                        label={grupo ? grupo.libelle : ''}
                        sx={{
                          backgroundColor: grupo ? grupo.code_couleur : 'grey',
                          color: '#fff'
                        }}
                      />
                    );
                  })}
                </Box>
              )}
            >
              {grupos.map((grupo) => (
                <MenuItem key={grupo.id} value={grupo.id}>
                  <Checkbox checked={selectedGrupos.indexOf(grupo.id) > -1} />
                  <ListItemText primary={grupo.libelle} />
                  <Box
                    sx={{
                      width: 16,
                      height: 16,
                      backgroundColor: grupo.code_couleur,
                      borderRadius: '50%',
                      marginLeft: 1,
                    }}
                  />
                </MenuItem>
              ))}
            </Select>
            {tipo === 'cantera' && selectedGrupos.length === 0 && (
              <FormHelperText>Debe seleccionar al menos un grupo</FormHelperText>
            )}
          </FormControl>
        )}

        <TextField
          label="Peso (kg)"
          type="number"
          fullWidth
          value={peso}
          onChange={(e) => setPeso(e.target.value)}
        />
        <TextField
          label="Altura (cm)"
          type="number"
          fullWidth
          value={altura}
          onChange={(e) => setAltura(e.target.value)}
          error={!altura}
          helperText={!altura ? 'La altura es obligatoria' : ''}
        />
        <TextField
          label="Nacionalidad"
          fullWidth
          value={nacionalidad}
          onChange={(e) => setNacionalidad(e.target.value)}
        />
        <TextField
          label="Fecha de nacimiento"
          type="date"
          fullWidth
          InputLabelProps={{ shrink: true }}
          value={fechaNacimiento}
          onChange={(e) => setFechaNacimiento(e.target.value)}
          error={!fechaNacimiento}
          helperText={!fechaNacimiento ? 'La fecha de nacimiento es obligatoria' : ''}
        />
      </Box>

      {/* Caractéristiques du joueur */}
      <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>Características del Jugador</Typography>
      <Box sx={{ 
        display: 'grid', 
        gridTemplateColumns: isSmallScreen ? '1fr' : 'repeat(2, 1fr)', 
        gap: 2 
      }}>
        <FormControl fullWidth error={!puesto}>
          <InputLabel>Puesto</InputLabel>
          <Select
            value={puesto}
            onChange={(e) => setPuesto(e.target.value)}
            label="Puesto"
            error={!puesto}
          >
            <MenuItem value="Base">Base</MenuItem>
            <MenuItem value="Alero">Alero</MenuItem>
            <MenuItem value="Pívot">Pívot</MenuItem>
            <MenuItem value="Escolta">Escolta</MenuItem>
            <MenuItem value="Ala-Pívot">Ala-Pívot</MenuItem>
          </Select>
          {!puesto && (
            <FormHelperText>El puesto es obligatorio</FormHelperText>
          )}
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Mano Dominante</InputLabel>
          <Select
            value={mano}
            onChange={(e) => setMano(e.target.value)}
            label="Mano Dominante"
          >
            <MenuItem value="derecha">Derecha</MenuItem>
            <MenuItem value="izquierda">Izquierda</MenuItem>
            <MenuItem value="ambas">Ambas</MenuItem>
          </Select>
        </FormControl>

        <TextField
          label="Rol Ofensivo"
          fullWidth
          value={rolOfensivo}
          onChange={(e) => setRolOfensivo(e.target.value)}
        />

        <TextField
          label="Rol Defensivo"
          fullWidth
          value={rolDefensivo}
          onChange={(e) => setRolDefensivo(e.target.value)}
        />

        <TextField
          label="Nivel"
          fullWidth
          value={nivel}
          onChange={(e) => setNivel(e.target.value)}
        />

        <FormControl fullWidth>
          <InputLabel>Proyección</InputLabel>
          <Select
            value={proyeccion}
            onChange={(e) => setProyeccion(e.target.value)}
            label="Proyección"
          >
            <MenuItem value="BOTTOM BCL/EUROCUP (60-65)">BOTTOM BCL/EUROCUP (60-65)</MenuItem>
            <MenuItem value="EL MID-LEVEL (75-80)">EL MID-LEVEL (75-80)</MenuItem>
            <MenuItem value="EL TOP (80-90)">EL TOP (80-90)</MenuItem>
            <MenuItem value="MID BCL/EUROCUP (65-70)">MID BCL/EUROCUP (65-70)</MenuItem>
            <MenuItem value="MID LEB (55-60)">MID LEB (55-60)</MenuItem>
            <MenuItem value="NBA ALL-STAR (95-100)">NBA ALL-STAR (95-100)</MenuItem>
            <MenuItem value="NBA ROTATION (85-90)">NBA ROTATION (85-90)</MenuItem>
            <MenuItem value="TOP BCL/EUROCUP (70-75)">TOP BCL/EUROCUP (70-75)</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Información administrativa */}
      <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>Información Administrativa</Typography>
      <Box sx={{ 
        display: 'grid', 
        gridTemplateColumns: isSmallScreen ? '1fr' : 'repeat(2, 1fr)', 
        gap: 2 
      }}>
        <TextField
          label="Pasaporte"
          fullWidth
          value={pasaporte}
          onChange={(e) => setPasaporte(e.target.value)}
        />

        <TextField
          label="Estatus"
          fullWidth
          value={estatus}
          onChange={(e) => setEstatus(e.target.value)}
        />

        <TextField
          label="Equipo Actual"
          fullWidth
          value={equipo}
          onChange={(e) => setEquipo(e.target.value)}
        />

        <TextField
          label="Liga Principal"
          fullWidth
          value={liga1}
          onChange={(e) => setLiga1(e.target.value)}
        />

        <TextField
          label="Liga Secundaria"
          fullWidth
          value={liga2}
          onChange={(e) => setLiga2(e.target.value)}
        />
      </Box>

      {/* Información del Draft */}
      <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>Información del Draft</Typography>
      <Box sx={{ 
        display: 'grid', 
        gridTemplateColumns: isSmallScreen ? '1fr' : 'repeat(2, 1fr)', 
        gap: 2 
      }}>
        <TextField
          label="Equipo Pre-Draft"
          fullWidth
          value={equipoPreDraft}
          onChange={(e) => setEquipoPreDraft(e.target.value)}
        />

        <TextField
          label="Año del Draft"
          type="number"
          fullWidth
          value={añoDraft}
          onChange={(e) => setAñoDraft(e.target.value)}
        />

        <TextField
          label="Posición en el Draft"
          fullWidth
          value={posicionDraft}
          onChange={(e) => setPosicionDraft(e.target.value)}
        />

        <TextField
          label="Grado"
          fullWidth
          value={grado}
          onChange={(e) => setGrado(e.target.value)}
        />
      </Box>

      {/* Información Profesional */}
      <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>Información Profesional</Typography>
      <Box sx={{ 
        display: 'grid', 
        gridTemplateColumns: isSmallScreen ? '1fr' : 'repeat(2, 1fr)', 
        gap: 2 
      }}>
        <TextField
          label="Experiencia ACB"
          fullWidth
          value={experienciaAcb}
          onChange={(e) => setExperienciaAcb(e.target.value)}
        />

        <TextField
          label="Agencia"
          fullWidth
          value={agencia}
          onChange={(e) => setAgencia(e.target.value)}
        />

        <TextField
          label="Comparación Pro"
          fullWidth
          value={comparacionPro}
          onChange={(e) => setComparacionPro(e.target.value)}
        />

        <TextField
          label="Comportamiento"
          fullWidth
          value={comportamiento}
          onChange={(e) => setComportamiento(e.target.value)}
        />

        <TextField
          label="Rango de Precios"
          fullWidth
          value={rangoPrecios}
          onChange={(e) => setRangoPrecios(e.target.value)}
        />

        <TextField
          label="Contrato"
          fullWidth
          value={contrato}
          onChange={(e) => setContrato(e.target.value)}
        />
      </Box>

      {/* Otros campos */}
      <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>Información Adicional</Typography>
      <Box sx={{ 
        display: 'grid', 
        gridTemplateColumns: isSmallScreen ? '1fr' : 'repeat(2, 1fr)', 
        gap: 2 
      }}>
        <TextField
          label="URL EuroBasket"
          fullWidth
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />

        <FormControlLabel
          control={
            <Switch
              checked={jeJi}
              onChange={(e) => setJeJi(e.target.checked)}
            />
          }
          label="JE/JI"
        />

        <FormControlLabel
          control={
            <Switch
              checked={seleccionJeJi}
              onChange={(e) => setSeleccionJeJi(e.target.checked)}
            />
          }
          label="Selección JE/JI"
        />
      </Box>

      {/* Boutons d'action */}
      <Box sx={{ mt: 4, display: 'flex', gap: 2, justifyContent: 'flex-end', alignItems: 'center' }}>
        {isSubmitting && <CircularProgress size={24} />}
        <Button
          variant="outlined"
          color="primary"
          onClick={() => window.history.back()}
          disabled={isSubmitting}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Creando...' : 'Crear Jugador'}
        </Button>
      </Box>

      <Dialog open={tutorialOpen} onClose={() => setTutorialOpen(false)} maxWidth="md">
        <DialogTitle>Tutorial - Crear Jugador</DialogTitle>
        <DialogContent>
          <Typography paragraph>
            Bienvenido al formulario de creación de jugadores. Aquí hay algunas indicaciones importantes:
          </Typography>
          <Typography variant="body1" paragraph>
            • Los campos marcados en rojo son obligatorios:
            - Nombre del jugador
            - Tipo de jugador (Pro o Cantera)
            - Fecha de nacimiento
            - Altura
            - Puesto
          </Typography>
          <Typography variant="body1" paragraph>
            • Para los jugadores de cantera, debe seleccionar al menos un grupo
          </Typography>
          <Typography variant="body1" paragraph>
            • Puede subir una foto del jugador haciendo clic en el icono de la cámara
          </Typography>
          <Typography variant="body1" paragraph>
            • Todos los demás campos son opcionales pero ayudan a tener una ficha más completa del jugador
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTutorialOpen(false)}>Entendido</Button>
        </DialogActions>
      </Dialog>

      {/* Modal d'erreur */}
      <Dialog open={errorModalOpen} onClose={handleCloseModal}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <Typography>{errorMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal de succès */}
      <Dialog open={successModalOpen} onClose={() => setSuccessModalOpen(false)}>
        <DialogTitle>¡Éxito!</DialogTitle>
        <DialogContent>
          <Typography>
            El jugador ha sido registrado correctamente en la base de datos. 
            ¿Desea crear otro jugador?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setSuccessModalOpen(false);
            resetForm();
          }} color="primary">
            Crear otro jugador
          </Button>
          <Button onClick={() => window.history.back()} color="primary">
            Volver
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CrearJugador;
