import React, { useState, useEffect } from 'react';
import {
  Box, Typography, TextField, Button, Select, MenuItem, Chip,
  IconButton, Dialog, DialogActions, DialogContent, Grid,
  FormControlLabel, Checkbox
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import axios from 'axios';

const Tags = () => {
  const [tagName, setTagName] = useState('');
  const [tagColor, setTagColor] = useState('');
  const [createBigboards, setCreateBigboards] = useState(false);
  const [tags, setTags] = useState([]);
  const [editTag, setEditTag] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const navigate = useNavigate();

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    fetchTags();
  }, []);

  const fetchTags = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/tags`, { params: { section } });
      setTags(response.data);
    } catch (error) {
      console.error("Error al recuperar les tags:", error);
    }
  };

  const handleAddTag = async () => {
    if (tagName && tagColor) {
      try {
        // Récupérer l'utilisateur du localStorage
        const user = JSON.parse(localStorage.getItem('user'));
  
        const response = await axios.post(`${backendUrl}/api/tags`, {
          libelle: tagName,
          code_couleur: tagColor,
          create_bigboards: createBigboards,
          section,
          user_id: user.id // Ajout de l'user_id depuis le localStorage
        });
        setTags([...tags, response.data]);
        setTagName('');
        setTagColor('');
        setCreateBigboards(false);
      } catch (error) {
        console.error("Error al añadir el tag:", error);
      }
    }
  };

  const handleEditTag = (tag) => {
    setEditTag(tag);
    setTagName(tag.libelle);
    setTagColor(tag.code_couleur);
    setCreateBigboards(false); // Réinitialiser la case à cocher en mode édition
  };

  const handleSaveEdit = async () => {
    try {
      await axios.put(`${backendUrl}/api/tags/${editTag.id}`, {
        libelle: tagName,
        code_couleur: tagColor,
        section
      });
      setTags(tags.map((t) => (t.id === editTag.id ? { ...t, libelle: tagName, code_couleur: tagColor } : t)));
      setTagName('');
      setTagColor('');
      setEditTag(null);
    } catch (error) {
      console.error("Error al actualizar el tag:", error);
    }
  };

  const handleDeleteTag = async (tag) => {
    try {
      await axios.delete(`${backendUrl}/api/tags/${tag.id}`, {
        params: { section }
      });
      setTags(tags.filter((t) => t.id !== tag.id));
      setConfirmDelete(null);
    } catch (error) {
      console.error("Error al eliminar el tag:", error);
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" sx={{ mb: 4 }}>
        Tags
      </Typography>

      <Grid container spacing={2} direction={isSmallScreen ? 'column' : 'row'} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Nombre Tag"
            variant="outlined"
            value={tagName}
            onChange={(e) => setTagName(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Select
            value={tagColor}
            onChange={(e) => setTagColor(e.target.value)}
            displayEmpty
            variant="outlined"
            fullWidth
          >
            <MenuItem value="" disabled>Seleccionar color</MenuItem>
            <MenuItem value="#ffcc80">Naranja</MenuItem>
            <MenuItem value="#90caf9">Azul</MenuItem>
            <MenuItem value="#a5d6a7">Verde</MenuItem>
            <MenuItem value="#f48fb1">Rosa</MenuItem>
            <MenuItem value="#ffab91">Naranja Claro</MenuItem>
            <MenuItem value="#ce93d8">Violeta</MenuItem>
            <MenuItem value="#81c784">Verde Claro</MenuItem>
            <MenuItem value="#64b5f6">Azul Cielo</MenuItem>
            <MenuItem value="#ba68c8">Morado</MenuItem>
            <MenuItem value="#4fc3f7">Azul Claro</MenuItem>
            <MenuItem value="#e57373">Rojo Claro</MenuItem>
            <MenuItem value="#fff176">Amarillo</MenuItem>
          </Select>
        </Grid>
        {!editTag && (
          <Grid item xs={12} sm={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={createBigboards}
                  onChange={(e) => setCreateBigboards(e.target.checked)}
                />
              }
              label="Crear los bigboards"
            />
          </Grid>
        )}
        <Grid item xs={12} sm={2}>
          <Button
            variant="contained"
            onClick={editTag ? handleSaveEdit : handleAddTag}
            sx={{ backgroundColor: '#ff9800', color: 'white', height: '100%', width: '150px' }}
          >
            {editTag ? 'Guardar Cambios' : 'Añadir Etiqueta'}
          </Button>
        </Grid>
      </Grid>

      <Box>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Tags existentes:
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {tags.map((tag) => (
            <Box key={tag.id} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Chip
                label={tag.libelle}
                sx={{ backgroundColor: tag.code_couleur, color: 'white' }}
              />
              <IconButton onClick={() => handleEditTag(tag)} sx={{ color: '#ff9800' }}>
                <Edit />
              </IconButton>
              <IconButton onClick={() => setConfirmDelete(tag)} sx={{ color: 'red' }}>
                <Delete />
              </IconButton>
            </Box>
          ))}
        </Box>
      </Box>

      <Dialog open={!!confirmDelete} onClose={() => setConfirmDelete(null)}>
        <DialogContent>
          <Typography variant="h6">
            ¿Estás seguro de querer eliminar el tag "{confirmDelete?.libelle}"?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDelete(null)} sx={{ color: 'grey' }}>
            Cancelar
          </Button>
          <Button onClick={() => handleDeleteTag(confirmDelete)} sx={{ color: 'red' }}>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Tags;