import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  Avatar,
  Button,
  Typography,
  TextField,
  Autocomplete,
  Grid
} from '@mui/material';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import ListboxComponent from '../components/ListboxComponent ';
import PlayerProfileEditor from '../components/PlayerProfileEditor';

const PlayerCardCreator = () => {
  // États communs
  const [players, setPlayers] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [cardType, setCardType] = useState('');
  const [fecha, setFecha] = useState('');
  const [evento, setEvento] = useState('');
  const [fuente, setFuente] = useState('');
  const [detalles, setDetalles] = useState('');
  const [userId, setUserId] = useState(null);

  // États pour técnicas
  const [bodyFat, setBodyFat] = useState('');
  const [heightWoShoes, setHeightWoShoes] = useState('');
  const [heightWithShoes, setHeightWithShoes] = useState('');
  const [shuttleRun, setShuttleRun] = useState('');
  const [wingspanHorizontal, setWingspanHorizontal] = useState('');
  const [handLength, setHandLength] = useState('');
  const [wingspanVertical, setWingspanVertical] = useState('');
  const [standingVerticalLeap, setStandingVerticalLeap] = useState('');
  const [maxVerticalLeap, setMaxVerticalLeap] = useState('');
  const [laneAgility, setLaneAgility] = useState('');
  const [threeFourSprint, setThreeFourSprint] = useState('');
  const [handWidth, setHandWidth] = useState('');
  const [puntosFuertesDebiles, setPuntosFuertesDebiles] = useState('');
  const [habilidadAtletica, setHabilidadAtletica] = useState('');
  const [aspectosPsicologicos, setAspectosPsicologicos] = useState('');
  const [ambitoAcademicoFamiliar, setAmbitoAcademicoFamiliar] = useState('');
  const [intel, setIntel] = useState('');
  const [aspectosParaSeguirDesarrollando, setAspectosParaSeguirDesarrollando] = useState('');
  const [conclusiones, setConclusiones] = useState('');

  // États pour le PlayerProfileEditor
  const [showProfileEditor, setShowProfileEditor] = useState(false);
  const [playerData, setPlayerData] = useState(null);
  const [updatedPlayerProfile, setUpdatedPlayerProfile] = useState(null);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;

  // Pattern pour la validation des nombres décimaux
  const decimalPattern = /^[0-9]{0,3}(\.[0-9]{0,2})?$/;

  // Effet pour recharger le PlayerProfileEditor quand on change de joueur
  useEffect(() => {
    if (selectedPlayer && (cardType === 'intel' || cardType === 'informe') && showProfileEditor) {
      const fetchPlayerData = async () => {
        try {
          const response = await axios.get(`${backendUrl}/api/players/${selectedPlayer.id}?section=${section}`);
          setPlayerData(response.data);
          setUpdatedPlayerProfile(null);
        } catch (error) {
          console.error('Error fetching player data:', error);
        }
      };
      fetchPlayerData();
    } else {
      setPlayerData(null);
      setUpdatedPlayerProfile(null);
    }
  }, [selectedPlayer, cardType, backendUrl, section, showProfileEditor]);

  // Effet pour réinitialiser la visibilité du ProfileEditor lors du changement de joueur
  useEffect(() => {
    setShowProfileEditor(false);
  }, [selectedPlayer]);

  // Fonction pour basculer l'affichage du ProfileEditor
  const toggleProfileEditor = () => {
    setShowProfileEditor(!showProfileEditor);
  };

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/players/select`, {
          params: { section }
        });
        setPlayers(response.data.players);
      } catch (error) {
        console.error("Error al obtener los jugador@s:", error);
      }
    };

    fetchPlayers();
    resetFecha();

    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser && storedUser.id) {
      setUserId(storedUser.id);
    }
  }, [backendUrl, section]);

  const resetFecha = () => {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    setFecha(formattedDate);
  };

  const resetForm = () => {
    setSelectedPlayer(null);
    setCardType('');
    resetFecha();
    setEvento('');
    setFuente('');
    setDetalles('');
    setShowProfileEditor(false);
    // Réinitialiser les champs techniques
    setBodyFat('');
    setHeightWoShoes('');
    setHeightWithShoes('');
    setShuttleRun('');
    setWingspanHorizontal('');
    setHandLength('');
    setWingspanVertical('');
    setStandingVerticalLeap('');
    setMaxVerticalLeap('');
    setLaneAgility('');
    setThreeFourSprint('');
    setHandWidth('');
    setPuntosFuertesDebiles('');
    setHabilidadAtletica('');
    setAspectosPsicologicos('');
    setAmbitoAcademicoFamiliar('');
    setIntel('');
    setAspectosParaSeguirDesarrollando('');
    setConclusiones('');
    setPlayerData(null);
    setUpdatedPlayerProfile(null);
  };

  const handleCardTypeChange = (e) => {
    setCardType(e.target.value);
    setShowProfileEditor(false);
  };

  const transformDetalles = (text) => {
    return text.replace(/<p>/g, '').replace(/<\/p>/g, '<br />').trim();
  };

  const formatDateForBackend = (input) => {
    if (!input || input.includes('T')) return input;
    const [year, month, day] = input.split('-');
    if (year && month && day) {
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    }
    return input;
  };

  const handleCreateCard = async () => {
    if (!selectedPlayer || !cardType) {
      alert("Por favor, seleccione un jugador y un tipo de ficha.");
      return;
    }

    const formattedDetalles = transformDetalles(detalles);
    
    try {
      const promises = [];

      // Si le profil a été mis à jour et que le ProfileEditor est visible
      if (updatedPlayerProfile && showProfileEditor) {
        const dateNaissanceFormatted = formatDateForBackend(updatedPlayerProfile.date_naissance);

        const formData = new FormData();
        formData.append('nom', updatedPlayerProfile.nom.trim());
        formData.append('annee_naissance', new Date(dateNaissanceFormatted).getFullYear());
        formData.append('date_naissance', dateNaissanceFormatted);
        formData.append('taille', updatedPlayerProfile.taille || null);
        formData.append('poids', updatedPlayerProfile.poids || null);
        formData.append('poste', updatedPlayerProfile.poste || null);
        formData.append('type', updatedPlayerProfile.type);
        formData.append('section', section);
        formData.append('nationalite', updatedPlayerProfile.nationalite || null);
        formData.append('je_ji', updatedPlayerProfile.je_ji ? 1 : 0);
        formData.append('selection_je_ji', updatedPlayerProfile.selection_je_ji ? 1 : 0);
        formData.append('mano', updatedPlayerProfile.mano || null);
        formData.append('offensive_role', updatedPlayerProfile.offensive_role || null);
        formData.append('defensive_role', updatedPlayerProfile.defensive_role || null);
        formData.append('passport', updatedPlayerProfile.passport || null);
        formData.append('statut', updatedPlayerProfile.statut || null);
        formData.append('team', updatedPlayerProfile.team || null);
        formData.append('league_1', updatedPlayerProfile.league_1 || null);
        formData.append('league_2', updatedPlayerProfile.league_2 || null);
        formData.append('pre_draft_team', updatedPlayerProfile.pre_draft_team || null);
        formData.append('draft_year', updatedPlayerProfile.draft_year || null);
        formData.append('draft_pick', updatedPlayerProfile.draft_pick || null);
        formData.append('grade', updatedPlayerProfile.grade || null);
        formData.append('acb_experience', updatedPlayerProfile.acb_experience || null);
        formData.append('level', updatedPlayerProfile.level || null);
        formData.append('proyeccion', updatedPlayerProfile.proyeccion || null);
        formData.append('agency', updatedPlayerProfile.agency || null);
        formData.append('pro_comparaison', updatedPlayerProfile.pro_comparaison || null);
        formData.append('flag_behaviour', updatedPlayerProfile.flag_behaviour || null);
        formData.append('price_range', updatedPlayerProfile.price_range || null);
        formData.append('contract', updatedPlayerProfile.contract || null);
        formData.append('url', updatedPlayerProfile.url || null);
        if (updatedPlayerProfile.groupes) {
          formData.append('groupes', JSON.stringify(updatedPlayerProfile.groupes.map(g => g.id)));
        }

        const updatePlayerProfilePromise = axios.put(
          `${backendUrl}/api/players/${updatedPlayerProfile.id}?section=${section}`,
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        );
        promises.push(updatePlayerProfilePromise);
      }

      if (cardType === 'informe') {
        if (!evento) {
          alert("Por favor, ingrese un evento para la ficha de informe.");
          return;
        }
        const informePromise = axios.post(`${backendUrl}/api/informe`, {
          playerId: selectedPlayer.id,
          fecha,
          id_usuarios: userId,
          evento,
          detalles: formattedDetalles,
          section
        });
        promises.push(informePromise);
      } else if (cardType === 'intel') {
        if (!fuente) {
          alert("Por favor, ingrese una fuente para la ficha de intel.");
          return;
        }
        const intelPromise = axios.post(`${backendUrl}/api/intel`, {
          playerId: selectedPlayer.id,
          fecha,
          id_usuarios: userId,
          fuente,
          detalles: formattedDetalles,
          section
        });
        promises.push(intelPromise);
      } else if (cardType === 'techniques') {
        const techniquesPromise = axios.post(`${backendUrl}/api/technique`, {
          playerId: selectedPlayer.id,
          fecha,
          id_usuarios: userId,
          bodyFat: bodyFat || 0,
          heightWoShoes: heightWoShoes || 0,
          heightWithShoes: heightWithShoes || 0,
          shuttleRun: shuttleRun || 0,
          wingspanHorizontal: wingspanHorizontal || 0,
          handLength: handLength || 0,
          wingspanVertical: wingspanVertical || 0,
          standingVerticalLeap: standingVerticalLeap || 0,
          maxVerticalLeap: maxVerticalLeap || 0,
          laneAgility: laneAgility || 0,
          threeFourSprint: threeFourSprint || 0,
          handWidth: handWidth || 0,
          puntosFuertesDebiles: transformDetalles(puntosFuertesDebiles),
          habilidadAtletica: transformDetalles(habilidadAtletica),
          aspectosPsicologicos: transformDetalles(aspectosPsicologicos),
          ambitoAcademicoFamiliar: transformDetalles(ambitoAcademicoFamiliar),
          intel: transformDetalles(intel),
          aspectosParaSeguirDesarrollando: transformDetalles(aspectosParaSeguirDesarrollando),
          conclusiones: transformDetalles(conclusiones),
          section
        });
        promises.push(techniquesPromise);
      }

      await Promise.all(promises);
      alert("Ficha guardada exitosamente!");
      resetForm();
    } catch (error) {
      console.error("Error al guardar la ficha:", error);
      alert("Error al guardar la ficha.");
    }
  };

  return (
    <Box sx={{ mt: 4, px: 4 }}>
      <Typography variant="h4" gutterBottom>
        Crear una Ficha
      </Typography>

      {/* Sélecteur de joueur avec Autocomplete */}
      <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
        <Autocomplete
          options={players}
          getOptionLabel={(player) => player.nom}
          value={selectedPlayer}
          onChange={(event, newValue) => setSelectedPlayer(newValue)}
          renderInput={(params) => (
            <TextField {...params} label="Seleccionar un jugador" variant="outlined" />
          )}
          renderOption={(props, player) => (
            <Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                src={`${mediaUrl}/photos/${player.id}.png`}
                alt={player.nom}
                sx={{ width: 30, height: 30, marginRight: 1 }}
              />
              {player.nom}
            </Box>
          )}
          ListboxComponent={ListboxComponent}
        />
      </FormControl>

      {/* Avatar du joueur sélectionné */}
      {selectedPlayer && (
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center',
            mb: 3 
          }}
        >
          <Avatar
            src={`${mediaUrl}/photos/${selectedPlayer.id}.png`}
            alt={selectedPlayer.nom}
            sx={{ 
              width: 150, 
              height: 150,
              border: '2px solid #e0e0e0',
              boxShadow: '0px 3px 6px rgba(0,0,0,0.1)'
            }}
          />
        </Box>
      )}

      {/* Sélecteur de type de fiche */}
      <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
        <TextField
          select
          label="Tipo de ficha"
          value={cardType}
          onChange={handleCardTypeChange}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" />
          <option value="techniques">Técnicas</option>
          <option value="informe">Informe</option>
          <option value="intel">Intel</option>
        </TextField>
      </FormControl>

      {/* Formulaire pour "informe" et "intel" */}
      {(cardType === 'informe' || cardType === 'intel') && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6" gutterBottom>
            Información de {cardType === 'informe' ? 'Informe' : 'Intel'}
          </Typography>

          <FormControl fullWidth sx={{ mb: 3 }}>
            <TextField
              label="Fecha"
              variant="outlined"
              type="date"
              value={fecha}
              onChange={(e) => setFecha(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>

          {cardType === 'informe' && (
            <FormControl fullWidth sx={{ mb: 3 }}>
              <TextField
                label="Evento"
                variant="outlined"
                value={evento}
                onChange={(e) => setEvento(e.target.value)}
                placeholder="Introduzca el evento"
              />
            </FormControl>
          )}

          {cardType === 'intel' && (
            <FormControl fullWidth sx={{ mb: 3 }}>
              <TextField
                label="Fuente"
                variant="outlined"
                value={fuente}
                onChange={(e) => setFuente(e.target.value)}
                placeholder="Introduzca la fuente"
              />
            </FormControl>
          )}

          <FormControl fullWidth sx={{ mb: 3 }}>
            <Typography variant="body1" gutterBottom>Detalles</Typography>
            <ReactQuill
              theme="snow"
              value={detalles}
              onChange={setDetalles}
              placeholder="Añadir detalles (texto enriquecido)..."
            />
          </FormControl>

          {/* Bouton pour afficher/masquer le ProfileEditor */}
          {selectedPlayer && selectedPlayer.type !== 'cantera' && (
            <Button 
              variant="outlined" 
              color="primary" 
              onClick={toggleProfileEditor}
              sx={{ mb: 3 }}
              fullWidth
            >
              {showProfileEditor ? 'Ocultar Perfil del Jugador' : 'Ver Perfil del Jugador'}
            </Button>
          )}

          {/* PlayerProfileEditor conditionnel */}
          {showProfileEditor && playerData && selectedPlayer && selectedPlayer.type !== 'cantera' && (
            <Box sx={{ mt: 3, mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                Perfil del Jugador
              </Typography>
              <PlayerProfileEditor
                player={playerData}
                onSave={setUpdatedPlayerProfile}
                onCancel={() => setShowProfileEditor(false)}
                backendUrl={backendUrl}
                section={section}
              />
            </Box>
          )}

          {/* Bouton unique "Guardar" */}
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleCreateCard} 
            sx={{ mt: 3 }}
            fullWidth
          >
            Guardar {cardType === 'informe' ? 'Informe' : 'Intel'}
            
          </Button>
        </Box>
      )}

      {/* Formulaire pour "techniques" */}
      {cardType === 'techniques' && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6" gutterBottom>Información de Técnicas</Typography>

          <FormControl fullWidth sx={{ mb: 3 }}>
            <TextField
              label="Fecha"
              variant="outlined"
              type="date"
              value={fecha}
              onChange={(e) => setFecha(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField 
                fullWidth
                label="Body Fat %" 
                variant="outlined" 
                value={bodyFat} 
                onChange={(e) => {
                  if (decimalPattern.test(e.target.value)) setBodyFat(e.target.value);
                }} 
              />
            </Grid>
            <Grid item xs={6}>
              <TextField 
                fullWidth
                label="Height W/O Shoes (cm)" 
                variant="outlined" 
                value={heightWoShoes} 
                onChange={(e) => {
                  if (decimalPattern.test(e.target.value)) setHeightWoShoes(e.target.value);
                }} 
              />
            </Grid>
            <Grid item xs={6}>
              <TextField 
                fullWidth
                label="Height With Shoes (cm)" 
                variant="outlined" 
                value={heightWithShoes} 
                onChange={(e) => {
                  if (decimalPattern.test(e.target.value)) setHeightWithShoes(e.target.value);
                }} 
              />
            </Grid>
            <Grid item xs={6}>
              <TextField 
                fullWidth
                label="Shuttle Run (s)" 
                variant="outlined" 
                value={shuttleRun} 
                onChange={(e) => {
                  if (decimalPattern.test(e.target.value)) setShuttleRun(e.target.value);
                }} 
              />
            </Grid>
            <Grid item xs={6}>
              <TextField 
                fullWidth
                label="Wingspan Horizontal (cm)" 
                variant="outlined" 
                value={wingspanHorizontal} 
                onChange={(e) => {
                  if (decimalPattern.test(e.target.value)) setWingspanHorizontal(e.target.value);
                }} 
              />
            </Grid>
            <Grid item xs={6}>
              <TextField 
                fullWidth
                label="Hand Length (cm)" 
                variant="outlined" 
                value={handLength} 
                onChange={(e) => {
                  if (decimalPattern.test(e.target.value)) setHandLength(e.target.value);
                }} 
              />
            </Grid>
            <Grid item xs={6}>
              <TextField 
                fullWidth
                label="Wingspan Vertical (cm)" 
                variant="outlined" 
                value={wingspanVertical} 
                onChange={(e) => {
                  if (decimalPattern.test(e.target.value)) setWingspanVertical(e.target.value);
                }} 
              />
            </Grid>
            <Grid item xs={6}>
              <TextField 
                fullWidth
                label="Standing Vertical Leap (cm)" 
                variant="outlined" 
                value={standingVerticalLeap} 
                onChange={(e) => {
                  if (decimalPattern.test(e.target.value)) setStandingVerticalLeap(e.target.value);
                }} 
              />
            </Grid>
            <Grid item xs={6}>
              <TextField 
                fullWidth
                label="Max Vertical Leap (cm)" 
                variant="outlined" 
                value={maxVerticalLeap} 
                onChange={(e) => {
                  if (decimalPattern.test(e.target.value)) setMaxVerticalLeap(e.target.value);
                }} 
              />
            </Grid>
            <Grid item xs={6}>
              <TextField 
                fullWidth
                label="Lane Agility (s)" 
                variant="outlined" 
                value={laneAgility} 
                onChange={(e) => {
                  if (decimalPattern.test(e.target.value)) setLaneAgility(e.target.value);
                }} 
              />
            </Grid>
            <Grid item xs={6}>
              <TextField 
                fullWidth
                label="3/4 Sprint (s)" 
                variant="outlined" 
                value={threeFourSprint} 
                onChange={(e) => {
                  if (decimalPattern.test(e.target.value)) setThreeFourSprint(e.target.value);
                }} 
              />
            </Grid>
            <Grid item xs={6}>
              <TextField 
                fullWidth
                label="Hand Width (cm)" 
                variant="outlined" 
                value={handWidth} 
                onChange={(e) => {
                  if (decimalPattern.test(e.target.value)) setHandWidth(e.target.value);
                }} 
              />
            </Grid>
          </Grid>

          <FormControl fullWidth sx={{ mt: 3 }}>
            <Typography variant="body1" gutterBottom>Puntos Fuertes y Débiles</Typography>
            <ReactQuill 
              theme="snow" 
              value={puntosFuertesDebiles} 
              onChange={setPuntosFuertesDebiles} 
              placeholder="Añadir detalles..." 
            />
          </FormControl>

          <FormControl fullWidth sx={{ mt: 3 }}>
            <Typography variant="body1" gutterBottom>Habilidad Atlética</Typography>
            <ReactQuill 
              theme="snow" 
              value={habilidadAtletica} 
              onChange={setHabilidadAtletica} 
              placeholder="Añadir detalles..." 
            />
          </FormControl>

          <FormControl fullWidth sx={{ mt: 3 }}>
            <Typography variant="body1" gutterBottom>Aspectos Psicológicos</Typography>
            <ReactQuill 
              theme="snow" 
              value={aspectosPsicologicos} 
              onChange={setAspectosPsicologicos} 
              placeholder="Añadir detalles..." 
            />
          </FormControl>

          <FormControl fullWidth sx={{ mt: 3 }}>
            <Typography variant="body1" gutterBottom>Ámbito Académico y Familiar</Typography>
            <ReactQuill 
              theme="snow" 
              value={ambitoAcademicoFamiliar} 
              onChange={setAmbitoAcademicoFamiliar} 
              placeholder="Añadir detalles..." 
            />
          </FormControl>

          <FormControl fullWidth sx={{ mt: 3 }}>
            <Typography variant="body1" gutterBottom>Intel</Typography>
            <ReactQuill 
              theme="snow" 
              value={intel} 
              onChange={setIntel} 
              placeholder="Añadir detalles..." 
            />
          </FormControl>

          <FormControl fullWidth sx={{ mt: 3 }}>
            <Typography variant="body1" gutterBottom>Aspectos para Seguir Desarrollando</Typography>
            <ReactQuill 
              theme="snow" 
              value={aspectosParaSeguirDesarrollando} 
              onChange={setAspectosParaSeguirDesarrollando} 
              placeholder="Añadir detalles..." 
            />
          </FormControl>

          <FormControl fullWidth sx={{ mt: 3 }}>
            <Typography variant="body1" gutterBottom>Conclusiones</Typography>
            <ReactQuill 
              theme="snow" 
              value={conclusiones} 
              onChange={setConclusiones} 
              placeholder="Añadir detalles..." 
            />
          </FormControl>

          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleCreateCard} 
            sx={{ mt: 3 }}
            fullWidth
          >
            Guardar Técnicas
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default PlayerCardCreator;