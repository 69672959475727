import React, { createContext, useContext, useState } from 'react';
import axios from 'axios'; 

const BigBoardTableContext = createContext();

export const BigBoardTableProvider = ({ children }) => {
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [tags, setTags] = useState([]); // Nouvel état pour les tags
  const [selectedTags, setSelectedTags] = useState([]); // Nouvel état pour les tags sélectionnés

  const refreshData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/bigboard`, {
        params: { section: process.env.REACT_APP_SECTION }
      });
      setData(response.data);
      setError(null);
    } catch (error) {
      console.error("Error al recuperar los Big Boards:", error);
      setError("No se pueden cargar los Big Boards.");
    } finally {
      setLoading(false);
    }
  };

  const value = {
    expandedRows,
    setExpandedRows,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    data,
    setData,
    loading,
    setLoading,
    error,
    setError,
    selectedPlayer,
    setSelectedPlayer,
    selectedUser,
    setSelectedUser,
    tags, // Ajout des tags au contexte
    setTags, // Ajout du setter pour les tags
    selectedTags, // Ajout des tags sélectionnés
    setSelectedTags, // Ajout du setter pour les tags sélectionnés
    refreshData,
  };

  return (
    <BigBoardTableContext.Provider value={value}>
      {children}
    </BigBoardTableContext.Provider>
  );
};

export const useBigBoardTable = () => {
  const context = useContext(BigBoardTableContext);
  if (context === undefined) {
    throw new Error('useBigBoardTable must be used within a BigBoardTableProvider');
  }
  return context;
};