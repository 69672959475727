import React, { useState, useEffect, useContext } from 'react';
import {
  Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Avatar, Paper, Grid, Checkbox,
  IconButton, Button, TablePagination, TextField, FormControl, InputLabel, Select, MenuItem, ListItemText, Slider, Chip
} from '@mui/material';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Visibility, Delete as DeleteIcon } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';
import { BigBoardContext } from '../BigBoardContext';

const BigBoard = () => {
  const [players, setPlayers] = useState([]);
  const { bigBoardPlayers, setBigBoardPlayers } = useContext(BigBoardContext);
  const [checkedPlayers, setCheckedPlayers] = useState([]);
  const [page, setPage] = useState(0);
  const rowsPerPage = 50;

  // États pour les filtres
  const [nameFilter, setNameFilter] = useState('');
  const [teamFilter, setTeamFilter] = useState('');
  const [positionFilter, setPositionFilter] = useState([]);
  const [heightFilter, setHeightFilter] = useState([130, 230]);
  const [ageFilter, setAgeFilter] = useState([12, 45]);
  const [uniqueLeagues, setUniqueLeagues] = useState([]);
  const [leagueFilter, setLeagueFilter] = useState([]);

  const positionMappings = {
    'base': ['base', 'Point Guard'],
    'escolta': ['escolta', 'Shooting Guard', 'Guard'],
    'alerio': ['alerio', 'Small Forward', 'Swingman'],
    'ala-pivot': ['ala-pivot', 'Power Forward', 'Power Forward / Center'],
    'pivot': ['pivot', 'Center']
  };

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [bigBoardName, setBigBoardName] = useState('');
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Charger les filtres depuis l'URL au chargement initial
  useEffect(() => {
    if (isInitialLoad) {
      const params = new URLSearchParams(location.search);
      setNameFilter(params.get('nameFilter') || '');
      setTeamFilter(params.get('teamFilter') || '');
      setPositionFilter(params.get('positionFilter') ? params.get('positionFilter').split(',') : []);
      setLeagueFilter(params.get('leagueFilter') ? params.get('leagueFilter').split(',') : []);
      setHeightFilter(params.get('heightFilter') ? params.get('heightFilter').split(',').map(Number) : [130, 230]);
      setAgeFilter(params.get('ageFilter') ? params.get('ageFilter').split(',').map(Number) : [12, 45]);
      setPage(params.get('page') ? parseInt(params.get('page'), 10) : 0);
      setIsInitialLoad(false);
    }
  }, [location.search, isInitialLoad]);

  // Mettre à jour l'URL lorsque les filtres changent
  useEffect(() => {
    if (!isInitialLoad) {
      const params = new URLSearchParams();
      if (nameFilter) params.set('nameFilter', nameFilter);
      if (teamFilter) params.set('teamFilter', teamFilter);
      if (positionFilter.length) params.set('positionFilter', positionFilter.join(','));
      if (leagueFilter.length) params.set('leagueFilter', leagueFilter.join(','));
      if (heightFilter.length) params.set('heightFilter', heightFilter.join(','));
      if (ageFilter.length) params.set('ageFilter', ageFilter.join(','));
      if (page) params.set('page', page);

      const newUrl = `${location.pathname}?${params.toString()}`;
      if (location.search !== `?${params.toString()}`) {
        navigate(newUrl, { replace: true });
      }
    }
  }, [nameFilter, teamFilter, positionFilter, leagueFilter, heightFilter, ageFilter, page, isInitialLoad, location.pathname, navigate]);

  // Récupérer les joueurs depuis le backend
  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/players?section=${section}`);
        const allPlayers = response.data.players;

        // Extraire les ligues uniques
        const leagues = Array.from(new Set(
          allPlayers
            .map(player => player.league_1)
            .filter(league => 
              league !== null && 
              league !== undefined && 
              typeof league === 'string' && 
              league.trim() !== '' && 
              league.trim() !== 'null'
            )
        )).sort();  
        setUniqueLeagues(leagues);

        // Exclure les joueurs déjà sur le Big Board
        const bigBoardPlayerIds = bigBoardPlayers.map(player => player.id);
        const availablePlayers = allPlayers.filter(player => !bigBoardPlayerIds.includes(player.id));

        setPlayers(availablePlayers);
      } catch (error) {
        console.error('Erreur lors de la récupération des joueurs:', error);
      }
    };
    fetchPlayers();
  }, [backendUrl, section, bigBoardPlayers]);

  // Récupérer les tags depuis le backend
  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/tags`, { params: { section } });
        setTags(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des tags:', error);
      }
    };
    fetchTags();
  }, [backendUrl, section]);

  // Fonction pour calculer l'âge du joueur
  const calculateAge = (anneeNaissance) => {
    const currentYear = new Date().getFullYear();
    return currentYear - anneeNaissance;
  };

  // Filtrer les joueurs selon les filtres appliqués
  const filteredPlayers = players.filter((player) => {
    const playerAge = calculateAge(player.annee_naissance);
    const searchTermName = nameFilter.toLowerCase();
    const searchTermTeam = teamFilter.toLowerCase();

    // Vérification de la position avec les équivalences
    const positionMatches = positionFilter.length === 0 || 
      positionFilter.some(pos => 
        positionMappings[pos].some(mappedPos => 
          player.poste.toLowerCase() === mappedPos.toLowerCase()
        )
      );

    return (
      player.nom.toLowerCase().includes(searchTermName) &&
      (!searchTermTeam || (player.team && player.team.toLowerCase().includes(searchTermTeam))) &&
      positionMatches &&
      (leagueFilter.length === 0 || leagueFilter.includes(player.league_1)) &&
      player.taille >= heightFilter[0] && player.taille <= heightFilter[1] &&
      playerAge >= ageFilter[0] && playerAge <= ageFilter[1]
    );
  });

  const getCurrentPagePlayers = () => {
    return filteredPlayers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  const handleTogglePlayer = (playerId) => {
    const currentIndex = checkedPlayers.indexOf(playerId);
    const newChecked = [...checkedPlayers];

    if (currentIndex === -1) {
      newChecked.push(playerId);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedPlayers(newChecked);
  };

  const movePlayersToBigBoard = () => {
    const selected = filteredPlayers.filter((p) => checkedPlayers.includes(p.id));
    setBigBoardPlayers([...bigBoardPlayers, ...selected]);
    setPlayers(players.filter((p) => !checkedPlayers.includes(p.id)));
    setCheckedPlayers([]);
  };

  const movePlayerToBigBoard = (player) => {
    if (!bigBoardPlayers.some((p) => p.id === player.id)) {
      setBigBoardPlayers([...bigBoardPlayers, player]);
      setPlayers(players.filter((p) => p.id !== player.id));
    }
  };

  const removePlayerFromBigBoard = (player) => {
    setBigBoardPlayers(bigBoardPlayers.filter((p) => p.id !== player.id));
    setPlayers([...players, player]);
  };

  // Fonction pour réarranger les joueurs dans le big board
  const movePlayerInBigBoard = (dragIndex, hoverIndex) => {
    const updatedPlayers = [...bigBoardPlayers];
    const [movedPlayer] = updatedPlayers.splice(dragIndex, 1);
    updatedPlayers.splice(hoverIndex, 0, movedPlayer);
    setBigBoardPlayers(updatedPlayers);
  };

  // Fonction pour naviguer vers la fiche du joueur
  const handleViewPlayer = (player) => {
    navigate(`/players/${player.id}`);
  };

  // Fonction pour enregistrer le Big Board
  const handleSaveBigBoard = async () => {
    if (!bigBoardName) {
      alert('Por favor, introduce un nombre para el Big Board.');
      return;
    }
    if (bigBoardPlayers.length === 0) {
      alert('Por favor, añade jugador@s al Big Board.');
      return;
    }
    
    try {
      // Récupérer l'ID de l'utilisateur depuis localStorage
      const userData = JSON.parse(localStorage.getItem('user'));
      const userId = userData?.id;
      if (!userId) {
        alert('Error: ID de usuario no disponible.');
        return;
      }
      
      // Envoi de la requête avec l'ID de l'utilisateur inclus
      await axios.post(`${backendUrl}/api/bigboard`, {
        nom: bigBoardName,
        tags: selectedTags,
        players: bigBoardPlayers.map((player) => player.id),
        user_id: userId,
        section,
      });
      
      alert('Big Board guardado con éxito.');
      
      // Réinitialiser le formulaire
      setBigBoardName('');
      setSelectedTags([]);
      setBigBoardPlayers([]);
    } catch (error) {
      console.error('Error al guardar el Big Board.', error);
      alert('Error al guardar el Big Board.');
    }
  };

  // Composant pour chaque ligne de joueur
  const PlayerRow = ({ player }) => {
    const [{ isDragging }, dragRef] = useDrag({
      type: 'player',
      item: player,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

  return (
    <TableRow 
    ref={dragRef} 
    key={player.id}
    style={{ opacity: isDragging ? 0.5 : 1 }}
    sx={{
      cursor: 'move',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    }}
  >
    <TableCell padding="checkbox">
      <Checkbox
        checked={checkedPlayers.indexOf(player.id) !== -1}
        onChange={() => handleTogglePlayer(player.id)}
        onClick={(e) => e.stopPropagation()}
      />
    </TableCell>
    <TableCell>
      <Avatar 
        src={`${mediaUrl}/photos/${player.id}.png`} 
        alt={player.nom}
        sx={{ width: 40, height: 40 }}
      />
    </TableCell>
    <TableCell>
      <Box 
        component="a"
        href={`/players/${player.id}`}
        onClick={(e) => {
          e.preventDefault();
          handleViewPlayer(player);
        }}
        sx={{
          color: 'black',
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer'
          }
        }}
      >
        {player.nom}
      </Box>
    </TableCell>
    <TableCell>{player.annee_naissance}</TableCell>
    <TableCell>{player.taille}</TableCell>
    <TableCell>{player.poste}</TableCell>
    <TableCell>{player.team || '-'}</TableCell>
    <TableCell>{player.league_1 || '-'}</TableCell>
     
    </TableRow>
  );
};

  // Composant pour les joueurs dans le Big Board
  const DraggableBigBoardPlayer = ({ player, index }) => {
    const ref = React.useRef(null);
    const [, drag] = useDrag({ type: 'bigboard-player', item: { index } });
    const [, drop] = useDrop({
      accept: 'bigboard-player',
      hover: (item) => {
        if (item.index !== index) {
          movePlayerInBigBoard(item.index, index);
          item.index = index;
        }
      },
    });
    drag(drop(ref));

    return (
      <Box ref={ref} display="flex" alignItems="center" mb={2} style={{ cursor: 'move' }}>
        <Typography variant="h6" style={{ marginRight: '10px' }}>{index + 1}</Typography>
        <Avatar src={`${mediaUrl}/photos/${player.id}.png`} alt={player.nom} />
        <Typography style={{ marginLeft: '10px' }}>{player.nom}</Typography>
        <IconButton onClick={() => removePlayerFromBigBoard(player)} style={{ marginLeft: 'auto' }}>
          <DeleteIcon color="error" />
        </IconButton>
      </Box>
    );
  };

  // Zone de dépôt pour le Big Board
  const BigBoardDropZone = () => {
    const [{ isOver }, dropRef] = useDrop({
      accept: 'player',
      drop: (player) => movePlayerToBigBoard(player),
      collect: (monitor) => ({ isOver: monitor.isOver() }),
    });

    return (
      <Paper ref={dropRef} style={{ minHeight: '400px', backgroundColor: isOver ? '#e0f7fa' : '#f5f5f5', padding: '20px' }}>
        <Typography variant="h6">Big Board</Typography>
        {bigBoardPlayers.length === 0 && <p>Arrastra jugador@s aquí.</p>}
        {bigBoardPlayers.map((player, index) => (
          <DraggableBigBoardPlayer key={player.id} player={player} index={index} />
        ))}
      </Paper>
    );
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box sx={{ p: 2 }}>
        {/* Filtres */}
        <Box sx={{ mt: 4, px: 4 }}>
          {/* Filtres par nom et équipe */}
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Filtrar por nombre"
                variant="outlined"
                value={nameFilter}
                onChange={(e) => setNameFilter(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Filtrar por equipo"
                variant="outlined"
                value={teamFilter}
                onChange={(e) => setTeamFilter(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>

          {/* Filtres par position et ligue */}
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="position-filter-label">Filtrar por posición</InputLabel>
                <Select
                  labelId="position-filter-label"
                  multiple
                  value={positionFilter}
                  onChange={(e) => setPositionFilter(e.target.value)}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {Object.entries(positionMappings).map(([position, equivalents]) => (
                    <MenuItem key={position} value={position}>
                      <Checkbox checked={positionFilter.indexOf(position) > -1} />
                      <ListItemText 
                        primary={position}
                        secondary={equivalents.slice(1).join(', ')}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="league-filter-label">Filtrar por liga</InputLabel>
                <Select
                  labelId="league-filter-label"
                  multiple
                  value={leagueFilter}
                  onChange={(e) => setLeagueFilter(e.target.value)}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {uniqueLeagues.map((league) => (
                    <MenuItem key={league} value={league}>
                      <Checkbox checked={leagueFilter.indexOf(league) > -1} />
                      <ListItemText primary={league} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Sliders pour la taille et l'âge */}
          <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', gap: 5, mb: 3 }}>
            <Box sx={{ width: isSmallScreen ? '100%' : '50%' }}>
              <Typography gutterBottom sx={{ textAlign: 'center' }}>Filtrar por altura (130 a 230 cm)</Typography>
              <Slider
                value={heightFilter}
                onChange={(e, newValue) => setHeightFilter(newValue)}
                valueLabelDisplay="auto"
                min={130}
                max={230}
                sx={{ color: '#ff9800' }}
              />
            </Box>

            <Box sx={{ width: isSmallScreen ? '100%' : '50%' }}>
              <Typography gutterBottom sx={{ textAlign: 'center' }}>Filtrar por edad (12 a 45 años)</Typography>
              <Slider
                value={ageFilter}
                onChange={(e, newValue) => setAgeFilter(newValue)}
                valueLabelDisplay="auto"
                min={12}
                max={45}
                sx={{ color: '#ff9800' }}
              />
            </Box>
          </Box>

          {/* Configuration du Big Board */}
          <Grid container spacing={2} sx={{ mb: 4 }}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Nombre del Big Board"
                variant="outlined"
                value={bigBoardName}
                onChange={(e) => setBigBoardName(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="tags-select-label">Seleccionar Tags</InputLabel>
                <Select
                  labelId="tags-select-label"
                  multiple
                  value={selectedTags}
                  onChange={(e) => setSelectedTags(e.target.value)}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((tagId) => {
                        const tag = tags.find((t) => t.id === tagId);
                        return (
                          <Chip
                            key={tagId}
                            label={tag.libelle}
                            sx={{ backgroundColor: tag.code_couleur, color: 'white' }}
                          />
                        );
                      })}
                    </Box>
                  )}
                >
                  {tags.map((tag) => (
                    <MenuItem key={tag.id} value={tag.id}>
                      <Checkbox checked={selectedTags.indexOf(tag.id) > -1} />
                      <Chip
                        label={tag.libelle}
                        sx={{ backgroundColor: tag.code_couleur, color: 'white', mr: 1 }}
                      />
                      <ListItemText primary={tag.libelle} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Button
            variant="contained"
            sx={{ backgroundColor: '#ff9800', color: 'white', mb: 4 }}
            onClick={handleSaveBigBoard}
          >
            Guardar Big Board
          </Button>

          <Button
            variant="contained"
            sx={{ mt: 2, mb: 4, ml: 2, backgroundColor: '#ff9800', color: 'white' }}
            onClick={movePlayersToBigBoard}
            disabled={checkedPlayers.length === 0}
          >
            Mover jugador@s seleccionados ({checkedPlayers.length})
          </Button>

          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          indeterminate={
                            checkedPlayers.length > 0 && 
                            checkedPlayers.length < getCurrentPagePlayers().length
                          }
                          checked={
                            getCurrentPagePlayers().length > 0 && 
                            getCurrentPagePlayers().every(p => checkedPlayers.includes(p.id))
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              const pagePlayerIds = getCurrentPagePlayers().map(p => p.id);
                              setCheckedPlayers([...new Set([...checkedPlayers, ...pagePlayerIds])]);
                            } else {
                              const pagePlayerIds = getCurrentPagePlayers().map(p => p.id);
                              setCheckedPlayers(checkedPlayers.filter(id => !pagePlayerIds.includes(id)));
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell>Avatar</TableCell>
                      <TableCell>Nombre</TableCell>
                      <TableCell>Año de nacimiento</TableCell>
                      <TableCell>Altura (cm)</TableCell>
                      <TableCell>Posición</TableCell>
                      <TableCell>Equipo</TableCell>
                      <TableCell>Liga</TableCell>
                      
                    </TableRow>
                  </TableHead>
                  <TableBody>
  {filteredPlayers
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((player) => (
      <PlayerRow key={player.id} player={player} />
    ))}
</TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={filteredPlayers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                labelRowsPerPage="Filas por página"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <BigBoardDropZone />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </DndProvider>
  );
};

export default BigBoard;