import React, { useState } from 'react';
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  Checkbox,
  ListItemText,
  Box,
  Paper,
  Typography,
  Grid,
} from '@mui/material';
import axios from 'axios';

// Composant réutilisable pour les champs de formulaire
const FormField = ({ children }) => (
  <Grid item xs={12} md={4}>
    <Box sx={{ p: 1 }}>
      {children}
    </Box>
  </Grid>
);

const PlayerProfileEditor = ({
  player,
  onSave,
  onCancel,
  allGroups,
  backendUrl,
  section,
}) => {
  const [editablePlayer, setEditablePlayer] = useState({ ...player });
  const [errorMessage, setErrorMessage] = useState('');
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;
    setEditablePlayer((prev) => ({ ...prev, [name]: fieldValue }));
  };

  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  // Ajout de la fonction handleGroupChange manquante
  const handleGroupChange = (e, group) => {
    setEditablePlayer((prev) => {
      const isGroupSelected = prev.groupes.some(g => g.id === group.id);
      const updatedGroups = isGroupSelected
        ? prev.groupes.filter(g => g.id !== group.id)
        : [...prev.groupes, group];
      return { ...prev, groupes: updatedGroups };
    });
  };

  // Ajout des fonctions pour le formatage des dates
  const formatDateForBackend = (input) => {
    if (!input || input.includes('T')) return input;
    const [year, month, day] = input.split('-');
    if (year && month && day) {
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    }
    return input;
  };

  // Ajout de la fonction handleUpdate manquante
  const handleUpdate = async () => {
    const dateNaissanceFormatted = formatDateForBackend(editablePlayer.date_naissance);

    const formData = new FormData();
    formData.append('nom', editablePlayer.nom.trim());
    formData.append('annee_naissance', new Date(dateNaissanceFormatted).getFullYear());
    formData.append('date_naissance', dateNaissanceFormatted);
    formData.append('taille', editablePlayer.taille || null);
    formData.append('poids', editablePlayer.poids || null);
    formData.append('poste', editablePlayer.poste || null);
    formData.append('type', editablePlayer.type);
    formData.append('section', section);
    formData.append('nationalite', editablePlayer.nationalite || null);
    formData.append('je_ji', editablePlayer.je_ji ? 1 : 0);
    formData.append('selection_je_ji', editablePlayer.selection_je_ji ? 1 : 0);
    formData.append('mano', editablePlayer.mano || null);
    formData.append('offensive_role', editablePlayer.offensive_role || null);
    formData.append('defensive_role', editablePlayer.defensive_role || null);
    formData.append('passport', editablePlayer.passport || null);
    formData.append('statut', editablePlayer.statut || null);
    formData.append('team', editablePlayer.team || null);
    formData.append('league_1', editablePlayer.league_1 || null);
    formData.append('league_2', editablePlayer.league_2 || null);
    formData.append('pre_draft_team', editablePlayer.pre_draft_team || null);
    formData.append('draft_year', editablePlayer.draft_year || null);
    formData.append('draft_pick', editablePlayer.draft_pick || null);
    formData.append('grade', editablePlayer.grade || null);
    formData.append('acb_experience', editablePlayer.acb_experience || null);
    formData.append('level', editablePlayer.level || null);
    formData.append('proyeccion', editablePlayer.proyeccion || null);
    formData.append('agency', editablePlayer.agency || null);
    formData.append('pro_comparaison', editablePlayer.pro_comparaison || null);
    formData.append('flag_behaviour', editablePlayer.flag_behaviour || null);
    formData.append('price_range', editablePlayer.price_range || null);
    formData.append('contract', editablePlayer.contract || null);
    formData.append('url', editablePlayer.url || null);
    if (editablePlayer.groupes) {
      formData.append('groupes', JSON.stringify(editablePlayer.groupes.map(g => g.id)));
    }

    try {
      await axios.put(`${backendUrl}/api/players/${editablePlayer.id}?section=${section}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      alert('¡Jugador actualizado con éxito!');
      onSave(editablePlayer);
    } catch (error) {
      console.error('Error updating player:', error);
      if (error.response && error.response.status === 400) {
        setErrorMessage(error.response.data.message || 'Error al actualizar el jugador.');
      } else {
        setErrorMessage('Error al actualizar el jugador.');
      }
      setErrorModalOpen(true);
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      {/* Informations générales */}
      <Paper sx={{ p: 3, mb: 2 }}>
        <Typography variant="h6" gutterBottom>Información Básica</Typography>
        <Grid container>
          <FormField>
            <TextField
              label="Nombre"
              name="nom"
              value={editablePlayer.nom || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>

          <FormField>
            <FormControl fullWidth>
              <InputLabel>Puesto</InputLabel>
              <Select
                name="poste"
                value={editablePlayer.poste || ''}
                onChange={handleChange}
                label="Puesto"
              >
                {['base', 'alerio', 'pivot', 'escolta', 'ala-pivot'].map((pos) => (
                  <MenuItem key={pos} value={pos}>{pos}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormField>

          <FormField>
            <TextField
              label="Fecha de Nacimiento"
              name="date_naissance"
              type="date"
              value={formatDateForInput(editablePlayer.date_naissance)}
              onChange={(e) => setEditablePlayer((prev) => ({ ...prev, date_naissance: e.target.value }))}
              fullWidth
            />
          </FormField>

          <FormField>
            <TextField
              label="Altura (cm)"
              name="taille"
              value={editablePlayer.taille || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>

          <FormField>
            <TextField
              label="Peso (kg)"
              name="poids"
              value={editablePlayer.poids || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>

          <FormField>
            <TextField
              label="Nacionalidad"
              name="nationalite"
              value={editablePlayer.nationalite || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>
        </Grid>
      </Paper>

      {/* Caractéristiques du joueur */}
      <Paper sx={{ p: 3, mb: 2 }}>
        <Typography variant="h6" gutterBottom>Características del Jugador</Typography>
        <Grid container>
          <FormField>
            <FormControl fullWidth>
              <InputLabel>Mano Dominante</InputLabel>
              <Select
                name="mano"
                value={editablePlayer.mano || ''}
                onChange={handleChange}
                label="Mano Dominante"
              >
                <MenuItem value="derecha">Derecha</MenuItem>
                <MenuItem value="izquierda">Izquierda</MenuItem>
                <MenuItem value="ambas">Ambas</MenuItem>
              </Select>
            </FormControl>
          </FormField>

          <FormField>
            <TextField
              label="Rol Ofensivo"
              name="offensive_role"
              value={editablePlayer.offensive_role || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>

          <FormField>
            <TextField
              label="Rol Defensivo"
              name="defensive_role"
              value={editablePlayer.defensive_role || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>

          <FormField>
            <TextField
              label="Nivel"
              name="level"
              value={editablePlayer.level || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>

          <FormField>
            <FormControl fullWidth>
              <InputLabel>Proyección</InputLabel>
              <Select
                name="proyeccion"
                value={editablePlayer.proyeccion || ''}
                onChange={handleChange}
                label="Proyección"
              >
                <MenuItem value="BOTTOM BCL/EUROCUP (60-65)">BOTTOM BCL/EUROCUP (60-65)</MenuItem>
                <MenuItem value="EL MID-LEVEL (75-80)">EL MID-LEVEL (75-80)</MenuItem>
                <MenuItem value="EL TOP (80-90)">EL TOP (80-90)</MenuItem>
                <MenuItem value="MID BCL/EUROCUP (65-70)">MID BCL/EUROCUP (65-70)</MenuItem>
                <MenuItem value="MID LEB (55-60)">MID LEB (55-60)</MenuItem>
                <MenuItem value="NBA ALL-STAR (95-100)">NBA ALL-STAR (95-100)</MenuItem>
                <MenuItem value="NBA ROTATION (85-90)">NBA ROTATION (85-90)</MenuItem>
                <MenuItem value="TOP BCL/EUROCUP (70-75)">TOP BCL/EUROCUP (70-75)</MenuItem>
              </Select>
            </FormControl>
          </FormField>
        </Grid>
      </Paper>

      {/* Información Administrativa */}
      <Paper sx={{ p: 3, mb: 2 }}>
        <Typography variant="h6" gutterBottom>Información Administrativa</Typography>
        <Grid container>
          <FormField>
            <TextField
              label="Pasaporte"
              name="passport"
              value={editablePlayer.passport || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>

          <FormField>
            <TextField
              label="Estatus"
              name="statut"
              value={editablePlayer.statut || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>

          <FormField>
            <TextField
              label="Equipo Actual"
              name="team"
              value={editablePlayer.team || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>

          <FormField>
            <TextField
              label="Liga Principal"
              name="league_1"
              value={editablePlayer.league_1 || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>

          <FormField>
            <TextField
              label="Liga Secundaria"
              name="league_2"
              value={editablePlayer.league_2 || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>
        </Grid>
      </Paper>

      {/* Información del Draft */}
      <Paper sx={{ p: 3, mb: 2 }}>
        <Typography variant="h6" gutterBottom>Información del Draft</Typography>
        <Grid container>
          <FormField>
            <TextField
              label="Equipo Pre-Draft"
              name="pre_draft_team"
              value={editablePlayer.pre_draft_team || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>

          <FormField>
            <TextField
              label="Año del Draft"
              name="draft_year"
              type="number"
              value={editablePlayer.draft_year || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>

          <FormField>
            <TextField
              label="Posición en el Draft"
              name="draft_pick"
              value={editablePlayer.draft_pick || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>

          <FormField>
            <TextField
              label="Grado"
              name="grade"
              value={editablePlayer.grade || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>
        </Grid>
      </Paper>

      {/* Información Profesional */}
      <Paper sx={{ p: 3, mb: 2 }}>
        <Typography variant="h6" gutterBottom>Información Profesional</Typography>
        <Grid container>
          <FormField>
            <TextField
              label="Experiencia ACB"
              name="acb_experience"
              value={editablePlayer.acb_experience || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>

          <FormField>
            <TextField
              label="Agencia"
              name="agency"
              value={editablePlayer.agency || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>

          <FormField>
            <TextField
              label="Comparación Pro"
              name="pro_comparaison"
              value={editablePlayer.pro_comparaison || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>

          <FormField>
            <TextField
              label="Comportamiento"
              name="flag_behaviour"
              value={editablePlayer.flag_behaviour || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>

          <FormField>
            <TextField
              label="Rango de Precios"
              name="price_range"
              value={editablePlayer.price_range || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>

          <FormField>
            <TextField
              label="Contrato"
              name="contract"
              value={editablePlayer.contract || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>
        </Grid>
      </Paper>

      {/* Información Adicional */}
      <Paper sx={{ p: 3, mb: 2 }}>
        <Typography variant="h6" gutterBottom>Información Adicional</Typography>
        <Grid container>
          <FormField>
            <TextField
              label="URL EuroBasket"
              name="url"
              value={editablePlayer.url || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>

          <FormField>
            <FormControlLabel
              control={
                <Switch
                  checked={editablePlayer.je_ji || false}
                  onChange={(e) => setEditablePlayer((prev) => ({ ...prev, je_ji: e.target.checked }))}
                  name="je_ji"
                />
              }
              label="JE/JI"
            />
          </FormField>

          <FormField>
            <FormControlLabel
              control={
                <Switch
                  checked={editablePlayer.selection_je_ji || false}
                  onChange={(e) => setEditablePlayer((prev) => ({ ...prev, selection_je_ji: e.target.checked }))}
                  name="selection_je_ji"
                />
              }
              label="Selección JE/JI"
            />
          </FormField>
        </Grid>
      </Paper>

      {/* Grupos pour les joueurs de type "cantera" */}
      {editablePlayer.type === 'cantera' && (
        <Paper sx={{ p: 3, mb: 2 }}>
          <Typography variant="h6" gutterBottom>Grupos</Typography>
          <Grid container>
            <FormField>
              <FormControl fullWidth>
                <InputLabel>Grupos</InputLabel>
                <Select
                  multiple
                  value={editablePlayer.groupes || []}
                  renderValue={(selected) => selected.map(group => group.libelle).join(', ')}
                >
                  {allGroups.map((group) => (
                    <MenuItem key={group.id} value={group}>
                      <Checkbox
                        checked={editablePlayer.groupes.some(g => g.id === group.id)}
                        onChange={(e) => handleGroupChange(e, group)}
                        style={{ color: group.code_couleur || '#000' }}
                      />
                      <ListItemText primary={group.libelle} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FormField>
          </Grid>
        </Paper>
      )}

      {/* Boutons de sauvegarde et annulation */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Button variant="contained" onClick={handleUpdate} sx={{ mr: 2 }}>
          Guardar cambios
        </Button>
        <Button variant="outlined" onClick={onCancel}>
          Cancelar
        </Button>
      </Box>
    </Box>
  );
};

export default PlayerProfileEditor;